import React from 'react';
import { 
    TitleHeading, GroupCheckboxGrid, TextFieldOther, GroupCheckboxColumn, 
} from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './MainMaterials.scss';

function Form(props) {
    const {
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched,
        checkboxNaming,
        checkboxMaterials,
        checkboxGauges,
        tagsList,
        inputName
    } = props;

    const inputChange = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.mainMaterials.map(item => 
                    <div key={item.code}>
                        <GroupCheckboxGrid
                            naming={checkboxNaming}
                            name={item.code}
                            checkboxList={item.children}
                            values={checkboxMaterials[item.code]}
                            onChange={props.onMaterialsChange}
                            md={4}            
                        />
                        <TextFieldOther
                            id={inputName}
                            name={inputName}
                            value={props.values[inputName]}
                            helperText={touched[inputName] ? errors[inputName] : ''}
                            error={touched[inputName] && Boolean(errors[inputName])}
                            onChange={inputChange.bind(null, inputName)}
                        />
                    </div>
                )
            }  
            {
                tagsList.gauges ?
                <div className='gauge-container'>
                    <TitleHeading title={tagsList.gauges.name}/>
                    {      
                        tagsList.gauges.children.map((item) => 
                            <GroupCheckboxColumn 
                                naming={checkboxNaming}
                                title={item.name}
                                name={item.code}
                                checkboxList={item.children}
                                key={item.code}
                                values={checkboxGauges[item.code]}
                                onChange={props.onGaugesChange}
                            />
                        )
                    }
                </div> : 
                <div className="mainMaterials-spacing"></div>
            }
        </form>
    );
}

function MainMaterials(props){
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    const materialsCode = tagsList['mainMaterials'][0]['code'];
    const inputName = 'materialsOther';
    let inputOtherInit = {
        [inputName]: dataList['mainMaterials'][materialsCode] ? dataList['mainMaterials'][materialsCode]['other'] : ''
    };
    let yupObject = {
        // [inputName]: Yup.string('')
        //     .max(20, 'Maximum number of characters is 20')
    };

    const [checkboxMaterials, setCheckboxMaterials] = React.useState(dataList['mainMaterials']);
    const [checkboxGauges, setCheckboxGauges] = React.useState(dataList['gauges']);

    const validationSchema = Yup.object(yupObject);

    const handleMaterialsChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxMaterials);
        newCheckboxValues[name][target.name] = target.checked;
        setCheckboxMaterials(newCheckboxValues);
    };

    const handleGaugesChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxGauges);
        newCheckboxValues[name][target.name] = target.checked;
        setCheckboxGauges(newCheckboxValues);
    };

    const handleFormSubmit = (values) => { 
        let newMaterialsValues = Object.assign({}, checkboxMaterials);
        newMaterialsValues[materialsCode]['other'] = values[inputName];
        props.onSubmit({
            mainMaterials: newMaterialsValues,
            gauges: checkboxGauges
        });
    };

    return(
        <div className='mainMaterials-container'>
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxMaterials={checkboxMaterials}
                        checkboxGauges={checkboxGauges}
                        onMaterialsChange={handleMaterialsChange}
                        onGaugesChange={handleGaugesChange}
                        inputName={inputName}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={inputOtherInit}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
        </div>
    )
}

export default MainMaterials;