import React from 'react';
import { AccessHeading, Button } from '../../_common/index';
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import { selectors as registerSelectors, actions as registerActions } from '../../../redux/modules/register';
import { useHistory } from "react-router-dom";
import './BackToWebsite.scss';


function BackToWebsite(props){

    const important = 'support@abana.mu';
    let history = useHistory();

    const paragraphOne = 'Thank you for registering on ABANA. We have sent you and email to verify your identity and complete your profile. ';
    const paragraphTwo = 'Should you have not received any email, you may reach out to us ';

    const handleBack = () => {
        history.push('/login');
    };

    return(
        <div className='backtowebsite-container'>
            <AccessHeading/>
            <div className="backtowebsite-paragraph">
                <p>{paragraphOne}</p>
                <p>{paragraphTwo}<a href={"mailto:support@abana.mu"}>{important}</a></p>
            </div>
        </div>
    )
}


export default connect(
    state => ({
        activeStep: registerSelectors.getActiveStep(state),
        registerSellerData: registerSelectors.getRegisterSellerData(state)
    }),
    { 
        setActiveStep: registerActions.setActiveStep,
        setRegisterSellerData: registerActions.setRegisterSellerData
    }
)(BackToWebsite)