import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import {
  TitleHeading,
  ButtonBack,
  ButtonNext,
  GroupCheckboxColumn,
  TextFieldOther,
} from "../../_common/index";
import { filterArray } from "../../../functions/converters";
import Grid from "@material-ui/core/Grid";
import "./UniqueSellingPoints.scss";
import { getSectionType } from "../../../functions/compute";

function UniqueSellingPoints(props) {
  const heading = "Select your Unique Selling Points:";
  const subHeading = "Qualities that describe your business";
  let requestData = [];
  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
    description: "description",
  };

  switch (getSectionType(props.clientData.basics.type)) {
    case "SG":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "sg_unique_selling_points"
      )[0].children;
      break;
    case "SI":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "si_unique_selling_points"
      )[0].children;
      break;
    default:
      requestData = [];
  }

  if (props.registerSellerData["unique_selling_points"]) {
    checkboxInit = Object.assign(
      {},
      props.registerSellerData["unique_selling_points"]
    );
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
      });

      checkboxInit[item.code] = children;
    });
    checkboxInit["other"] = "";
  }

  const [checkboxValues, setCheckboxValues] = useState(checkboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, value) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][value.name] = value.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleOtherChange = (event) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues["other"] = event.target.value;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      unique_selling_points: checkboxValues,
      supplier_type: getSectionType(props.clientData.basics.type),
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="usp-container-reg">
      <TitleHeading title={heading} subtitle={subHeading} />
      {requestData.map((item) => (
        <GroupCheckboxColumn
          naming={checkboxNaming}
          title={item.name}
          name={item.code}
          checkboxList={item.children}
          key={item.code}
          values={checkboxValues[item.code]}
          onChange={handleCheckboxChange}
        />
      ))}
      <TextFieldOther
        className="text-field-other-override"
        id="uspOther"
        name="uspOther"
        value={checkboxValues["other"]}
        onChange={handleOtherChange}
      />

      <Grid
        container
        direction="row"
        justify={props.activeStep === 1 ? "flex-end" : "space-between"}
        alignItems="center"
      >
        {props.activeStep === 1 ? null : (
          <ButtonBack children="Back" onClick={handleBack} />
        )}
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
    clientData: profileSelectors.getClientData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(UniqueSellingPoints);
