import React from 'react';
import { Grid, Card, ButtonBase } from '@material-ui/core';
import './FullImage.scss';

function FullImage(props) {
    const templateList = props.templateList;

    if(!templateList) {
        return null;
    }

    return (
        <div className="full-image-container">
            <Grid
                container
                justify="space-between"
                spacing={3}
            >
                {
                    templateList.map((item, index) => 
                        <Grid 
                            key={index}
                            className="full-image-item" 
                            item
                            xs={12}
                            sm={templateList.length > 1 ? 6 : 'auto'}
                            md
                        >
                            <Card>
                                <ButtonBase onClick={() => props.onClick(item)}>
                                    <img src={item.image} alt="abana" />
                                    <div className="opaque"></div>
                                    {
                                        item.buttonText ?
                                        <div className="centered-text">{item.buttonText}</div> : null
                                    }
                                </ButtonBase>
                            </Card>   
                        </Grid>
                    )
                }
            </Grid>  
        </div>
    );
}

export default FullImage;