import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import './PaginationComponent.scss';

function PaginationComponent(props) {
    return (
        <Pagination 
            variant="outlined" 
            shape="rounded" 
            {...props}
        />
    );
}

export default PaginationComponent;