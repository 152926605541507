import { localStorageName } from '../../constants/auth';

//Module Name
export const name = 'auth';

// Actions
export const types = {
    SET_AUTH: 'SET_AUTH',
    SET_SIDENAV_OPEN: 'SET_SIDENAV_OPEN',
    SET_REFERER: 'SET_REFERER',
};

const isAuth = JSON.parse(localStorage.getItem(localStorageName)) || JSON.parse(sessionStorage.getItem(localStorageName)) || false;

const initialState = {
    auth: isAuth,
    sidenavOpen: true,
    referer: ""
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_AUTH: {
            const { content } = action.payload;

            return {
                ...state,
                auth: content
            };
        }
        case types.SET_SIDENAV_OPEN: {
            const { content } = action.payload;

            return {
                ...state,
                sidenavOpen: content
            };
        }
        case types.SET_REFERER: {
            const { content } = action.payload;

            return {
                ...state,
                referer: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setAuth: content => ({
        type: types.SET_AUTH,
        payload: {
            content
        }
    }),
    setSidenavOpen: content => ({
        type: types.SET_SIDENAV_OPEN,
        payload: {
            content
        }
    }),
    setReferer: content => ({
        type: types.SET_REFERER,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getAuth: store => store[name].auth,
    getSidenavOpen: store => store[name].sidenavOpen,
    getReferer: store => store[name].referer,
};