import React, { useState, useEffect } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { actions as authActions } from "../../redux/modules/auth";
import { selectors as feedSelectors } from "../../redux/modules/feed";
import { selectors as authSelectors } from "../../redux/modules/auth";
import { selectors as profileSelectors } from "../../redux/modules/profile";
import { selectors as commonSelectors } from "../../redux/modules/common";
import { selectors as messagesSelectors } from "../../redux/modules/messages";
import { actions as profileActions } from "../../redux/modules/profile";
import { actions as accountActions } from "../../redux/modules/account";
import { actions as commonActions } from "../../redux/modules/common";
import { actions as messagesActions } from "../../redux/modules/messages";
import { actions as searchActions } from "../../redux/modules/search";
import { actions as feedActions } from "../../redux/modules/feed";
import { actions as listingsQuotesActions } from "../../redux/modules/listingsQuotes";
import messagesService from "../../services/messagesService";
import { getUserKind, getSubscriptionLevel } from "../../functions/compute";
import { setSelectedCon } from "../../functions/mutators";
import profileService from "../../services/profileService";
import commonService from "../../services/commonService";
import {
  localStorageImpersonationName,
  localStorageName,
} from "../../constants/auth";
import { errorMessages } from "../../constants/messages";
import {
  ErrorMessage,
  Loading,
  MenuElement,
  Notification,
} from "../_common/index";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { setUserPreferences } from "../../functions/mutators";
import freshChatInit from "../../functions/freshChat";
import "./AuthLayout.scss";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Toolbar from "@material-ui/core/Toolbar";
import { sidenavBuyer, sidenavSeller } from "../../constants/sidenav";
import { footerList } from "../../constants/footer";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import Snackbar from "@material-ui/core/Snackbar";
import StarIcon from "@material-ui/icons/Star";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import authService from "../../services/authService";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { dummyNotifications } from "../../constants/dummy";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SearchBar from "../SearchBar/SearchBar";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function Footer(props) {
  const currentYear = new Date().getFullYear();

  const handleFreshChat = () => {
    window.fcWidget.open();
    window.fcWidget.show();
  };

  return (
    <Grid
      className={"auth-layout-footer" + (props.open ? " open" : " close")}
      container
      direction="column"
    >
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        className="footer-links"
      >
        {footerList.map((item) => (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            key={item.id}
            item
            xs={3}
            sm
          >
            {item.name == "Help" ? (
              <a href="#" onClick={handleFreshChat}>
                {item.name}
              </a>
            ) : (
              <a href={item.link} target="_blank">
                {item.name}
              </a>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="copyright"
        item
        sm={12}
        md
      >
        &copy; {currentYear} ABANA
      </Grid>
    </Grid>
  );
}

function AuthLayout(props) {
  const open = props.sidenavOpen;
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState(false);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElMessages, setAnchorElMessages] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueMobile, setSearchValueMobile] = useState("");
  const isProfileMenuOpen = Boolean(anchorElProfile);
  const isMessagesMenuOpen = Boolean(anchorElMessages);
  const isNotificationsMenuOpen = Boolean(anchorElNotifications);
  const recentSearchesName = "abana-recent-searches";
  const recentSearchesInit =
    JSON.parse(localStorage.getItem(recentSearchesName)) || [];
  const [recentSearches, setRecentSearches] = useState(recentSearchesInit);
  const [conversationEventSource, setConversationEventSource] = useState(null);
  const [notificationEventSource, setNotificationEventSource] = useState(null);
  const [feedEventSource, setFeedEventSource] = useState(null);
  const [showAction, setShowAction] = useState(false);
  const [actionMsg, setActionMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.errorApi);
  const [position, setPosition] = useState(61 * (itemsPerPage - 4));
  const incomingNotification = [];
  let sidenavLinks = sidenavBuyer;
  let notificationList = [];
  let conversationList = [];
  let notifyMessages = false;
  let notificationBell = false;
  let history = useHistory();

  const handleWindowResized = () => {
    if (window.innerWidth < 960) {
      props.setSidenavOpen(false);
    }
  };

  const handleLogout = () => {
    if (localStorage.getItem(localStorageName)) {
      localStorage.removeItem(localStorageName);
    }

    if (localStorage.getItem(localStorageImpersonationName)) {
      localStorage.removeItem(localStorageImpersonationName);
    }

    if (sessionStorage.getItem(localStorageName)) {
      sessionStorage.removeItem(localStorageName);
    }

    setUserPreferences(null);
    props.setAuth(false);
    window.location.reload();
    return false;
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResized);
    handleWindowResized();

    return () => {
      window.removeEventListener("resize", handleWindowResized);
    };
  }, []);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.clientData) {
      profileService
        .getProfileBasics(props.auth.user_id, props.auth.token, cancelToken)
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            data["kind"] = getUserKind(data["type"]);
            data["subscription"] = getSubscriptionLevel(data["type"]);
            data["subsPeriod"] = data["period"] || "quarterly";
            //data['free_trial'] = true;
            //data['next_bill'] = '24th September, 2020';

            props.setClientData({
              basics: data,
            });

            return false;
          }

          handleLogout();
        })
        .catch(function (error) {
          handleLogout();
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.clientData]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (props.clientData && !props.mercureData) {
      setLoading(true);

      commonService
        .getMercure(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            props.setMercureData(data);
          }
        })
        .catch(function (error) {});
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.clientData, props.mercureData]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (props.clientData && !props.conversationList) {
      setLoading(true);

      messagesService
        .getConversationList(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200 && response.data) {
            const responseData = response.data;
            props.setConversationList(responseData);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.clientData, props.conversationList]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (props.clientData && !props.notificationList) {
      setLoading(true);

      commonService
        .getNotificationList(
          props.auth.user_id,
          props.clientData.basics.type,
          itemsPerPage,
          1,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200 && response.data) {
            const responseData = response.data;
            props.setNotificationList(responseData.notifications);
            setNotifications(responseData);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.clientData, props.notificationList]);

  useEffect(() => {
    if (props.shouldUpdateMsg) {
      history.push("/auth/messages");
      window.location.reload(false);
    }
  }, [props.shouldUpdateMsg]);

  useEffect(() => {
    if (
      !conversationEventSource &&
      props.conversationList &&
      props.mercureData
    ) {
      const mercureData = props.mercureData.messageHub;
      const hubURL = new URL(mercureData.link);
      let url = new URL(hubURL);
      url.searchParams.append("topic", mercureData.topic);
      let newEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer " + mercureData.token,
        },
      });

      newEventSource.onmessage = (event) => {
        const responseData = JSON.parse(event.data);
        let newConversationList = null;
        if (!responseData) return;

        if (responseData.basics) {
          // newConversationList = cloneDeep(props.conversationList);
          // let newConversation = responseData;
          // newConversation.messages = [responseData.messages];
          // newConversation.pageCount = 1;
          // newConversation.currentPage = 1;
          // newConversationList.push(newConversation);
          // props.setConversationList(newConversationList);

          setActionMsg(
            "New connection available. Please reload page to see messages."
          );
          setShowAction(true);
        } else {
          newConversationList = props.conversationList.map((item) => {
            if (item.conversation_id == responseData.conversation_id) {
              const data = responseData.messages
                ? [responseData.messages]
                : null;
              let newList = item;

              if (data) {
                newList.last_message = data[data.length - 1];
                newList.notify = true;

                if (newList.messages) {
                  newList.messages = newList.messages.concat(data);
                }
              }

              return newList;
            }

            return item;
          });

          props.setConversationList(newConversationList);
        }
      };
      setConversationEventSource(newEventSource);
    }
  }, [conversationEventSource, props.conversationList, props.mercureData]);

  useEffect(() => {
    if (
      !notificationEventSource &&
      props.mercureData &&
      props.notificationList
    ) {
      const mercureData = props.mercureData.notificationHub;
      const hubURL = new URL(mercureData.link);
      let url = new URL(hubURL);
      url.searchParams.append("topic", mercureData.topic);
      let newEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer " + mercureData.token,
        },
      });

      newEventSource.onmessage = (event) => {
        const responseData = JSON.parse(event.data);

        incomingNotification.unshift(responseData);
        let newNotificationList = null;

        newNotificationList = cloneDeep(props.notificationList);

        newNotificationList.unshift(...incomingNotification);

        props.setNotificationList(newNotificationList);
      };
      setNotificationEventSource(newEventSource);
    }
  }, [notificationEventSource, props.notificationList, props.mercureData]);

  useEffect(() => {
    if (!feedEventSource && props.mercureData) {
      const mercureData = props.mercureData.feedHub;

      const hubURL = new URL(mercureData.link);
      let url = new URL(hubURL);
      url.searchParams.append("topic", mercureData.topic);
      let newEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer " + mercureData.token,
        },
      });

      newEventSource.onmessage = (event) => {
        const responseData = JSON.parse(event.data);
        if (!props.newFeedData) {
          props.setNewFeedData(responseData);
        } else {
          if (!isEqual(props.newFeedData, responseData)) {
            props.setNewFeedData(responseData);
          }
        }
      };
      setFeedEventSource(newEventSource);
    }
  }, []);

  useEffect(() => {
    if (props.clientData && props.mercureData && props.conversationList) {
      const basics = props.clientData.basics || {};
      const freshChatScript = freshChatInit({
        userId: props.auth.user_id,
        firstName: basics.firstName,
        lastName: basics.lastName,
        subsType: basics.subscriptionType,
        accountType: basics.type,
        email: basics.email,
        accountCreated: basics.dateCreated,
      });
      document.body.appendChild(freshChatScript);

      return () => {
        document.body.removeChild(freshChatScript);
      };
    }
  }, [props.clientData, props.mercureData, props.conversationList]);

  // useEffect(() => {
  //     return () => {
  //         if(newEventSource instanceof EventSourcePolyfill) {
  //             newEventSource.close();
  //             setEventSource(null);
  //         }
  //     };
  // }, []);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (props.clientData && (!props.subsLimits || props.shouldUpdateLimits)) {
      commonService
        .getSubsLimits(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200) {
            props.setSubsLimits(response.data);
            props.setShouldUpdateLimits(false);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.clientData, props.shouldUpdateLimits]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.tipsData) {
      commonService
        .getTips(cancelToken)
        .then(function (response) {
          if (response.status === 200) {
            props.setTipsData(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, []);

  if (
    props.clientData &&
    props.mercureData &&
    props.conversationList &&
    props.subsLimits &&
    props.notificationList
  ) {
    if (loading) {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (
    !loading &&
    (!props.clientData ||
      !props.mercureData ||
      !props.conversationList ||
      !props.notificationList ||
      !props.subsLimits)
  ) {
    return (
      <div className="request-failed">
        <ErrorMessage message={errorMessages.requestFailed} showError={true} />
      </div>
    );
  }

  const clientData = props.clientData.basics;
  const profilePic = clientData.image
    ? clientData.image.path || clientData.image.base64
    : null;
  const clientName =
    clientData["firstName"] && clientData["lastName"]
      ? clientData["firstName"] + " " + clientData["lastName"]
      : null;
  notificationList = props.notificationList;

  if (clientData["kind"] === "S") {
    sidenavLinks = sidenavSeller;
  }

  if (props.conversationList.length > 0) {
    let notifiedArray = [];
    let notNotifiedArray = [];
    conversationList = cloneDeep(props.conversationList);

    conversationList.sort((a, b) => {
      const val1 =
        a.last_message && a.last_message.created_at
          ? a.last_message.created_at
          : a.connection_created_at;
      const val2 =
        b.last_message && b.last_message.created_at
          ? b.last_message.created_at
          : b.connection_created_at;

      return new Date(val2) - new Date(val1);
    });

    conversationList.forEach((item) => {
      if (item.notify) {
        notifiedArray.push(item);
      } else {
        notNotifiedArray.push(item);
      }
    });

    notifyMessages = Boolean(notifiedArray.length > 0);
    conversationList = notifiedArray.concat(notNotifiedArray);
  }

  if (props.notificationList.length > 0) {
    let notifiedArray = [];
    notificationList = cloneDeep(props.notificationList);

    notificationList.forEach((item) => {
      if (item.notify === true) {
        notifiedArray.push(item);
      }
    });

    notificationBell = Boolean(notifiedArray.length > 0);
  }

  const handleDrawerOpen = () => {
    if (window.innerWidth < 960 && window.innerWidth >= 600) {
      //props.setSidenavOpen(false);
      props.setSidenavOpen(!open);
    } else {
      props.setSidenavOpen(!open);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleProfileMenuClose = (item) => {
    if (item.name === "Upgrade") {
      props.setTabValue(1);
      history.push("/auth/account-settings");
    }

    setAnchorElProfile(null);
  };

  const handleMessagesMenuOpen = (event) => {
    setAnchorElMessages(event.currentTarget);
  };

  const handleMessagesMenuClose = () => {
    setAnchorElMessages(null);
  };

  const handleNotificationsMenuOpen = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setAnchorElNotifications(null);
  };

  const handleLinkClick = () => {
    if (window.innerWidth < 600) {
      props.setSidenavOpen(false);
    }
  };

  const handleFreshChat = () => {
    window.fcWidget.open();
    window.fcWidget.show();
    setAnchorElProfile(null);
  };

  const handleSeeAllMessages = () => {
    handleMessagesMenuClose();
    history.push("/auth/messages");
  };

  const handleConnect = (request) => {
    const requestData = {
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
      connection_id: request.connection_id || request.profile_id,
      connection_type: request.connection_type || request.profile_type,
      request_id: request.request_id,
      response: 1,
    };

    setShowError(false);

    messagesService
      .respondRequest(requestData, props.auth.token)
      .then(function (response) {
        if (response && response.status === 204) {
          //history.push(`/auth/messages`);
          //window.location.reload(false);
          props.setShouldUpdateMsg(true);
          return false;
        }

        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
      })
      .catch(function (error) {
        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
        console.error(error);
      });
  };

  const handleMarkAllNotification = () => {
    const requestData = {
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
    };

    authService
      .updateAllNotification(requestData, props.auth.token)
      .then(function (response) {
        if (response && response.status === 204) {
          const newNotificationList = props.notificationList.map(
            (notification) => {
              notification.notify = false;
              return notification;
            }
          );
          props.setNotificationList(newNotificationList);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleNotificationClick = (item) => {
    handleNotificationsMenuClose();

    const requestData = {
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
      notification_id: item.notification_id,
    };

    if (item.notify === true) {
      authService
        .updateNotification(requestData, props.auth.token)
        .then(function (response) {
          if (response && response.status === 204) {
            const newNotificationList = props.notificationList.map(
              (notification) => {
                if (notification.notification_id === item.notification_id) {
                  let newList = notification;
                  newList.notify = false;
                  return newList;
                }

                return notification;
              }
            );
            props.setNotificationList(newNotificationList);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    switch (item.type) {
      case "report":
        history.push(`/auth/reports`);
        break;
      case "announcement":
        if (item.basics && item.basics.link) {
          window.open(item.basics.link, "_blank");
        }
        break;
      case "listing":
        props.setSelectedFilter("listing");
        props.setFeedData(null);
        history.push(`/auth/home`);
        break;
      case "sannouncement":
      case "cannouncement":
        if (item.profile_id && item.profile_type) {
          if (item.profile_id === props.auth.user_id) {
            history.push(`/auth/profile-own`);
          } else {
            history.push(
              `/auth/profile-view/${item.profile_id}/${item.profile_type}`
            );
          }
        }
        break;
      case "request":
        if (item.notify === true) {
          handleConnect(item);
        } else {
          setSelectedCon(item);
          history.push("/auth/messages");
        }
        break;
      case "proposal":
        if (item.request_id) {
          props.setReloadRFQ(true);
          history.push(`/auth/requests/quotes/yourRFQ/${item.request_id}`);
        }
        break;
      default:
        break;
    }
  };

  const handleMessageClick = (item) => {
    handleMessagesMenuClose();
    setSelectedCon(item);
    history.push("/auth/messages");
  };

  const handleActionClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAction(false);
  };

  const handleReload = () => {
    setShowAction(false);
    props.setShouldUpdateMsg(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const handleNotificationScroll = (e) => {
    e.persist();

    const scrollTop = e.target.scrollTop;

    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (scrollTop === position) {
      if (notifications.current < notifications.pageCount) {
        const nxtPage = notifications.current + 1;
        setLoadingNotification(true);

        commonService
          .getNotificationList(
            props.auth.user_id,
            props.clientData.basics.type,
            itemsPerPage,
            nxtPage,
            props.auth.token,
            cancelToken
          )
          .then(function (response) {
            if (response.status === 200 && response.data) {
              const responseData = response.data;
              const newNotificationList = props.notificationList.concat(
                responseData.notifications
              );
              setNotifications(responseData);
              props.setNotificationList(newNotificationList);
              setLoadingNotification(false);
              const lastPosition = 61 * (responseData.lastItemNumber - 4);
              setPosition(lastPosition);

              if (notifications && notifications.lastItemNumber) {
                const lastItem = document.querySelector(
                  `#notification${notifications.lastItemNumber - 1}`
                );
                lastItem.scrollIntoView({ block: "start" });
              }
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      }
    }
  };

  const menuProfileList = [
    {
      id: 1,
      links: [
        {
          name: "Account Settings",
          icon: <SettingsIcon />,
          path: "/auth/account-settings",
        },
        {
          name: "Upgrade",
          icon: <StarIcon />,
          path: "/auth/account-settings",
        },
      ],
    },
    {
      id: 2,
      links: [
        {
          name: "Saved Items",
          icon: <BookmarkIcon />,
          path: "/auth/saved-items",
        },
        {
          name: "Orders",
          icon: <AssignmentIcon />,
          path: "/auth/orders",
        },
      ],
    },
  ];

  const RenderMenuNotifications = (props) => {
    return (
      <Menu
        anchorEl={anchorElNotifications}
        keepMounted
        onScroll={handleNotificationScroll}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={4}
        open={isNotificationsMenuOpen}
        onClose={handleNotificationsMenuClose}
        className="appbar-menu-lists notifications"
      >
        <Grid
          className="menu-lists-header"
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item md={6}>
            {props.header}
          </Grid>
          <Grid item md={6} container direction="row" justify="flex-end">
            <Button
              size="small"
              onClick={handleMarkAllNotification}
              className="mark-all"
            >
              Mark all read
            </Button>
          </Grid>
        </Grid>
        <Divider className="sidenav-divider" />
        {/* {
                    loadingNotification ?
                    <div className="notification-spinner">
                        <CircularProgress
                            size={props.size || 20}
                            thickness={props.thickness || 4.5}
                        />
                    </div> : */}
        <Grid
          className="menu-lists-content"
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {loadingNotification ? (
            <div className="notification-spinner">
              <CircularProgress
                size={props.size || 20}
                thickness={props.thickness || 4.5}
              />
            </div>
          ) : (
            props.children
          )}
        </Grid>
        {/* } */}
      </Menu>
    );
  };

  const RenderMenuMessages = (props) => {
    return (
      <Menu
        anchorEl={anchorElMessages}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={4}
        open={isMessagesMenuOpen}
        onClose={handleMessagesMenuClose}
        className="appbar-menu-lists"
      >
        <Grid
          className="menu-lists-header"
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {props.header}
        </Grid>
        <Divider className="sidenav-divider" />
        <Grid
          className="menu-lists-content"
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {props.children}
        </Grid>
      </Menu>
    );
  };

  const renderMenuProfile = (
    <Menu
      anchorEl={anchorElProfile}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={4}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
      className="appbar-menu-profile"
    >
      {menuProfileList.map((section, index) => (
        <div key={section.id}>
          {index !== 0 ? <Divider className="sidenav-divider" /> : null}
          <List>
            {index === 0 ? (
              <NavLink to={"/auth/profile-own"}>
                <ListItem
                  button
                  disableRipple={true}
                  onClick={handleProfileMenuClose}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <ListItemIcon>
                      {profilePic ? (
                        <Avatar
                          alt="Profile"
                          src={profilePic}
                          className="toolbar-avatar"
                        />
                      ) : (
                        <Avatar>
                          {clientName
                            ? clientName.charAt(0).toUpperCase()
                            : null}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Company Profile" />
                  </Grid>
                </ListItem>
              </NavLink>
            ) : null}
            {section.links.map((link) => (
              <NavLink to={link.path} key={link.name}>
                <ListItem
                  button
                  disableRipple={true}
                  onClick={() => handleProfileMenuClose(link)}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.name} />
                  </Grid>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </div>
      ))}
      <Divider className="sidenav-divider" />
      <a href="https://www.abana.mu/privacy" target="_blank">
        <ListItem button disableRipple={true} onClick={handleProfileMenuClose}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Privacy" />
          </Grid>
        </ListItem>
      </a>
      <ListItem button disableRipple={true} onClick={handleFreshChat}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </Grid>
      </ListItem>
      <Divider className="sidenav-divider" />
      <ListItem button disableRipple={true} onClick={handleLogout}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </Grid>
      </ListItem>
    </Menu>
  );

  return (
    <div className="auth-layout-container">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={"app-bar" + (open ? " app-bar-shift" : "")}
      >
        <Toolbar className={open ? "toolbar-open" : "toolbar-close"}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className="drawer-button-mobile"
          >
            <MenuIcon />
          </IconButton>
          <SearchBar />
          <div className="flex-grow" />
          <Grid
            className="toolbar-icons"
            container
            alignItems="center"
            wrap="nowrap"
          >
            <IconButton
              aria-label="notifications"
              color="inherit"
              onClick={handleNotificationsMenuOpen}
            >
              <Badge
                overlap="circle"
                variant="dot"
                invisible={!notificationBell}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="messages"
              color="inherit"
              onClick={handleMessagesMenuOpen}
            >
              <Badge overlap="circle" variant="dot" invisible={!notifyMessages}>
                <ModeCommentIcon />
              </Badge>
            </IconButton>
            <Divider orientation="vertical" flexItem />
            {clientName ? (
              <div className="toolbar-username">
                {clientName} {clientData.verified ? <CheckCircle /> : null}
              </div>
            ) : null}
            {profilePic ? (
              <Avatar
                alt="Profile"
                src={profilePic}
                className="toolbar-avatar"
              />
            ) : (
              <Avatar>
                {clientName ? clientName.charAt(0).toUpperCase() : null}
              </Avatar>
            )}

            <IconButton
              aria-label="profile-menu"
              color="inherit"
              className="toolbar-more"
              onClick={handleProfileMenuOpen}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <RenderMenuNotifications header="Notifications">
        {notificationList.length > 0 ? (
          <>
            {notificationList.map((item, index) => (
              <Notification
                id={`notification${index}`}
                key={index}
                dataList={item}
                onClick={() => handleNotificationClick(item)}
              />
            ))}
          </>
        ) : (
          <div className="menu-items">No new notifications</div>
        )}
      </RenderMenuNotifications>
      <RenderMenuMessages header="Messages">
        {conversationList.length > 0 ? (
          <>
            {conversationList.slice(0, 7).map((item, index) => (
              <MenuElement
                key={index}
                dataList={item}
                onClick={() => handleMessageClick(item)}
              />
            ))}
          </>
        ) : (
          <div className="menu-items">No new messages</div>
        )}
        {conversationList.length > 7 ? (
          <div className="menu-see-all" onClick={handleSeeAllMessages}>
            See All
          </div>
        ) : null}
      </RenderMenuMessages>
      {renderMenuProfile}
      <Drawer
        variant="permanent"
        className={"drawer" + (open ? " drawer-open" : " drawer-close")}
        classes={{
          paper: open ? " drawer-open" : " drawer-close",
        }}
        open={open}
      >
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="center"
          className="sidenav-header"
          wrap="nowrap"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <a href="https://www.abana.mu" target="_blank">
            <img src="/assets/images/logo.svg" alt="abana" />
          </a>
        </Grid>

        {sidenavLinks.map((section, index) => (
          <div key={section.id}>
            {index !== 0 ? <Divider className="sidenav-divider" /> : null}
            <List
              subheader={
                section.title && open ? (
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    disableSticky={true}
                  >
                    {section.title}
                  </ListSubheader>
                ) : null
              }
            >
              {section.links.map((link) => (
                <NavLink
                  to={link.path}
                  key={link.name}
                  activeClassName="nav-link-active"
                  className={"nav-links" + (open ? " open" : " close")}
                >
                  <ListItem
                    button
                    disableRipple={true}
                    onClick={handleLinkClick}
                  >
                    <Grid
                      container
                      direction={open ? "row" : "column"}
                      justify={open ? "flex-start" : "center"}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText
                        primary={open ? link.name : link.shortname}
                      />
                      {link.coming ? (
                        <span className="coming-soon">{link.coming}</span>
                      ) : null}
                    </Grid>
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </div>
        ))}
      </Drawer>
      <main className="content" id="container">
        <div className="content-container">
          <div className="content-children">{props.children}</div>
          <Footer open={open} />
        </div>
      </main>
      <Snackbar
        className={"auth-snackbar"}
        open={showAction}
        onClose={handleActionClose}
        message={actionMsg}
        action={
          <div>
            <Button size="small" onClick={handleActionClose}>
              Close
            </Button>
            <Button size="small" onClick={handleReload}>
              Reload
            </Button>
          </div>
        }
      />
      <Snackbar
        open={showError}
        autoHideDuration={10000}
        onClose={handleErrorClose}
      >
        <Alert
          elevation={0}
          variant="filled"
          onClose={handleErrorClose}
          severity="error"
          children={errorMsg}
        />
      </Snackbar>
    </div>
  );
}

export default connect(
  (state) => ({
    newFeedData: feedSelectors.getNewFeedData(state),
    auth: authSelectors.getAuth(state),
    sidenavOpen: authSelectors.getSidenavOpen(state),
    clientData: profileSelectors.getClientData(state),
    mercureData: commonSelectors.getMercureData(state),
    subsLimits: commonSelectors.getSubsLimits(state),
    shouldUpdateLimits: commonSelectors.getShouldUpdateLimits(state),
    tipsData: commonSelectors.getTipsData(state),
    conversationList: messagesSelectors.getConversationList(state),
    shouldUpdateMsg: messagesSelectors.getShouldUpdateMsg(state),
    notificationList: commonSelectors.getNotificationList(state),
  }),
  {
    setAuth: authActions.setAuth,
    setSidenavOpen: authActions.setSidenavOpen,
    setClientData: profileActions.setClientData,
    setTabValue: accountActions.setTabValue,
    setMercureData: commonActions.setMercureData,
    setSubsLimits: commonActions.setSubsLimits,
    setShouldUpdateLimits: commonActions.setShouldUpdateLimits,
    setTipsData: commonActions.setTipsData,
    setConversationList: messagesActions.setConversationList,
    setShouldUpdateMsg: messagesActions.setShouldUpdateMsg,
    setSearchValue: searchActions.setSearchValue,
    setNotificationList: commonActions.setNotificationList,
    setSelectedFilter: feedActions.setSelectedFilter,
    setFeedData: feedActions.setFeedData,
    setNewFeedData: feedActions.setNewFeedData,
    setReloadRFQ: listingsQuotesActions.setReloadRFQ,
  }
)(AuthLayout);
