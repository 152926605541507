//Module Name
export const name = 'home';

// Actions
export const types = {
    SET_TITLE: 'SET_TITLE',
};

const initialState = {
    title: '',
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_TITLE: {
            const { content } = action.payload;

            return {
                ...state,
                title: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setTitle: content => ({
        type: types.SET_TITLE,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getTitle: store => store[name].title
};