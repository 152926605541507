import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

function DropdownComponent(props) {

    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <FormControl variant="outlined">
            <label htmlFor={props.name}>{props.labelname}</label>
            <Select
                value={props.selectedValue}
                onChange={handleChange}
                displayEmpty
            >
                <MenuItem value="" disabled>
                    Select
                </MenuItem>
                {props.menuItems}
            </Select>
        </FormControl>
    );
}

export default DropdownComponent;