import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import {
  TitleHeading,
  ButtonBack,
  ButtonNext,
  RadioGroup,
  ErrorMessage,
} from "../../_common/index";
import commonService from "../../../services/commonService";
import { errorMessages } from "../../../constants/messages";
import { filterArray } from "../../../functions/converters";
import Grid from "@material-ui/core/Grid";
import "./SupplierType.scss";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function SupplierType(props) {
  const heading = "What kind of supplier are you?";
  const radioList = [
    {
      key: 1,
      value: "SG",
      label: "Apparel Manufacturer",
    },
    {
      key: 2,
      value: "SI",
      label: "Yarn, Fabrics, Accessories and Equipment Supplier",
    },
    {
      key: 3,
      value: "SS",
      label: "Service Provider/Freelancer",
    },
  ];
  const supplierDesc = {
    SG: "Apparel Manufacturers are all producers and manufacturers of products from raw materials",
    SI: "Yarn, Fabrics, Accessories and Equipment Suppliers provide all raw material, embroideries, trims and accessories for finished products",
    SS: "Service Providers provide value-added services to manufacturers and buyers along the supply chain",
  };
  const initValue = props.registerSellerData["supplier_type"] || "SG";
  const [supplierDescription, setSupplierDescription] = useState(
    supplierDesc[initValue]
  );
  const [selectedValue, setSelectedValue] = useState(initValue);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.verifyAccount);
  const [isRequest, setIsRequest] = useState(false);

  const handleRadioChange = (value) => {
    setSupplierDescription(supplierDesc[value]);
    setSelectedValue(value);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    let newRegisterSellerData = {};

    //Clear list if type selection is changed
    if (registerSellerData["supplier_type"] !== selectedValue) {
      const basics = registerSellerData["basics"];
      newRegisterSellerData = {
        basics,
        supplier_type: selectedValue,
      };
    } else {
      newRegisterSellerData = {
        ...registerSellerData,
        supplier_type: selectedValue,
      };
    }

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  return (
    <div className="supplier-type-container">
      <Grid container direction="column" className="supplier-type">
        <Grid item xs={12}>
          <TitleHeading title={heading} />
          <ErrorMessage showError={showError} message={errorMsg} />
          <RadioGroup
            radioList={radioList}
            value={selectedValue}
            onChange={handleRadioChange}
          />
          <div className="supplier-description">{supplierDescription}</div>
        </Grid>

        <Grid container direction="row" className="supplier-type-btn">
          <Grid item xs={6}>
            <ButtonBack onClick={handleBack}>Back</ButtonBack>
          </Grid>
          <Grid item xs={6}>
            <ButtonNext
              onClick={handleNext}
              className="supplier-btn-nxt"
              disabled={isRequest}
            >
              Next
            </ButtonNext>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
    setTaxonsList: registerActions.setTaxonsList,
  }
)(SupplierType);
