import React from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { ButtonInverted } from '../../_common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './FilterBy.scss';

function FilterBy(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const menu = props.menu

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return(
       
        <Grid 
            className="filter-by-container"
            container
            direction="row"
            alignItems="center"
        >
            {
                menu !== undefined ?
                <>
                    <div className="filter-name">Filter by:</div>

                    <ButtonInverted
                        endIcon={open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        onClick={handleClick}
                    >
                        Tags
                    </ButtonInverted>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        elevation={0}
                        open={open}
                        onClose={handleClose}
                        classes={{paper:'appbar-filter-lists'}}
                    >
                        {props.menu.map((item, index) => (
                            <MenuItem key={index} onClick={() => props.handleMenuItemClick(item.code)}>{item.name}</MenuItem>
                        ))} 
                    </Menu>
                </>
                :null
            }
           
        </Grid>
    )
}

export default FilterBy;