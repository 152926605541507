//Module Name
export const name = 'services';

// Actions
export const types = {
    SET_SERVICES_DATA: 'SET_SERVICES_DATA',
};

const initialState = {
    servicesData: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_SERVICES_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                servicesData: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setServicesData: content => ({
        type: types.SET_SERVICES_DATA,
        payload: {
            content
        }
    }),
};

// Selectors
export const selectors = {
    getServicesData: store => store[name].servicesData,
};