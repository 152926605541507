//Module Name
export const name = 'feed';

// Actions
export const types = {
    SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',
    SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
    SET_FEED_DATA: 'SET_FEED_DATA',
    SET_NEW_FEED_DATA: 'SET_NEW_FEED_DATA',
};

const initialState = {
    selectedPage: 1,
    selectedFilter: 'all',
    feedData:null,
    newFeed: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_SELECTED_PAGE: {
            const { content } = action.payload;

            return {
                ...state,
                selectedPage: content
            };
        }
        case types.SET_SELECTED_FILTER: {
            const { content } = action.payload;

            return {
                ...state,
                selectedFilter: content
            };
        }
        case types.SET_FEED_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                feedData: content
            };
        }
        case types.SET_NEW_FEED_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                newFeed: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setSelectedPage: content => ({
        type: types.SET_SELECTED_PAGE,
        payload: {
            content
        }
    }),
    setSelectedFilter: content => ({
        type: types.SET_SELECTED_FILTER,
        payload: {
            content
        }
    }),
    setFeedData: content => ({
        type: types.SET_FEED_DATA,
        payload: {
            content
        }
    }),
    setNewFeedData: content => ({
        type: types.SET_NEW_FEED_DATA,
        payload: {
            content
        }
    }),
};

// Selectors
export const selectors = {
    getSelectedPage: store => store[name].selectedPage,
    getSelectedFilter: store => store[name].selectedFilter,
    getFeedData: store => store[name].feedData,
    getNewFeedData: store => store[name].newFeed,
};