import React from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { selectors as profileSelectors } from '../../../redux/modules/profile';
import { actions as accountActions } from '../../../redux/modules/account';
import './CardNews.scss';

function CardNews(props) {
    let history = useHistory(); 
    const userKind = props.clientData ? props.clientData.basics.kind : null;
    const freeTrial = props.clientData ? (props.clientData.basics.free_trial || false) : false;

    if(!props.dataList) {
        return null;
    }

    const dataList = props.dataList;
    const dropImage = (dataList.latestDrop && dataList.latestDrop.image) ? dataList.latestDrop.image.path : null;

    /*
    const handleQuickHelp = () => {
        history.push('/auth/quick-help');
    };
    */

    const handleFreeTrial = () => {
        props.setTabValue(1);
        history.push('/auth/account-settings');
    };

    return (
        <Card className="card-news-container" variant="outlined">
            <CardContent className="card-content">
                {
                    props.title ?
                    <div className="cn-title">What's New:</div>: null
                }
                {
                    dropImage ?
                    <div className="cn-latest-drop">
                        <div className="cn-subtitle">The latest Drop:</div>
                        <img src={dropImage} alt="latest drop"/>
                    </div> : null
                }
                <div className="cn-announcements">
                    <div className="cn-subtitle">Announcements:</div>
                    <ul className="cn-announcements-ul">
                        {
                            (dataList.announcements && dataList.announcements.length > 0) ?
                            <>
                                {
                                    dataList.announcements.map((item, index) => 
                                        <li key={index} className="cn-announcements-li">
                                            <a href={item.link} target="_blank">{item.name}</a>
                                        </li>
                                    )
                                }
                            </> :
                            <li className="cn-announcements-li">
                                <span>No announcements yet.</span>
                            </li>
                        }
                        
                    </ul>
                </div>
                {/* {
                    (userKind === 'B' && freeTrial) ?
                    <Button 
                        className="btn-free-trial"
                        variant="contained"
                        disableElevation={true}
                        onClick={handleFreeTrial}
                    >
                        Free 14-day Upgrade
                    </Button> : null
                } */}
            </CardContent>
            <CardActions className="card-actions">
                <Button href="https://www.abana.mu/how-it-works/" target="_blank">Getting Started: How it works</Button>
            </CardActions>
        </Card>
    );
}

export default connect(
    state => ({
        clientData: profileSelectors.getClientData(state),
    }),
    {
        setTabValue: accountActions.setTabValue,
    }
)(CardNews)