import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { selectors as commonSelectors } from "../../../redux/modules/common";
import { actions as commonActions } from "../../../redux/modules/common";
import { MenuItem, Grid } from "@material-ui/core";
import {
  TextField,
  ErrorMessage,
  TitleHeading,
  Checkbox,
  TermsAndConditions,
  ParagraphComponent,
  ButtonNext,
  SignUpBuy,
  Loading,
} from "../../_common/index";
import * as Yup from "yup";
import { Formik } from "formik";
import { stringToSha256 } from "../../../functions/converters";
import CountryRegionData from "country-region-data";
import authService from "../../../services/authService";
import commonService from "../../../services/commonService";
import { errorMessages } from "../../../constants/messages";
import { commonTexts } from "../../../constants/commonTexts";
import { repsDefaultList, wdyfuDefaultList } from "../../../constants/auth";
import "./CreateAccount.scss";
import { africanCodeCountriesList } from "../../../constants/african-code-countries-list";
import { filterContriesByContinent } from "../../../functions/converters";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function Form(props) {
  const {
    values: {
      companyName,
      streetAddress,
      city,
      state,
      postalCode,
      phoneNumber,
      country,
      website,
      firstName,
      lastName,
      email,
      cellphone,
      position,
      plainPassword,
      representative,
      wdyfu,
    },
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    showError,
    errorMsg,
    isRequest,
    repsList,
    wdyfuList,
    supplierType,
  } = props;

  const [checked, setChecked] = useState(false);
  const [checkedCondition, setCheckedCondition] = useState(false);

  const countryList =
    props.supplierType === "SG"
      ? filterContriesByContinent(
          CountryRegionData,
          "countryShortCode",
          africanCodeCountriesList
        )
      : CountryRegionData;

  const countryMenuItems = countryList.map((item) => {
    const code = item["countryShortCode"];
    const name = item["countryName"];

    return (
      <MenuItem key={code} value={code}>
        {name}
      </MenuItem>
    );
  });

  let repMenuItems = (
    <MenuItem key={"none"} value={"none"}>
      None
    </MenuItem>
  );

  if (repsList) {
    repMenuItems = repsList.map((item) => {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      );
    });
  }

  let wdyfuMenuItems = (
    <MenuItem key={"none"} value={"none"}>
      None
    </MenuItem>
  );

  if (wdyfuList) {
    wdyfuMenuItems = wdyfuList.map((item) => {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      );
    });
  }

  const getRegions = () => {
    if (!country) {
      return [];
    }

    const countryObject = CountryRegionData.find((value) => {
      return value["countryShortCode"] === country;
    });

    if (!countryObject) {
      return [];
    }

    const regions = countryObject["regions"];

    return regions.map((region) => (
      <MenuItem key={region["name"]} value={region["name"]}>
        {region["name"]}
      </MenuItem>
    ));
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);

    if (name === "country") {
      setFieldValue("state", "", false);
    }
  };

  const handleTerms = (event) => {
    setChecked(event.target.checked);
  };

  const handleCondition = (event) => {
    setCheckedCondition(event.target.checked);
  };

  const subHeaderOne = "Business Details";
  const subHeaderTwo = "Contact Details";
  const subHeaderThree = "Account Details";
  const subSubHeader = "Your email address will be your username";
  const condition =
    "Compliance is an important value for ABANA. By signing up you agree that we check your information and let you know of your application status within two business days";

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid container direction="row" className="form-container">
          <Grid item xs={12} md={4} className="title-subheader">
            <TitleHeading title={subHeaderOne} />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              id="companyName"
              name="companyName"
              labelname="Company Name"
              value={companyName}
              helperText={touched.companyName ? errors.companyName : ""}
              error={touched.companyName && Boolean(errors.companyName)}
              onChange={change.bind(null, "companyName")}
            />

            <Grid
              container
              direction="row"
              item
              justify="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  id="streetAddress"
                  name="streetAddress"
                  labelname="Street Address"
                  value={streetAddress}
                  helperText={touched.streetAddress ? errors.streetAddress : ""}
                  error={touched.streetAddress && Boolean(errors.streetAddress)}
                  onChange={change.bind(null, "streetAddress")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="city"
                  name="city"
                  labelname="City"
                  value={city}
                  helperText={touched.city ? errors.city : ""}
                  error={touched.city && Boolean(errors.city)}
                  onChange={change.bind(null, "city")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  id="country"
                  name="country"
                  labelname="Country"
                  value={country}
                  helperText={touched.country ? errors.country : ""}
                  error={touched.country && Boolean(errors.country)}
                  onChange={change.bind(null, "country")}
                >
                  {countryMenuItems}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  id="state"
                  name="state"
                  labelname="State / Province"
                  value={state}
                  helperText={touched.state ? errors.state : ""}
                  error={touched.state && Boolean(errors.state)}
                  onChange={change.bind(null, "state")}
                >
                  {getRegions()}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="postalCode"
                  name="postalCode"
                  labelname="ZIP / Postal Code"
                  value={postalCode}
                  helperText={touched.postalCode ? errors.postalCode : ""}
                  error={touched.postalCode && Boolean(errors.postalCode)}
                  onChange={change.bind(null, "postalCode")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  labelname="Phone Number"
                  value={phoneNumber}
                  helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  onChange={change.bind(null, "phoneNumber")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  id="representative"
                  name="representative"
                  labelname="Representative"
                  value={representative}
                  helperText={
                    touched.representative ? errors.representative : ""
                  }
                  error={
                    touched.representative && Boolean(errors.representative)
                  }
                  onChange={change.bind(null, "representative")}
                >
                  {repMenuItems}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  id="wdyfu"
                  name="wdyfu"
                  labelname="How did you find us?"
                  value={wdyfu}
                  helperText={touched.wdyfu ? errors.wdyfu : ""}
                  error={touched.wdyfu && Boolean(errors.wdyfu)}
                  onChange={change.bind(null, "wdyfu")}
                >
                  {wdyfuMenuItems}
                </TextField>
              </Grid>
            </Grid>

            <TextField
              id="website"
              name="website"
              labelname="Website"
              value={website ? website : "https://www."}
              subtitle={"Should be in format http://..."}
              helperText={touched.website ? errors.website : ""}
              error={touched.website && Boolean(errors.website)}
              onChange={change.bind(null, "website")}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" className="form-container">
          <Grid item xs={12} md={4} className="title-subheader">
            <TitleHeading title={subHeaderTwo} />
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid
              container
              direction="row"
              item
              justify="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  labelname="First Name"
                  value={firstName}
                  helperText={touched.firstName ? errors.firstName : ""}
                  error={touched.firstName && Boolean(errors.firstName)}
                  onChange={change.bind(null, "firstName")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  labelname="Last Name"
                  value={lastName}
                  helperText={touched.lastName ? errors.lastName : ""}
                  error={touched.lastName && Boolean(errors.lastName)}
                  onChange={change.bind(null, "lastName")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  labelname="Email Address"
                  value={email}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  onChange={change.bind(null, "email")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="cellphone"
                  name="cellphone"
                  labelname="Cell Phone"
                  value={cellphone}
                  helperText={touched.cellphone ? errors.cellphone : ""}
                  error={touched.cellphone && Boolean(errors.cellphone)}
                  onChange={change.bind(null, "cellphone")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="position"
                  name="position"
                  labelname="Position"
                  value={position}
                  helperText={touched.position ? errors.position : ""}
                  error={touched.position && Boolean(errors.position)}
                  onChange={change.bind(null, "position")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" className="form-container" spacing={2}>
          <Grid item xs={12} md={4} className="title-subheader">
            <TitleHeading title={subHeaderThree} subtitle={subSubHeader} />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              id="plainPassword"
              name="plainPassword"
              labelname="Password"
              type="password"
              value={plainPassword}
              placeholder="8+ Characters"
              helperText={commonTexts.pwdValidations}
              error={touched.plainPassword && Boolean(errors.plainPassword)}
              onChange={change.bind(null, "plainPassword")}
            />

            <Grid
              container
              direction="row"
              item
              justify="space-around"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={1}>
                <Checkbox checked={checked} onChange={handleTerms} />
              </Grid>

              <Grid item xs={11}>
                <TermsAndConditions />
              </Grid>

              <Grid item xs={1}>
                <Checkbox
                  checked={checkedCondition}
                  onChange={handleCondition}
                />
              </Grid>

              <Grid item xs={11}>
                <ParagraphComponent para={condition} />
              </Grid>
            </Grid>
            <ErrorMessage showError={showError} message={errorMsg} />
          </Grid>
        </Grid>

        <Grid
          className="buttons-container"
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <SignUpBuy />
          </Grid>

          <Grid item className="next-button">
            <ButtonNext
              type="submit"
              children="Create Account"
              disabled={!checked || !checkedCondition || isRequest}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

function CreateAccount(props) {
  let initialValues = {
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    country: "",
    website: "https://www.",
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
    position: "",
    plainPassword: "",
    representative: "",
    wdyfu: "",
  };
  const header = "Creating your account";
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object({
    companyName: Yup.string("").required("Company Name is required"),
    streetAddress: Yup.string("").required("Street Address is required"),
    city: Yup.string("").required("City is required"),
    phoneNumber: Yup.string("").required("Phone Number is required"),
    country: Yup.string("").required("Country is required"),
    state: Yup.string("").required("State / Province is required"),
    // postalCode: Yup.string('')
    //     .required('ZIP / Postal Code is required'),
    firstName: Yup.string("").required("First Name is required"),
    lastName: Yup.string("").required("Last Name is required"),
    email: Yup.string("")
      .email("Enter a valid email (e.g. something@email.com)")
      .required("Email is required"),
    cellphone: Yup.string("").required("Cell Phone is required"),
    position: Yup.string("").required("Position is required"),
    plainPassword: Yup.string("")
      .min(8, "Password must contain at least 8 characters")
      .matches(
        /[0-9]/,
        "At least: 1 special character, 1 capital and 1 lower case letter, and 1 number"
      )
      .matches(
        /[a-z]/,
        "At least: 1 special character, 1 capital and 1 lower case letter, and 1 number"
      )
      .matches(
        /[A-Z]/,
        "At least: 1 special character, 1 capital and 1 lower case letter, and 1 number"
      )
      .matches(
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        "At least: 1 special character, 1 capital and 1 lower case letter, and 1 number"
      )
      .required("Password is required"),
    representative: Yup.string("Enter your Representative").required(
      "Please select a Representative"
    ),
    wdyfu: Yup.string("Please select an answer").required(
      "Please select an answer"
    ),
  });

  useEffect(() => {
    if (props.registerSellerData["basics"]) {
      const basics = props.registerSellerData["basics"];
      setFormValues({
        ...basics,
        plainPassword: "",
      });
    }

    window.scrollTo(0, 0);
  }, [props.registerSellerData]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.repsData) {
      commonService
        .getRepresentatives(cancelToken)
        .then(function (response) {
          if (response.status === 200 && response.data) {
            props.setRepsData(response.data);
          } else {
            props.setRepsData(repsDefaultList);
          }
        })
        .catch(function (error) {
          console.error(error);
          props.setRepsData(repsDefaultList);
        });
    }

    if (!props.wdyfuData) {
      commonService
        .getWDYFU(cancelToken)
        .then(function (response) {
          if (response.status === 200 && response.data) {
            props.setWdyfuData(response.data);
          } else {
            props.setWdyfuData(wdyfuDefaultList);
          }
        })
        .catch(function (error) {
          console.error(error);
          props.setWdyfuData(wdyfuDefaultList);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, []);

  if (props.repsData && props.wdyfuData) {
    if (loading) {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  const repsList = props.repsData;
  const wdyfuList = props.wdyfuData;
  const supplierType = props.registerSellerData["supplier_type"];

  const handleFormSubmit = (values, actions) => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      basics: {
        ...values,
        findUs: values.wdyfu,
        plainPassword: stringToSha256(values.plainPassword),
        agreeToTerms: true,
        compliance: true,
      },
    };

    setShowError(false);
    setIsRequest(true);

    const verifyEmail = () => {
      authService
        .verifyEmailExist(values["email"])
        .then(function (response) {
          if (response && response.status === 200) {
            if (response.data.code === 400) {
              setIsRequest(false);
              setErrorMsg(errorMessages.emailExists);
              setShowError(true);
            } else {
              props.setRegisterSellerData(newRegisterSellerData);
              registerSeller();
            }
          } else {
            setIsRequest(false);
            setErrorMsg(errorMessages.errorApi);
            setShowError(true);
          }
        })
        .catch(function (error) {
          setIsRequest(false);
          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
          console.error(error);
        });
    };

    authService
      .verifyCompanyName(values.companyName)
      .then(function (response) {
        if (response && response.status === 200) {
          if (response.data.code === 400) {
            setIsRequest(false);
            setErrorMsg(errorMessages.companyExists);
            setShowError(true);
          } else {
            verifyEmail();
          }
        } else {
          setIsRequest(false);
          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
        }
      })
      .catch(function (error) {
        setIsRequest(false);
        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
      });

    const registerSeller = () => {
      authService
        .registerSeller(newRegisterSellerData)
        .then(function (response) {
          if (response && response.status === 204) {
            props.setActiveStep(props.activeStep + 1);
          } else {
            setIsRequest(false);
            setErrorMsg(errorMessages.errorApi);
            setShowError(true);
          }
        })
        .catch(function (error) {
          setIsRequest(false);
          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
        });
    };
  };

  return (
    <div className="create-account-container">
      <TitleHeading className="heading-container" title={header} />
      <Formik
        children={(props) => (
          <Form
            {...props}
            errorMsg={errorMsg}
            showError={showError}
            isRequest={isRequest}
            repsList={repsList}
            wdyfuList={wdyfuList}
            supplierType={supplierType}
          />
        )}
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    repsData: commonSelectors.getRepsData(state),
    wdyfuData: commonSelectors.getWdyfuData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
    setRepsData: commonActions.setRepsData,
    setWdyfuData: commonActions.setWdyfuData,
  }
)(CreateAccount);
