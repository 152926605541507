const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

const databaseService = {
    getFilters: function(user_id, user_type, profile_type, token, cancelToken){
        const url = `${API_URL}/database-get-filters?user_id=${user_id}&user_type=${user_type}&profile_type=${profile_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getResults: function(data, per_page, page, token){
        const url = `${API_URL}/database-get-results?per_page=${per_page}&page=${page}`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    getFreeUser: function(user_id, user_type, token, cancelToken){
        const url = `${API_URL}/get-database-free-user?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
};

export default databaseService;