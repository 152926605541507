import React from 'react';
import './ParagraphComponent.scss';

function ParagraphComponent(props){
    return (
        <p className="paragraph-container">
            {props.para}
        </p>
    );
}

export default ParagraphComponent;