import React, { useState } from 'react';
import { Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import { errorMessages } from '../../../../constants/messages';
import './Segments.scss';

function Form(props) {
    const {
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const handleCheckboxChange = (name, event) => {
        props.onCheckboxChange(name, event.target);
    }; 

    return (
        <form onSubmit={handleSubmit}>
            <Grid 
                container 
                direction="row" 
                className='checkbox-grid'
            >
                <Grid item xs className="checkbox-heading">
                    Category
                </Grid>
                <Grid item xs className="checkbox-heading">
                    Percentage
                </Grid>

                {props.checkboxList.map((item, index) =>(
                    <Grid
                        container 
                        item 
                        xs={12}
                        key={item[props.naming.name]} 
                    >
                        <Grid 
                            className="checkbox-grid-item"
                            container 
                            item 
                            direction='row'
                            justify="flex-start"
                            alignItems="flex-start"
                            xs
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={props.checkboxValues[item[props.naming.name]]['checked']} 
                                        onChange={(e) => handleCheckboxChange(item[props.naming.name], e)} 
                                        name={'checkbox-' + item[props.naming.name]}
                                    />
                                }
                                label={item[props.naming.label]}
                            />
                        </Grid>
                        <Grid 
                            item
                            xs
                            className="checkbox-grid-percentage"
                        >
                            <TextField 
                                variant="outlined"
                                margin="dense"
                                placeholder="%"
                                id={item[props.naming.name]}
                                name={item[props.naming.name]} 
                                value={props.values[item[props.naming.name]]}
                                helperText={touched[item[props.naming.name]] ? errors[item[props.naming.name]] : ''}
                                error={touched[item[props.naming.name]] && Boolean(errors[item[props.naming.name]])}
                                onChange={change.bind(null, item[props.naming.name])}
                                disabled={!props.checkboxValues[item[props.naming.name]]['checked']}
                            />  
                        </Grid>
                    </Grid>
                ))}

                {
                    props.errorPercentage ?
                    <>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} className="error-percentage">
                            {props.errorPercentage}
                        </Grid> 
                    </> : null
                }
                
            </Grid>
        </form>
    );
}

function Segments(props){
    const tagsList = props.tagsList || [];
    const segments = props.dataList || {};
    const percentageError = 'Percentage must be an integer between 1 and 100';
    const [errorPercentage, setErrorPercentage] = useState(null);
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    let checkboxInit = {};
    let percentageValues = {};
    let yupObject = {};

    if(tagsList.length > 0) {
        tagsList.forEach((item, index) => {
            percentageValues[item['code']] = segments[item['code']] ? (segments[item['code']]['percentage'] || '') : '';
            yupObject[item['code']] = Yup.number('')
                .integer(percentageError)
                .min(1, percentageError)
                .max(100, percentageError)
                .typeError(percentageError)
    
            // Check in case tag name is changed
            if(segments[item['code']]) {
                checkboxInit[item['code']] = segments[item['code']];
            } else {
                checkboxInit[item['code']] = {
                    checked: false,
                    percentage: 0
                };
            }
        });
    }

    const [checkboxValues, setCheckboxValues] = useState(checkboxInit);
    const validationSchema = Yup.object(yupObject);

    const handleCheckboxChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name]['checked'] = target.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const handleFormSubmit = (values) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);
        let totalPercentage = 0;

        for(let props in checkboxValues) {
            if(checkboxValues[props]['checked']) {
                totalPercentage += parseInt(values[props]);
                newCheckboxValues[props]['percentage'] = parseInt(values[props]);
            } else {
                newCheckboxValues[props]['percentage'] = 0;
            }
        }

        if(totalPercentage !== 100) {
            setErrorPercentage(errorMessages.percentageError);
        } else {
            setErrorPercentage(null);
            props.onSubmit(newCheckboxValues);
        }
    }

    if(tagsList.length === 0 || Object.keys(checkboxValues).length === 0) { 
        return null;
    }

    return (
        <div className="segments-container">
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        naming={checkboxNaming}
                        checkboxList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                        errorPercentage={errorPercentage}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={percentageValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            />
        </div>
    );

}

export default Segments;
    
    