import React, {useState} from 'react';
import { 
    TitleHeading, GroupCheckboxGrid 
} from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './ProductionCapabilities.scss';

function Form(props) {
    const {
        handleSubmit,
        checkboxNaming,
        checkboxValues,
        tagsList,
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.map((item) => 
                    <GroupCheckboxGrid
                        naming={checkboxNaming}
                        name={item.code}
                        checkboxList={item.children}
                        key={item.code}
                        values={checkboxValues[item.code]}
                        onChange={props.onCheckboxChange}
                    />
                )
            }
        </form>
    );
}

function ProductionCapabilities(props){
    const imageHeading = 'Vertical Integrations Visualization:';
    const imageSubHeading = 'Depending on your selections you can fall into these categorizations:';
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    let initValues = {};
    let yupObject = {};

    const [checkboxValues, setCheckboxValues] = useState(dataList);
    const validationSchema = Yup.object(yupObject);

    const handleCheckboxChange = (name, value) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name][value.name] = value.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const handleFormSubmit = (values) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);
        props.onSubmit(newCheckboxValues);
    };

    if(tagsList.length === 0 || Object.keys(checkboxValues).length === 0) { 
        return null;
    }

    return (
        <div className="prodcap-container">
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
            {
                props.showImage ?
                <div>
                    <TitleHeading
                        title={imageHeading}
                        subtitle={imageSubHeading} 
                        className="img-heading-title"
                    />
                    <img 
                        src="/assets/images/vertical-integrations.png" 
                        className="prod-img" 
                        alt="vertical integrations"
                    />
                </div> : null
            }
        </div>
    );
}

export default ProductionCapabilities;
