import React, { useState, useEffect} from 'react';
import { Dropdown, Chip, ComboBox } from '../index';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import commonService from '../../../services/commonService';
import { filterArray } from '../../../functions/converters';
import CountriesList from '../../../constants/countries-list';
import './TagsSelection.scss';

const axios = require('axios');
const CancelToken = axios.CancelToken;

function TagsSelection(props) {
    const tagsLimit = props.limit || 10;
    const tagsLabel = props.label || 'Tags (Up to '+tagsLimit+') '
    const description = props.description || 'Use Tags to highlight what you are looking for';
    const [taxons, setTaxons] = useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    const [categoryOptions, setCategoryOptions] = React.useState(null);
    const [optionsValue, setOptionsValue] = useState(null);
    const [optionsInputValue, setOptionsInputValue] = useState('');
    const chipList = props.chipList;
    const selectionMode = props.selectionMode || 1;
  
    useEffect(() => {
        if(props.tagsList) {

            switch(selectionMode) {
                case 1:
                    const garmentMaker = filterArray(props.tagsList, 'code', 'sg_garment_maker');
                    const inputSupplier = filterArray(props.tagsList, 'code', 'si_input_supplier');
                    const serviceProvider = filterArray(props.tagsList, 'code', 'ss_service_provider');
                    const segment = filterArray(garmentMaker[0].children, 'code', 'sg_segment')[0];
                    const gaugesMain = filterArray(garmentMaker[0].children, 'code', 'sg_gauges')[0];
                    const upsMain = filterArray(garmentMaker[0].children, 'code', 'sg_unique_selling_points')[0];
                    const certificationMain = filterArray(garmentMaker[0].children, 'code', 'sg_certifications')[0];
                    const serviceMain = filterArray(serviceProvider[0].children, 'code', 'ss_services')[0];
                    const productSG = filterArray(garmentMaker[0].children, 'code', 'sg_product')[0].children;
                    const productSI = filterArray(inputSupplier[0].children, 'code', 'si_product')[0].children;
                    const materials = filterArray(garmentMaker[0].children, 'code', 'sg_materials')[0];
                    const gauges = gaugesMain.children;
                    const usps = upsMain.children;
                    const capabilities = filterArray(garmentMaker[0].children, 'code', 'sg_capabilities')[0];
                    const certifications = certificationMain.children;
                    const services = serviceMain.children;

                    function appendGroup(dataArray, name) {
                        return dataArray.map(item => {
                            return {
                                ...item,
                                group: name
                            }
                        });
                    }

                    function concatArrays(dataArray) {
                        let productsArray = [];
                        dataArray.forEach(item => {
                            const list = appendGroup(item.children, item.name);
                            productsArray = productsArray.concat(list);
                        });

                        return productsArray;
                    }

                    const segmentList = {
                        ...segment,
                        children: appendGroup(segment.children, segment.name)
                    };
                    
                    const productsListSG = {
                        name: 'Products (Apparel Manufacturers)',
                        code: 'product_sg',
                        children: concatArrays(productSG)
                    };

                    const productsListSI = {
                        name: 'Products (Yarn, Fabrics, Accessories and Equipment Suppliers)',
                        code: 'product_si',
                        children: concatArrays(productSI)
                    };

                    const materialsList = {
                        ...materials,
                        children: appendGroup(materials.children, materials.name)
                    };

                    const gaugesList = {
                        name: gaugesMain.name,
                        code: gaugesMain.code,
                        children: concatArrays(gauges)
                    };

                    const uspsList = {
                        name: upsMain.name,
                        code: upsMain.code,
                        children: concatArrays(usps)
                    };

                    const capabilitiesList = {
                        ...capabilities,
                        children: appendGroup(capabilities.children, capabilities.name)
                    };

                    const certificationsList = {
                        name: certificationMain.name,
                        code: certificationMain.code,
                        children: concatArrays(certifications)
                    };

                    const servicesList = {
                        name: serviceMain.name,
                        code: serviceMain.code,
                        children: concatArrays(services)
                    };

                    const countriesList = {
                        ...CountriesList,
                        children: appendGroup(CountriesList.children, CountriesList.name)
                    };

                    const taxonsList = [segmentList, productsListSG, productsListSI, materialsList, gaugesList, uspsList, capabilitiesList, certificationsList, servicesList, countriesList];
                    setTaxons(taxonsList);
                    break;
                case 2:
                    const section1 = filterArray(props.tagsList, 'code', 'sg_garment_maker');
                    const taxonsList1 = filterArray(section1[0].children, 'code', 'sg_product')[0].children;
                    setTaxons(taxonsList1);
                    break;
            }   
        }
        
    }, []);

    if(!props.tagsList) {
        return null;
    }

    let menuItems = null;

    if(taxons && taxons.length > 0) {
        menuItems = taxons.map((item) => 
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        );
    }

    const handleChipDelete = (chipToDelete) => () => {
        props.onChange(chipList.filter((chip) => chip.code !== chipToDelete.code));
    };

    const selectedChips = chipList.map((item) => 
        <Chip 
            key={item.code}
            label={item.name}
            onDelete={handleChipDelete(item)} 
        />
    );

    const handleCategoryChange = value => {
        setOptionsValue(null);
        setSelectedCategory(value);

        taxons.forEach((item) => {
            if(item.code == value) {
                setCategoryOptions(item.children);
            }
        })
    };

    const handleOptionsInputChange = (value) => {
        setOptionsInputValue(value);
    }

    const handleOptionsChange = (event, value) => {
        if((chipList.length < tagsLimit) && value) {
            let newChipList = chipList.slice();

            setOptionsValue(null);
            setOptionsInputValue('');
        
            categoryOptions.forEach((item) => {
                if(item.code == value.code) {
                    //Check if chip already exist
                    if(chipList.findIndex(chip => chip.code === value.code) === -1) {
                        newChipList.push(item);
                    }
                }
            });
            props.onChange(newChipList);
        }
    };

    if(!selectedCategory || !categoryOptions) {
        if(taxons && taxons.length > 0) {
            setSelectedCategory(taxons[0].code);
            setCategoryOptions(taxons[0].children);
        }
        return null;
    }

    return (
        <div className="tags-selection-container">
            <div className="field-section-title">
                <span className="field-section-bold">{tagsLabel}</span>
                <span>{description}</span>
            </div>
            <Grid container direction="row" className="field-wrapper">
                <Grid item sm={5} className="field-wrapper-item">
                    <Dropdown 
                        name="category"
                        labelname="Category"
                        menuItems={menuItems}
                        selectedValue={selectedCategory}
                        onChange={handleCategoryChange}
                    />
                </Grid>
                <Grid item sm={7} className="field-wrapper-item">
                    <ComboBox 
                        name="options"
                        labelname="Options"
                        placeholder="Click or start typing..."
                        options={categoryOptions}
                        groupBy={(option) => option.group}
                        onChange={handleOptionsChange}
                        onInputChange={handleOptionsInputChange}
                        value={optionsValue}
                        inputValue={optionsInputValue}
                    />
                </Grid>
            </Grid>
            <div className="chips-container">
                {selectedChips}
            </div>
        </div>
    );
}

export default TagsSelection;