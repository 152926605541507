import React from 'react';
import { Link } from "react-router-dom";
import './SignUpNowComponent.scss';

function SignUpNowComponent(props) {
    return (
        <div className="sign-up-now-container">
            <span>Not a member? <Link to="/register-buyer">Sign up for free as a Buyer</Link></span>
        </div>
    );
}

export default SignUpNowComponent;