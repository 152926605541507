import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
// import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from "@material-ui/icons/Create";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RoomServiceIcon from "@material-ui/icons/RoomService";
// import LocalMallIcon from '@material-ui/icons/LocalMall';
// import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TimelineIcon from "@material-ui/icons/Timeline";

const IconTrading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="MuiSvgIcon-root custom"
      viewBox="0 0 22 24"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="m10.6 2.3-8 4a1 1 0 0 0-.6 1v9.5a1 1 0 0 0 .5.9l8 4a1 1 0 0 0 1 0l8-4a1 1 0 0 0 .5-1V7.3a1 1 0 0 0-.6-.9l-8-4a1 1 0 0 0-.8 0Zm1.3-.8.4-1a3 3 0 0 0-2.6 0l-8 4A3 3 0 0 0 0 7.3v9.6a3 3 0 0 0 1.7 2.7l8 4a3 3 0 0 0 2.6 0l8-4a3 3 0 0 0 1.7-2.7V7.2a3 3 0 0 0-1.7-2.6l-8-4-.4.8Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M.4 5.7a1 1 0 0 1 1.4-.4L11 9.9l9.2-4.6a1 1 0 1 1 1 1.8l-9.8 4.8a1 1 0 0 1-.8 0L.9 7a1 1 0 0 1-.5-1.4Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11 10c.6 0 1 .4 1 1v11.8a1 1 0 1 1-2 0V11c0-.6.4-1 1-1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const sidenavBuyer = [
  {
    id: 1,
    title: "",
    links: [
      {
        name: "Home Feed",
        shortname: "Feed",
        icon: <HomeIcon />,
        path: "/auth/home",
      },
      {
        name: "Messages & Network",
        shortname: "Messages",
        icon: <MailIcon />,
        path: "/auth/messages",
      },
    ],
  },
  {
    id: 2,
    title: "Matching & Marketplace",
    links: [
      {
        name: "Your Requests",
        shortname: "Requests",
        icon: <CreateIcon />,
        path: "/auth/requests",
      },
      {
        name: "Search Database",
        shortname: "Search",
        icon: <MenuBookIcon />,
        path: "/auth/database",
      },
    ],
  },
  /* {
        id: 3,
        title: 'E-commerce',
        links: [
            {
                name: 'Drops',
                shortname: 'Drops',
                icon: <LocalMallIcon />,
                path: '/auth/drops'
            },
            {
                name: 'Fast Custom',
                shortname: 'Fast Custom',
                coming: '(Coming soon)',
                icon: <ShoppingCartIcon />,
                path: '/auth/fast-custom'
            },
            // {
            //     name: 'Brands',
            //     shortname: 'Brands',
            //     coming: '(coming 2021)',
            //     icon: <LocalOfferIcon />,
            //     path: '/auth/brands'
            // },
            {
                name: 'Orders',
                shortname: 'Orders',
                icon: <AssignmentIcon />,
                path: '/auth/orders'
            }
        ]
    }, */
  {
    id: 4,
    title: "Value-added",
    links: [
      {
        name: "Services",
        shortname: "Services",
        icon: <RoomServiceIcon />,
        path: "/auth/services",
      },
      {
        name: "Reports & Insights",
        shortname: "Reports",
        icon: <TimelineIcon />,
        path: "/auth/reports",
      },
    ],
  },
  {
    id: 5,
    title: "",
    links: [
      {
        name: "Agency & Trading",
        shortname: "Trading",
        icon: <IconTrading />,
        path: "/auth/agency-trading",
      },
    ],
  },
];

export const sidenavSeller = [
  {
    id: 1,
    title: "",
    links: [
      {
        name: "Home Feed",
        shortname: "Feed",
        icon: <HomeIcon />,
        path: "/auth/home",
      },
      {
        name: "Messages & Network",
        shortname: "Messages",
        icon: <MailIcon />,
        path: "/auth/messages",
      },
    ],
  },
  {
    id: 2,
    title: "Matching & Marketplace",
    links: [
      {
        name: "Listings & Quotes",
        shortname: "Listings",
        icon: <CreateIcon />,
        path: "/auth/listings",
      },
      {
        name: "Search Database",
        shortname: "Search",
        icon: <MenuBookIcon />,
        path: "/auth/database",
      },
    ],
  },
  /* {
        id: 3,
        title: 'E-commerce',
        links: [
            {
                name: 'Drops',
                shortname: 'Drops',
                icon: <LocalMallIcon />,
                path: '/auth/drops'
            },

            {
                name: 'Fast Custom',
                shortname: 'Fast Custom',
                coming: '(coming soon)',
                icon: <ShoppingCartIcon />,
                path: '/auth/fast-custom'
            },
            // {
            //     name: 'Your Brand',
            //     shortname: 'Brand',
            //     coming: '(coming 2021)',
            //     icon: <LocalOfferIcon />,
            //     path: '/auth/brand'
            // },
            {
                name: 'Orders',
                shortname: 'Orders',
                icon:  <AssignmentIcon />,
                path: '/auth/orders'
            }
        ]
    }, */
  {
    id: 4,
    title: "Value-added",
    links: [
      {
        name: "Services",
        shortname: "Services",
        icon: <RoomServiceIcon />,
        path: "/auth/services",
      },
      {
        name: "Reports & Insights",
        shortname: "Reports",
        icon: <TimelineIcon />,
        path: "/auth/reports",
      },
    ],
  },
  {
    id: 5,
    title: "",
    links: [
      {
        name: "Agency & Trading",
        shortname: "Trading",
        icon: <IconTrading />,
        path: "/auth/agency-trading",
      },
    ],
  },
];
