import React from 'react';
import './UploadValidator.scss';

function UploadValidator(props) {
    return (
        <div className="upload-validator-container">
            <div>Maximum file upload size: {props.size || ''}</div>
            <div>File types supported: {props.types || ''}</div>
            {
                props.showError ?
                <div className="not-supported">
                    File not supported, please select another file!
                </div> : null
            }    
        </div>
    );
}

export default UploadValidator;

