const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

const messagesService = {
    addConnection: function(data, token) {
        const url = `${API_URL}/add-connection`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    getConversationList: function(user_id, user_type, token, cancelToken){
        const url = `${API_URL}/get-conversations?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getMessagesList: function(user_id, user_type, conversation_id, page, per_page, token, cancelToken){
        const url = `${API_URL}/get-messages?user_id=${user_id}&user_type=${user_type}&conversation_id=${conversation_id}&page=${page}&per_page=${per_page}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getConnectionRequests: function(user_id, user_type, token, cancelToken){
        const url = `${API_URL}/get-connection-requests?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    createMessage: function(data, token) {
        const url = `${API_URL}/create-message`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    updateNotify: function(data, token) {
        const url = `${API_URL}/conversation-notify`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    respondRequest: function(data, token) {
        const url = `${API_URL}/response-connection`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    deleteConnection: function(data, token) {
        const url = `${API_URL}/delete-connection`;

        return axios({
            method: 'DELETE',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    removeRequestConnection: function(data, token) {
        const url = `${API_URL}/remove-connection`;

        return axios({
            method: 'DELETE',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    contactAnonymous: function(data, token) {
        const url = `${API_URL}/send-anonymous-mail`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
};

export default messagesService;