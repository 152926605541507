import React from 'react';
import Button from '@material-ui/core/Button';
import './ButtonInverted.scss';

function ButtonInverted(props) {
    return (
        <Button 
            className={
                "button-md button-inverted" + 
                (props.className ? (" " + props.className) : "")
            }
            disableRipple={true}
            disableElevation
            {...props}
        >
            {props.children}
        </Button>
    );
}

export default ButtonInverted;