import React, { useState } from 'react';
import { Grid, MobileStepper, IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './ImageStepper.scss';

function ImageStepper(props){

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = props.dataList.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSelectedImage = (e, value) => {
        setActiveStep(value);
    };

    return (
        <Grid
            className="image-stepper-container"
            direction="row"
            container
        >
            <Grid
                item
                className="image-selection-list"
            >
                {
                    props.dataList.map((item, value) => (
                        <div key={value} className={activeStep === value ? "selected-sample-image sample-image" :"sample-image"} onClick={(e) => handleSelectedImage(e, value)}>
                            <img
                                className="sample-image-content"
                                src={item.path}
                                alt={'Product'}
                            />
                        </div>
                    ))
                }
            </Grid>

            <Grid
                item
                className="image-container"
                //xs={12}
                //md={9}
            >
                <img
                    className="image-content"
                    src={props.dataList[activeStep].path}
                    alt={'Product'}
                />

                <MobileStepper
                    steps={maxSteps}
                    activeStep={activeStep}
                    position="static"
                    nextButton={
                    <IconButton size="medium" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                       <ChevronRightIcon fontSize="large"/> 
                    </IconButton>
                    }
                    backButton={
                    <IconButton size="medium" onClick={handleBack} disabled={activeStep === 0}>
                        <ChevronLeftIcon fontSize="large"/>
                    </IconButton>
                    }
                />
            </Grid>
        </Grid>
    )
}

export default ImageStepper;