import React from 'react';
import { Grid, ButtonBase, Avatar } from '@material-ui/core';
import { ButtonOutlined } from '../index';
import Moment from 'react-moment';
import "./Notification.scss";
import CheckCircle from '@material-ui/icons/CheckCircle';

function Notification(props) {
    if(!props.dataList || !props.dataList.basics) {
        return null;
    }

    const dataList = props.dataList;
    const basics = dataList.basics;
    const text = dataList.basics.description || dataList.text || null;
    const createdAt = dataList.created_at || null;
    
    return(
        <ButtonBase 
            className={
                "notification-container" + 
                (props.isList ? ' is-list' : '') +
                (props.isActive ? ' active' : '') +
                (dataList.notify ? ' notify' : '')
            }
            onClick={props.onClick}
            id={props.id}
        >
            <Grid
                container
                wrap="nowrap"
            >
                <Grid className="conversation-avatar">
                    {
                        (basics.logo &&
                        basics.logo.path) ?
                        <Avatar 
                            alt="Profile" 
                            src={basics.logo.path}
                        /> 
                        :
                        dataList.type === 'announcement' || dataList.type === 'report' ? 
                            null
                            :
                            <Avatar className = {basics.firstName ? null : "anonymous-user"} >
                                {
                                    basics.firstName ?
                                    basics.firstName.charAt(0).toUpperCase() : 
                                    null
                                }
                            </Avatar>
                    }
                </Grid>
                
                <Grid className={dataList.type === 'announcement' || dataList.type === 'report' ? "section-text text" : "section-text "}>
                    <div className="title-text">{basics.companyName || basics.title} {basics.verified ? <CheckCircle/> : null }</div>
                    <div className= {dataList.type === 'announcement' || dataList.type === 'report' ? "msg-txt" : "msg-text"}>
                        {text}
                    </div>
                </Grid>
                {
                    dataList.type === 'request' ?
                    <Grid 
                        className="section-btn"
                    >
                        <div className="confirm-btn">
                            Confirm
                        </div>
                    </Grid> 
                    :
                    <Grid className="section-date">
                        <div className="created-date">
                            {
                                createdAt ?
                                <Moment 
                                    date={createdAt}
                                    fromNow
                                /> : null
                            }            
                        </div>
                    </Grid> 
                }
            </Grid>
            <span className="notification-dot"></span>
        </ButtonBase>
    );
}

export default Notification;