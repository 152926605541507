import React from "react";
import { Dialog, Fab, Grid, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonOutlined } from "../_common/index";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import "./SearchBar.scss";
import countriesList from "../../constants/countries-list";
import { useHistory } from "react-router-dom";

function SearchBarModal({
  data,
  open,
  onClose,
  isRequest,
  onBtnClicked,
  onCloseSearch,
  btnDisabled = false,
  btnText = "Contact Supplier",
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  if (!open || !data) {
    return null;
  }

  const { name, description = "", basics } = data || {};
  const { tags = [], vendor = [], images: imageData = [] } = basics || {};
  const user = vendor?.[0] ? vendor?.[0] : null;

  const { country: ctrCode, profile_type, profile_id } = user || {};

  const ctrData = countriesList.children.find((ctr) => ctr.code === ctrCode);

  const country = ctrData?.name || "";

  // eslint-disable-next-line array-callback-return
  const images = imageData.filter((img) => {
    if (img) return img;
  });

  const handleClose = () => {
    onClose();
  };

  const handleProfileView = () => {
    handleClose();
    onCloseSearch();
    const url = `/auth/profile-view/${profile_id}/${profile_type}`;
    history.push(url);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      classes={{ container: "modal-display-container" }}
    >
      <Grid
        className="dialog-content"
        container
        direction="column"
        wrap="nowrap"
      >
        {fullScreen ? (
          <Grid
            className="header-container"
            container
            direction="row"
            justify="flex-end"
            item
          >
            <Fab
              aria-label="close"
              onClick={handleClose}
              disableFocusRipple={true}
              disableRipple={true}
            >
              <CloseIcon />
            </Fab>
          </Grid>
        ) : null}
        <Grid
          className="content-container"
          container
          direction="row"
          wrap="nowrap"
          justify="center"
          alignItems="center"
          item
          xs
        >
          <Grid container justify="center" alignItems="center" item xs>
            <div className="product-container">
              <Grid
                className="product-container-inner"
                container
                direction="row"
              >
                {images.length > 0 && (
                  <Grid
                    className=""
                    container
                    direction="row"
                    wrap="wrap"
                    item
                    xs={12}
                    md={6}
                  >
                    <Grid container item xs className="productImgContainer">
                      {images.map((img, idx) => {
                        const { path, name } = img || {};

                        const isLast = idx === images.length - 1;
                        const isOddLast = isLast
                          ? idx % 2 === 0
                            ? true
                            : false
                          : false;

                        return (
                          <div
                            key={name}
                            className={`productImg ${isOddLast ? "last" : ""}`}
                          >
                            <img src={path} alt="product poster" />
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}

                <Grid
                  className="product-details"
                  container
                  direction="column"
                  item
                  xs={12}
                  md={images.length > 0 ? 6 : 12}
                >
                  <Grid
                    className="product-details-title"
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    item
                  >
                    <div className="avatar-details">
                      <div className="avatar-name">
                        <div className="avatar-name-company">{name}</div>
                        {country && (
                          <p className="tagline-company">{country}</p>
                        )}
                      </div>
                    </div>

                    <ButtonOutlined
                      onClick={handleProfileView}
                      disabled={!profile_id}
                    >
                      Profile
                    </ButtonOutlined>
                  </Grid>
                  <Grid
                    className="product-details-main"
                    container
                    direction="column"
                    item
                    xs
                  >
                    <Grid item xs>
                      {ReactHtmlParser(description)}
                    </Grid>
                    <Grid
                      item
                      style={{ marginTop: 0 }}
                      className="details-tags-container"
                    >
                      {tags.map((tag) => (
                        <div
                          className="details-tag"
                          style={{ marginTop: "10px" }}
                          key={tag?.code}
                        >
                          {tag?.name}
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    className="product-details-btn"
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-end"
                    item
                  >
                    <ButtonOutlined
                      onClick={() => onBtnClicked(data)}
                      disabled={
                        !profile_id
                          ? true
                          : btnDisabled
                          ? btnDisabled
                          : isRequest
                      }
                    >
                      {isRequest ? (
                        <span className="dl-container">
                          <span className="dl-text">Processing</span>
                          <CircularProgress size={18} />
                        </span>
                      ) : (
                        btnText
                      )}
                    </ButtonOutlined>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default SearchBarModal;
