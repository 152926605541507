import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import './AutoCompleteComponent.scss'


function AutoCompleteComponent(props){
  return (
    <div className='autoComplete-container'>
      <Autocomplete
          multiple 
          filterSelectedOptions = {true} 	
          disabled={props.disabled}
          options={props.options}
          limitTags={props.limitTags}
          value={props.value}
          noOptionsText={props.noOptionsText}
          forcePopupIcon={props.forcePopupIcon}
          getOptionLabel={(option) => option[props.name] || option['name']}
          onChange={(event, newValue) => {
            props.onChange(newValue, props.code, event);
          }}
          renderInput={(params) => (
              <>
                <label className= {props.disabled ? 'disabled': 'label'}>{props.label}</label>
                {props.startAdornment ? 
                <TextField 
                  {...params} 
                  variant="outlined" 
                  placeholder={props.placeholder} 
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          {props.icon}
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }}/>
                  :
                  <TextField 
                    {...params} 
                    variant="outlined" 
                    placeholder={props.placeholder} 
                 />}
                </>
            )}
        />
    </div>
  );
}

export default AutoCompleteComponent;
