import React from 'react';
import './PasswordInstructionsComponent.scss';

function PasswordInstructions(){
    return (
        <div className="paragraph-container">
            <span>In order to protect your account make sure your password is:</span>
            <ul>
                <li>Longer than 8 characters</li>
                <li>Contains at least one special character and one number</li>
                <li>Contains at least one capital letter</li>
            </ul>
        </div>
    );
}

export default PasswordInstructions;