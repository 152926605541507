const freshChatInit = userData => {
    userData = userData || {};
    const token = process.env.REACT_APP_FRESHCHAT_TOKEN;
    const script = document.createElement('script');
    const freshChat = `
        function initFreshChat() {
            if(!window.fcWidget) return;
            
            window.fcWidget.init({
                token: "${token}",
                host: "https://wchat.freshchat.com"
            });

            window.fcWidget.user.setProperties({
                firstName: "${userData.firstName || ''}",                 
                lastName: "${userData.lastName || ''}",  
                subsType: "${userData.subsType || ''}",                 
                accountType: "${userData.accountType || ''}",   
                email: "${userData.email || ''}",                 
                accountCreated: "${userData.accountCreated || ''}"    
            });
        }
            
        function initialize(i,t) {
            var e;
            i.getElementById(t) ? initFreshChat() : ((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))
        }
            
        function initiateCall() {
            initialize(document,"freshchat-js-sdk")
        }
        
        initiateCall();
    `;
    script.innerHTML = freshChat;
    script.async = true;

    return script;
};

export default freshChatInit;