import React, { Suspense, lazy, useEffect } from "react";
import "./App.scss";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { selectors as authSelectors } from "./redux/modules/auth";
import { selectors as themeSelectors } from "./redux/modules/theme";
import { StylesProvider } from "@material-ui/core/styles";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { SuspenseFallback, Theme } from "./components/index";
import { useIdleTimer } from "react-idle-timer";
import { selectors as profileSelectors } from "./redux/modules/profile";
import { selectors as commonSelectors } from "./redux/modules/common";
import { DialogComponent } from "./components/_common";
import {
  actions as registerActions,
  selectors as registerSelectors,
} from "./redux/modules/register";
import { actions as authActions } from "./redux/modules/auth";
import { filterArray } from "./functions/converters";
import { getSectionType } from "./functions/compute";

const mouseMove = "mousemove";

const AccountManagement = lazy(() =>
  import("./components/AccountManagement/AccountManagement")
);
const AuthLayout = lazy(() => import("./components/AuthLayout/AuthLayout"));
const Brands = lazy(() => import("./components/Brands/Brands"));
const FastCustom = lazy(() => import("./components/FastCustom/FastCustom"));
const ComingSoon = lazy(() => import("./components/ComingSoon/ComingSoon"));
const CompleteProfileBuyer = lazy(() =>
  import("./components/CompleteProfileBuyer/CompleteProfileBuyer")
);
const CompleteProfileSeller = lazy(() =>
  import("./components/CompleteProfileSeller/CPSLayout/CPSLayout")
);
const CompleteRegistration = lazy(() =>
  import(
    "./components/RegisterSeller/CompleteProfileSeller/completeProfileSeller"
  )
);
const Impersonate = lazy(() => import("./components/Impersonate/Impersonate"));
const RegisterNewMember = lazy(() =>
  import("./components/RegisterNewMember/RegisterNewMember")
);
const Database = lazy(() => import("./components/Database/Database/Database"));
const DatabaseUpgrade = lazy(() =>
  import("./components/Database/DatabaseUpgrade/DatabaseUpgrade")
);
const DropHome = lazy(() => import("./components/Drops/DropHome/DropHome"));
const DropCheckout = lazy(() =>
  import("./components/Drops/DropCheckout/DropCheckout")
);
const DropCollection = lazy(() =>
  import("./components/Drops/DropCollection/DropCollection")
);
const DropPayment = lazy(() =>
  import("./components/Drops/DropPayment/DropPayment")
);
const DropOrderSuccessful = lazy(() =>
  import("./components/Drops/DropOrderSuccessful/DropOrderSuccessful")
);
const DropView = lazy(() => import("./components/Drops/DropView/DropView"));
const EmailVerification = lazy(() =>
  import("./components/EmailVerification/EmailVerification")
);
const HomeFeed = lazy(() => import("./components/HomeFeed/HomeFeed"));
const AgencyTrading = lazy(() =>
  import("./components/AgencyTrading/AgencyTrading")
);
const ListingsCreate = lazy(() =>
  import("./components/ListingsQuotes/ListingsCreate/ListingsCreate")
);
const ListingsQuotes = lazy(() =>
  import("./components/ListingsQuotes/ListingsQuotes/ListingsQuotes")
);
const ListingsUpgrade = lazy(() =>
  import("./components/ListingsQuotes/ListingsUpgrade/ListingsUpgrade")
);
const ListingsView = lazy(() =>
  import("./components/ListingsQuotes/ListingsView/ListingsView")
);
const Login = lazy(() => import("./components/Login/Login"));
const Messaging = lazy(() => import("./components/Messaging/Messaging"));
const Orders = lazy(() => import("./components/Orders/Orders/Orders"));
const OrderView = lazy(() => import("./components/Orders/OrderView/OrderView"));
const PasswordForgotten = lazy(() =>
  import("./components/PasswordForgotten/PasswordForgotten")
);
const PasswordReset = lazy(() =>
  import("./components/PasswordReset/PasswordReset")
);
const PasswordResetSuccess = lazy(() =>
  import("./components/PasswordResetSuccess/PasswordResetSuccess")
);
const Payment = lazy(() => import("./components/Payment/Payment"));
const PaymentCompleted = lazy(() =>
  import("./components/Payment/PaymentCompleted/PaymentCompleted")
);
const Profile = lazy(() =>
  import("./components/ProfileManagement/Profile/Profile")
);
const ProfileBuyerEdit = lazy(() =>
  import("./components/ProfileManagement/ProfileBuyerEdit/ProfileBuyerEdit")
);
const ProfileSellerEdit = lazy(() =>
  import("./components/ProfileManagement/ProfileSellerEdit/ProfileSellerEdit")
);
const QuickHelp = lazy(() => import("./components/QuickHelp/QuickHelp"));
const QuotesLayout = lazy(() =>
  import("./components/ListingsQuotes/QuotesCreate/QuotesLayout/QuotesLayout")
);
const QuotesResponse = lazy(() =>
  import("./components/ListingsQuotes/QuotesResponse/QuotesResponse")
);
const QuotesView = lazy(() =>
  import("./components/ListingsQuotes/QuotesView/QuotesView")
);
const RegisterBuyer = lazy(() =>
  import("./components/RegisterBuyer/RegisterBuyer")
);
const RegisterSeller = lazy(() =>
  import("./components/RegisterSeller/RegisterLayout/RegisterLayout")
);
const Reports = lazy(() => import("./components/Reports/Reports"));
const SavedItems = lazy(() => import("./components/SavedItems/SavedItems"));
const SearchResults = lazy(() =>
  import("./components/SearchResults/SearchResults")
);
const Services = lazy(() =>
  import("./components/Services/ServicesAll/ServicesAll")
);
const ServicesView = lazy(() =>
  import("./components/Services/ServicesView/ServicesView")
);
const SubsChanged = lazy(() =>
  import("./components/_common/SubsChanged/SubsChanged")
);
const YourCatalogue = lazy(() =>
  import("./components/ProfileManagement/YourCatalogue/YourCatalogue")
);
const YourCatalogueEdit = lazy(() =>
  import("./components/ProfileManagement/YourCatalogueEdit/YourCatalogueEdit")
);
const YourRFQ = lazy(() =>
  import("./components/ListingsQuotes/YourRFQ/YourRFQ")
);

function App(props) {
  const [open, setOpen] = React.useState(false);

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        "Source Sans Pro",
        "sans-serif",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  const handleOnAction = (event) => {
    if (
      props.taxonsData &&
      props.clientData &&
      !props.clientData.basics.fully_registered &&
      props.clientData.basics.kind == "S"
    ) {
      if (event.type != mouseMove) {
        switch (getSectionType(props.clientData.basics.type)) {
          case "SG":
            props.setTaxonsSelected(
              filterArray(props.taxonsData, "code", "sg_garment_maker")
            );
            break;
          case "SI":
            props.setTaxonsSelected(
              filterArray(props.taxonsData, "code", "si_input_supplier")
            );
            break;
          case "SS":
            props.setTaxonsSelected(
              filterArray(props.taxonsData, "code", "ss_service_provider")
            );
            break;
          default:
            break;
        }
        setTimeout(() => {
          if (!open) {
            setOpen(true);
          }
        }, 10000);
      }
    }
  };

  useIdleTimer({
    onAction: handleOnAction,
  });

  function Auth() {
    if (!props.auth) {
      if (window) {
        const urlReferer = window.location.pathname;
        if (props.setReferer) {
          props.setReferer(urlReferer);
        }
      }
      return <Redirect to="/login" />;
    }

    return (
      <AuthLayout>
        <Switch>
          {/* order is very important!!! */}
          <Route path="/auth/home" exact component={HomeFeed} />
          <Route
            path="/auth/profile-own"
            exact
            component={Profile}
            key="profile-own"
          />
          <Route
            path="/auth/profile-view/:id/:type"
            exact
            component={Profile}
            key="profile-view"
          />
          <Route
            path="/auth/profile/edit-buyer"
            exact
            component={ProfileBuyerEdit}
          />
          <Route
            path="/auth/profile/edit-seller"
            exact
            component={ProfileSellerEdit}
          />
          <Route
            path="/auth/account-settings"
            exact
            component={AccountManagement}
          />
          {/* <Route path="/auth/brands" exact component={Brands} /> */}
          <Route path="/auth/catalogue" exact component={YourCatalogue} />
          <Route
            path="/auth/catalogue/create"
            exact
            component={YourCatalogueEdit}
          />
          <Route
            path="/auth/catalogue/edit/:id"
            exact
            component={YourCatalogueEdit}
          />
          <Route path="/auth/search-results" exact component={SearchResults} />
          <Route path="/auth/messages" exact component={Messaging} />
          <Route path="/auth/database" exact component={Database} />
          <Route
            path="/auth/database/upgrade-now"
            exact
            component={DatabaseUpgrade}
          />
          <Route path="/auth/drops" exact component={DropHome} />
          <Route path="/auth/drops/check-out" exact component={DropCheckout} />
          <Route path="/auth/drops/payment" exact component={DropPayment} />
          <Route
            path="/auth/drops/payment-completed"
            exact
            component={DropOrderSuccessful}
          />
          <Route
            path="/auth/drops/collection/:segment/:category/:material"
            exact
            component={DropCollection}
          />
          <Route
            path="/auth/drops/view/:segment/:category/:id"
            exact
            component={DropView}
          />
          <Route path="/auth/fast-custom" exact component={FastCustom} />
          <Route path="/auth/listings" exact component={ListingsQuotes} />
          <Route
            path="/auth/listings/create"
            exact
            component={ListingsCreate}
          />
          <Route
            path="/auth/listings/quotes/response/:id"
            exact
            component={QuotesResponse}
          />
          <Route
            path="/auth/listings/quotes/yourRFQ/:id"
            exact
            component={YourRFQ}
          />
          <Route
            path="/auth/listings/upgrade-now"
            exact
            component={ListingsUpgrade}
          />
          <Route
            path="/auth/listings/view-all"
            exact
            component={ListingsView}
          />
          <Route
            path="/auth/listings/quotes/view-all"
            exact
            component={QuotesView}
          />
          <Route path="/auth/orders" exact component={Orders} />
          <Route path="/auth/orders/:id" exact component={OrderView} />
          <Route path="/auth/payment" exact component={Payment} />
          <Route path="/auth/quick-help" exact component={QuickHelp} />
          <Route path="/auth/requests" exact component={ListingsQuotes} />
          <Route
            path="/auth/requests/quotes/create"
            exact
            component={QuotesLayout}
          />
          <Route
            path="/auth/requests/quotes/yourRFQ/:id"
            exact
            component={YourRFQ}
          />
          <Route
            path="/auth/requests/quotes/view-all"
            exact
            component={QuotesView}
          />
          <Route
            path="/auth/requests/RFI/create"
            exact
            component={ListingsCreate}
          />
          <Route
            path="/auth/requests/upgrade-now"
            exact
            component={ListingsUpgrade}
          />
          <Route
            path="/auth/requests/view-all"
            exact
            component={ListingsView}
          />
          <Route path="/auth/reports" exact component={Reports} />
          <Route path="/auth/saved-items" exact component={SavedItems} />
          <Route path="/auth/services" exact component={Services} />
          <Route
            path="/auth/services/view/:id"
            exact
            component={ServicesView}
          />
          <Route path="/auth/agency-trading" exact component={AgencyTrading} />
          <Route path="/impersonate" exact component={Impersonate} />
          <Redirect from="/auth" to="/auth/home" />
        </Switch>
        <DialogComponent
          registerClassName={"register-dialog"}
          open={open}
          content={<CompleteRegistration />}
          disableAction={true}
        />
      </AuthLayout>
    );
  }

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Theme themeName={props.themeName}>
            <ErrorBoundary>
              <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                  {/* order is very important!!! */}
                  <Route path="/login" exact component={Login} />
                  <Route
                    path="/register-buyer"
                    exact
                    component={RegisterBuyer}
                  />
                  <Route
                    path="/password-forgotten"
                    exact
                    component={PasswordForgotten}
                  />
                  <Route
                    path="/password-reset"
                    exact
                    component={PasswordReset}
                  />
                  <Route
                    path="/password-reset-success"
                    exact
                    component={PasswordResetSuccess}
                  />
                  <Route
                    path="/email-verification"
                    exact
                    component={EmailVerification}
                  />
                  <Route
                    path="/complete-profile-buyer"
                    exact
                    component={CompleteProfileBuyer}
                  />
                  <Route
                    path="/register-seller"
                    exact
                    component={RegisterSeller}
                  />
                  <Route
                    path="/complete-profile-seller"
                    exact
                    component={CompleteProfileSeller}
                  />
                  <Route
                    path="/register-new-member"
                    exact
                    component={RegisterNewMember}
                  />
                  <Route
                    path="/payment-completed"
                    exact
                    component={PaymentCompleted}
                  />
                  <Route path="/impersonate" exact component={Impersonate} />
                  <Route path="/subs-changed" exact component={SubsChanged} />
                  <Route path="/auth" component={Auth} />
                  <Redirect from="/" to="/login" />
                  {/* <Route component={PageNotFound} /> */}
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Theme>
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    themeName: themeSelectors.getThemeName(state),
    taxonsData: commonSelectors.getTaxonsData(state),
    activeStep: registerSelectors.getActiveStep(state),
  }),
  {
    setTaxonsSelected: registerActions.setTaxonsSelected,
    setReferer: authActions.setReferer,
  }
)(App);
