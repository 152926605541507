import React from 'react';
import { Fab, Grid, Avatar } from '@material-ui/core';
import { ButtonOutlined } from '../../_common/index';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import './CardNetwork.scss';
import CheckCircle from '@material-ui/icons/CheckCircle';

function CardNetwork(props) {
    if(!props.cardData) return null;

    const cardData = props.cardData;
    const basics = cardData.basics;
    const lastMessage = cardData.last_message;
    const attachedFile = (lastMessage && lastMessage.attached_file && lastMessage.attached_file[0]) ? (lastMessage.attached_file[0].name || null) : null;
    const contents = lastMessage ? (lastMessage.contents || attachedFile) : null;

    return (
        <Grid
            className="card-network-container"
            container
            alignItems="center"
        >
            <Grid item xs={12} sm={'auto'}>
                {
                    (basics.logo && basics.logo.path) ?
                    <Avatar 
                        alt="Profile" 
                        src={basics.logo.path}
                        className="card-avatar"
                    /> 
                    :
                    <Avatar
                        alt="Profile" 
                        className="card-avatar card-avatar-letter"
                    >
                        {
                            basics.firstName ? 
                            basics.firstName.charAt(0).toUpperCase() : 
                            null
                        }
                    </Avatar>
                }
            </Grid>
            <Grid item xs={12} sm className="section-text">
                <div className="title-text">
                    {basics.firstName} {basics.lastName}
                    {
                        basics.companyName ?
                        <>{' | ' + basics.companyName}  </> : null
                    } {basics.verified ? <CheckCircle/> : null }
                </div>
                <div className="msg-text">
                    {contents || 'No messages'}
                </div>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                {
                    props.onDelete ?
                    <Fab 
                        className="delete-btn"
                        aria-label="delete network"
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick={() => props.onDelete(cardData)}
                        disabled={props.isRequest}
                    >
                        <DeleteOutlineIcon />
                    </Fab> : null
                }
                {
                    props.onMessage ?
                    <ButtonOutlined
                        onClick={() => props.onMessage(cardData)}
                        disabled={props.isRequest}
                    >
                        Message
                    </ButtonOutlined> : null
                }   
            </Grid>
        </Grid>
    )
};


export default CardNetwork;