import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom";
import './ButtonBackArrowComponent.scss';

function ButtonBackArrowComponent (props){
    let history = useHistory(); 

    const handleNavigation = () => {
        history.push(props.link);
    };

    return (
        <Fab 
            className="btn-back-arrow"
            aria-label="close"
            onClick={handleNavigation}
            disableFocusRipple={true}
            disableRipple={true}
        >
            <ArrowBackIosIcon/>
        </Fab>
    )
}

export default ButtonBackArrowComponent;