import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import './RadioGroupComponent.scss';

function RadioGroupComponent(props) {

    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            {
                props.title ?
                <FormLabel component="legend">{props.title}</FormLabel> : null
            }
            
            <RadioGroup 
                aria-label="radio-group" 
                name="radioGroup" 
                value={props.value} 
                onChange={handleChange}
            >
                {
                    props.radioList.map((item) => (
                        <FormControlLabel 
                            {...item} 
                            className={item.value === props.value && props.className ? props.className : null}
                            control={<Radio />}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
}

export default RadioGroupComponent;