import React, {useState} from 'react';
import { Grid, ButtonBase, Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NumberFormat from 'react-number-format';
import { Chip } from '../../_common/index';
import getSymbolFromCurrency from 'currency-symbol-map';
import './CardDrops.scss';

function CardDrops(props) {
    const dataList = props.dataList;
    const description = dataList.description + ' | ' + dataList.colour;
   
    return(
        <Grid item className="drops-container">
            <ButtonBase>
            <a onClick={props.onClick} href={`/auth/drops/view/${props.segmentParams}/${props.categoryParams}/${dataList.id}`}>       
                {
                    dataList.images ?
                    <div className="drop-image-container">
                        <img
                            className="image-content"
                            src={dataList.images[0].path}
                            alt={'Product'}
                        />
                    </div>
                    :null
                }
                {dataList.few_left || dataList.out_of_stock ? <span className="drop-few-left"> {dataList.few_left ? 'Few left' : 'Out of Stock Contact Us'} </span> : null}
                <Grid 
                    className="drops-content-description"
                    direction="column"
                    container
                >
                    <span className="drop-title"> {dataList.title} </span>
                    <span className="drop-description"> {description} </span>
                    <div className="drop-tags">
                        <Chip
                            label= { 
                                <NumberFormat
                                    value={dataList.unit_price * 10}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={getSymbolFromCurrency(dataList.preferred_currency)}
                                    suffix={' for 10 pieces'}
                                />
                            }
                        />
                        {dataList.discount ? 
                        <Tooltip
                            arrow
                            title="Bulk Discounts Available"
                            placement="top"
                        >
                            <CheckCircleOutlineIcon className="discount-tool"/>
                        </Tooltip>  
                        : 
                        null}
                    </div> 
                </Grid>
           </a>
           </ButtonBase>
        </Grid>
    )
}

export default CardDrops;