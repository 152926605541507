import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import {
  TitleHeading,
  ButtonBack,
  ButtonNext,
  GroupCheckboxGrid,
} from "../../_common/index";
import { filterArray } from "../../../functions/converters";
import Grid from "@material-ui/core/Grid";
import "./ProductionCapabilities.scss";

function ProductionCapabilities(props) {
  const heading = "Production Capabilities";
  const subHeading = "Select the parts of the supply chain that you control";
  const headingTwo = "Vertical Integrations Visualization:";
  const subHeadingTwo =
    "Depending on your selections you can fall into these categorizations:";
  const requestData = filterArray(
    props.taxonsSelected[0].children,
    "code",
    "sg_capabilities"
  );

  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  if (props.registerSellerData["production_capabilities"]) {
    checkboxInit = Object.assign(
      {},
      props.registerSellerData["production_capabilities"]
    );
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
      });

      checkboxInit[item.code] = children;
    });
  }

  const [checkboxValues, setCheckboxValues] = useState(checkboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, value) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][value.name] = value.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      production_capabilities: checkboxValues,
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="prodcap-container-reg">
      <TitleHeading title={heading} subtitle={subHeading} />

      {requestData.map((item) => (
        <GroupCheckboxGrid
          naming={checkboxNaming}
          title={item.name}
          name={item.code}
          checkboxList={item.children}
          key={item.code}
          values={checkboxValues[item.code]}
          onChange={handleCheckboxChange}
        />
      ))}
      <TitleHeading
        title={headingTwo}
        subtitle={subHeadingTwo}
        className="img-heading-title"
      />
      <img
        src="/assets/images/vertical-integrations.png"
        className="prod-img"
        alt="vertical integrations"
      />

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(ProductionCapabilities);
