import React, { useState } from 'react';
import { Grid, Chip, Divider } from '@material-ui/core';
import './Filter.scss';

function Filter(props) {
    if(!props.dataList) {
        return null;
    }

    return (
        <div className="filter-container">
            <div className="filter-label">{props.label}</div>
            <div className="filter-chips">
                {
                    props.dataList.map((section, index) =>
                        <React.Fragment key={section.code}>
                            {
                                index > 0 ?
                                <Divider 
                                    orientation="vertical" 
                                    flexItem 
                                /> : null
                            }
                            {
                                section.children.map((item) =>
                                    <Chip
                                        key={item.code}
                                        label={item.name}
                                        onClick={() => props.onFilterSelected(item)}
                                        variant={(props.selectedFilter === item.code) ? 'default' : 'outlined'}
                                    />
                                )
                            }
                        </React.Fragment>             
                    )
                }             
            </div>
        </div>
    );
}

export default Filter;