import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import './TextFieldSize.scss';

function TextFieldSize(props) {
    return (
        <Grid container direction="column" className="text-field-size-container">
            <label htmlFor={props.name}>{props.labelname}</label>
            <TextField 
                variant="outlined"
                margin="dense"
                {...props}
            />
        </Grid>       
    );
}

export default TextFieldSize;