import React, { useState, useEffect } from "react";
import {
  TitleHeading,
  TextFieldOther,
  ButtonNext,
  ButtonBack,
  GroupCheckboxGrid,
} from "../../_common/index";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { filterArray } from "../../../functions/converters";
import { Grid } from "@material-ui/core";
import "./Products.scss";

function Products(props) {
  const title = "What Products do you make?";
  const subtitle = "Select those that apply";
  let requestData = [];
  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  switch (props.registerSellerData["supplier_type"]) {
    case "SG":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "sg_product"
      )[0].children;
      break;
    case "SI":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "si_product"
      )[0].children;
      break;
    default:
      requestData = [];
  }

  if (props.registerSellerData["products"]) {
    checkboxInit = Object.assign({}, props.registerSellerData["products"]);
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
        children["other"] = "";
      });

      checkboxInit[item.code] = children;
    });
  }

  const [checkboxValues, setCheckboxValues] = useState(checkboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][target.name] = target.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleOtherChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name]["other"] = target.value;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      products: checkboxValues,
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="products-container-reg">
      <TitleHeading title={title} subtitle={subtitle} />
      {requestData.map((item) => (
        <div key={item.code}>
          <GroupCheckboxGrid
            naming={checkboxNaming}
            title={item.name}
            name={item.code}
            checkboxList={item.children}
            values={checkboxValues[item.code]}
            onChange={handleCheckboxChange}
          />
          <TextFieldOther
            id={item.name}
            className="text-field-other-override"
            name={item.name}
            value={checkboxValues[item.code].other}
            onChange={(e) => handleOtherChange(item.code, e.target)}
          />
        </div>
      ))}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(Products);
