//Module Name
export const name = "search";

// Actions
export const types = {
  SET_SEARCH_VALUE: "SET_SEARCH_VALUE",
  SET_SEARCH: "SET_SEARCH",
  SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
};

const initialState = {
  searchValue: null,
  search: "",
  searchResults: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_SEARCH_VALUE: {
      const { content } = action.payload;

      return {
        ...state,
        searchValue: content,
      };
    }
    case types.SET_SEARCH_RESULTS: {
      const { content } = action.payload;

      return {
        ...state,
        searchResults: content,
      };
    }
    case types.SET_SEARCH: {
      const { content } = action.payload;

      return {
        ...state,
        search: content,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const actions = {
  setSearchValue: (content) => ({
    type: types.SET_SEARCH_VALUE,
    payload: {
      content,
    },
  }),
  setSearch: (content) => ({
    type: types.SET_SEARCH,
    payload: {
      content,
    },
  }),
  setSearchResults: (content) => ({
    type: types.SET_SEARCH_RESULTS,
    payload: {
      content,
    },
  }),
};

// Selectors
export const selectors = {
  getSearchValue: (store) => store[name].searchValue,
  getSearch: (store) => store[name].search,
  getSearchResults: (store) => store[name].searchResults,
};
