export const errorMessages = { 
    invalidLogin: 'The Email Address and Password you entered did not match our records. Please double-check and try again.', 
    errorApi: 'Sorry, an error occurred while processing your request.',
    getCountryList: 'An error occurred. Failed to retrieve list of countries.',
    getTaxons: 'An error occurred. Failed to retrieve list of tags.',
    emailExists: 'An Email has already been registered using this email address. Please consult inbox / Junk to finalize profile creation - or re-enter address to receive new activation email',
    emailExists3: 'This email already exists, if it is you click on "Log In" link below.',
    emailExists2: 'This email already exists. Please use another email.',
    companyExists: 'This company name already exists, if it is you click on "Log In" link below.',
    percentageError: 'Values should add up to 100%',
    emailErrorApi: 'The email you entered does not match our records, please double check for mistakes.',
    verifyAccount: 'An error occurred. Failed to verify your account.',
    requestFailed: 'Sorry, an error occurred. Failed to retrieve data. Please refresh this page',
    invalidCoupon: 'This Gift Card or Coupon is invalid',
    connRequestExceeded: 'Connection requests to this user have been rejected twice. You can no longer connect to this user.',
    connDeleted: 'This user no longer forms part of your connections.',
    invalidQuantity: 'Quantity you entered is invalid',
    quantityExceeded: 'Quantity cannot exceed the amount in stock',
    limitExceeded: 'Your subscription plan limit has been reached for this functionality.',
    couponInvalid: 'This Discount code is invalid',
    couponError: 'An error occurred while verifying the Discount code',
    bankTransferBlocked: 'Once we receive your funds, you will receive a notification email and you will be granted access to our platform.'
};

export const successMessages = { 
    connectionRequestMade: 'A connection request has been sent to the user. The user will appear in your list of connections if the request is accepted.',
    connectionRequestExist: 'A connection request has already been sent to this user. The user will appear in your list of connections if the request is accepted.',
    couponValid: 'Coupon Code was successfully applied.',
    contactAnonymous: 'Your interest has been shared with the requestor.'
};