import React from 'react';
import { Dialog } from '@material-ui/core';
import { Button, CroppedImage } from '../index';
import './CropperDialog.scss';

function CropperDialog(props) {
    return (
        <Dialog 
            onClose={props.onDialogClose} 
            aria-labelledby="image-cropping" 
            open={props.dialogOpen}
        >
            <div className="cropper-container">
                <CroppedImage 
                    image={props.image}
                    onCropComplete={props.onCropComplete}
                    aspect={props.aspect}
                />
            </div>
            <div className="cropper-text">
                *Pinch or scroll to zoom in or out
            </div>
            <div className="cropper-button-wrapper">
                <Button 
                    className="button-md button-inverted" 
                    variant="outlined"
                    onClick={props.onDialogClose}
                >
                    Cancel
                </Button>
                <Button 
                    className="button-md"
                    onClick={props.onSaveImage}
                >
                    Save
                </Button>
            </div>
        </Dialog>
    );
}

export default CropperDialog;