import React from 'react';
import { TitleHeading, Checkbox, Label } from '../index';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './GroupCheckboxGridComponent.scss';

function GroupCheckboxGridComponent(props){

    const handleChange = (name, event) => {
        props.onChange(name, event.target);
    }; 

    return(
        <div className='checkbox-grid-container'>
            {
                props.title ?
                <div className="checkbox-grid-title">
                    {props.title}
                </div> : null
            }
            <Grid 
                container 
                direction="row" 
                className='checkbox-grid'
                justify="flex-start"
                alignItems="flex-start"
            >
                {props.checkboxList.map((item, index) =>(
                    <Grid 
                        className="checkbox-grid-item"
                        container 
                        item 
                        direction='row'
                        key={item[props.naming.name]} 
                        xs={props.xs || 12}
                        sm={props.sm || 6}
                        md={props.md || 6}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={props.values[item[props.naming.name]] || false} 
                                    onChange={(e) => handleChange(props.name, e)} 
                                    name={item[props.naming.name]}
                                />
                            }
                            label={item[props.naming.label]}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default GroupCheckboxGridComponent;

