import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  smallbutton: {
    width: "auto !important",
    minWidth: "auto !important",
    padding: "3px 9px !important",
    marginBottom: "0 !important",
  },
  button: {
    marginBottom: "0 !important",
    "&:hover": {
        zIndex: "999",
    }
  },
  buttongroup: {
    marginBottom: "16px",
  },
  popper: {
    zIndex: "999",
  }
});

const ButtonWithDropdown = ({ onClick, buttonText, options }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event, callback) => {
    setOpen(false);
    if (callback) callback();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        disableRipple={true}
        disableElevation
        ref={anchorRef}
        className={classes.buttongroup}
      >
        <Button onClick={onClick} className={classes.button}>{buttonText}</Button>
        <Button
          size="small"
          onClick={handleToggle}
          className={classes.smallbutton}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.callback)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonWithDropdown;
