import React, { useEffect } from "react";
import {
  TitleHeading,
  GroupCheckboxGrid,
  TextField,
  TextFieldOther,
  ButtonNext,
  ButtonBack,
} from "../../_common/index";
import { Grid, FormControl, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import { filterArray } from "../../../functions/converters";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import "./MainMarkets.scss";

function MainMarkets(props) {
  const title = "What are your main markets?";
  const subtitle = "Key Customers";
  let requestData = [];
  const keyCustomers = [
    { key: "value1" },
    { key: "value2" },
    { key: "value3" },
    { key: "value4" },
    { key: "value5" },
  ];

  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  switch (props.registerSellerData["supplier_type"]) {
    case "SG":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "sg_markets"
      );
      break;
    case "SI":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "si_markets"
      );
      break;
    default:
      break;
  }

  if (props.registerSellerData["main_markets"]) {
    checkboxInit = Object.assign({}, props.registerSellerData["main_markets"]);
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
      });

      checkboxInit[item.code] = children;
    });

    checkboxInit["keyCustomers"] = {};
    keyCustomers.forEach((item) => {
      checkboxInit["keyCustomers"][item["key"]] = "";
    });
  }

  const [checkboxValues, setCheckboxValues] = React.useState(checkboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][target.name] = target.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleTextFieldChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues["keyCustomers"][name] = target.value;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      main_markets: checkboxValues,
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="mainMarket-container-reg">
      <TitleHeading title={title} />
      {requestData.map((item) => (
        <div key={item.code} className="mainMarket-content">
          <GroupCheckboxGrid
            naming={checkboxNaming}
            title={item.name}
            name={item.code}
            checkboxList={item.children}
            values={checkboxValues[item.code]}
            onChange={handleCheckboxChange}
            md={4}
          />

          <div className="keyCustomers-container">
            <TitleHeading title={subtitle} />
            {keyCustomers.map((customer, index) => (
              <Grid
                key={customer["key"]}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <div className="keyCustomers-numbers">{index + 1}.</div>
                <TextFieldOther
                  placeholder=""
                  id={customer["key"]}
                  name={customer["key"]}
                  value={checkboxValues["keyCustomers"][customer["key"]]}
                  onChange={(e) =>
                    handleTextFieldChange(customer["key"], e.target)
                  }
                />
              </Grid>
            ))}
          </div>
        </div>
      ))}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(MainMarkets);
