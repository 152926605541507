//Module Name
export const name = 'orders';

// Actions
export const types = {
    SET_ORDERS_DATA: 'SET_ORDERS_DATA',
};

const initialState = {
   ordersData: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_ORDERS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                ordersData: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setOrdersData: content => ({
        type: types.SET_ORDERS_DATA,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getOrdersData: store => store[name].ordersData,
};