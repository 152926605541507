export const footerList = [
    {
        id: 0,
        name: 'Home Page',
        link: 'https://www.abana.mu/'
    },
    {
        id: 1,
        name: 'About',
        link: 'https://www.abana.mu/about/'
    },
    {
        id: 2,
        name: 'Help',
        link: '#'
    },
    {
        id: 3,
        name: 'Privacy',
        link: 'https://www.abana.mu/privacy/'
    },
    {
        id: 4,
        name: 'Terms',
        link: 'https://www.abana.mu/terms-conditions/'
    },
    {
        id: 5,
        name: 'Representatives',
        link: 'https://www.abana.mu/country-reps/'
    },
];