import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  Button,
  ButtonOutlined,
  TextField,
  ButtonInverted,
  RadioGroup,
  TitleHeading,
  Chip,
  Loading,
} from "../../_common/index";
import {
  subscriptionListBuyer,
  subscriptionListSeller,
} from "../../../constants/subscription";
import { errorMessages } from "../../../constants/messages";
import {
  getSubsPlans,
  getSubsPeriodLevel,
  getSectionType,
  getPlan,
  getSubsPeriodMonths,
  getSubsType,
} from "../../../functions/compute";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import { selectors as commonSelectors } from "../../../redux/modules/common";
import { selectors as authSelectors } from "../../../redux/modules/auth";
import { actions as commonActions } from "../../../redux/modules/common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import commonService from "../../../services/commonService";
import "./SubscriptionPlan.scss";
import { getUrlParams } from "../../../functions/converters";

const axios = require("axios");
const CancelToken = axios.CancelToken;

const SectionButtons = (props) => {
  const { currentSubs, currentPeriod, selectedPeriod, isFreeTrial, userKind } =
    props;

  function getButtonText(subsLevel) {
    const currentPeriodLevel = getSubsPeriodLevel(currentPeriod);
    const selectedPeriodLevel = getSubsPeriodLevel(selectedPeriod);

    if (userKind === "B" && isFreeTrial) {
      switch (subsLevel) {
        case 0:
          return "Current Plan";
        case 1:
          return "Select";
        case 2:
          return "Pay for Upgrade";
      }
    }

    if (currentSubs > subsLevel) {
      return "Downgrade";
    }

    if (currentSubs == subsLevel) {
      if (currentPeriodLevel == selectedPeriodLevel) {
        return "Current Plan";
      }

      return "Change Billing";
    }

    if (currentSubs < subsLevel) {
      return "Upgrade";
    }
  }

  const handleSelect = (value, type) => {
    props.onSelect(value, type);
  };

  return (
    <Grid
      className={
        "subscription-section-buttons" + (props.top ? " section-top" : "")
      }
      container
      direction="row"
    >
      <Grid
        className="section-info-feature"
        item
        xs
        container
        justify="flex-start"
        alignItems="center"
      ></Grid>
      {props.subList.map((plan) => (
        <Grid
          className={
            "section-info-value" +
            (getButtonText(plan.subscriptionLevel) === "Current Plan"
              ? " most-popular"
              : "") +
            (!currentPeriod && (plan.id === "second" || plan.id === "first")
              ? " most-popular"
              : "")
          }
          item
          xs
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          key={plan.id}
        >
          {props.top &&
          getButtonText(plan.subscriptionLevel) === "Current Plan" ? (
            <div className="most-popular-tab">Your plan</div>
          ) : null}
          {props.top &&
          !currentPeriod &&
          (plan.id === "second" || plan.id === "first") ? (
            <div className="most-popular-tab"> Most Popular</div>
          ) : null}
          {props.top ? (
            <div className="section-info-plan">{plan.name}</div>
          ) : null}
          {props.top ? (
            plan.monthly_price > 0 ? (
              <>
                <div className="price-section">
                  <div className="section-info-prices">
                    <span className="info-currency">$</span>
                    <span className="info-price">{plan.price}</span>
                    <span className="info-month">/MO</span>
                  </div>
                  {plan.id === "zero" ? null : (
                    <span className="billed-once">billed once*</span>
                  )}
                </div>

                {plan.id === "zero" ? null : (
                  <div className="billed-monthly">
                    ${plan.monthly_price}/mo billed monthly
                  </div>
                )}
              </>
            ) : (
              <div className="section-info-prices price-section">
                <span className="info-price">FREE</span>
              </div>
            )
          ) : null}
          {plan.id === "second" ? (
            <>
              {currentSubs || currentSubs == 0 ? (
                <Button
                  className={
                    getButtonText(plan.subscriptionLevel) === "Current Plan"
                      ? "button-md section-button current-plan-button"
                      : "button-md section-button"
                  }
                  onClick={() =>
                    handleSelect(plan.id, getButtonText(plan.subscriptionLevel))
                  }
                  disabled={
                    (getButtonText(plan.subscriptionLevel) === "Current Plan"
                      ? true
                      : false) || !props.isAdmin
                  }
                >
                  {getButtonText(plan.subscriptionLevel)}
                </Button>
              ) : (
                <Button
                  className="button-md section-button"
                  onClick={() => handleSelect(plan.id, null)}
                >
                  Select
                </Button>
              )}
            </>
          ) : (
            <>
              {currentSubs || currentSubs == 0 ? (
                <ButtonOutlined
                  className={
                    getButtonText(plan.subscriptionLevel) === "Current Plan"
                      ? "button-md section-button current-plan-button"
                      : "button-md section-button"
                  }
                  onClick={() =>
                    handleSelect(plan.id, getButtonText(plan.subscriptionLevel))
                  }
                  disabled={
                    (getButtonText(plan.subscriptionLevel) === "Current Plan"
                      ? true
                      : false) || !props.isAdmin
                  }
                >
                  {getButtonText(plan.subscriptionLevel)}
                </ButtonOutlined>
              ) : (
                <ButtonOutlined
                  className="button-md section-button"
                  onClick={() => handleSelect(plan.id, null)}
                >
                  Select
                </ButtonOutlined>
              )}
            </>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const SectionInfo = (props) => {
  const { list, userType } = props;

  return (
    <div className="subscription-section-info">
      <Grid
        className="section-info-title"
        container
        justify="flex-start"
        alignItems="center"
      >
        {list.title}
      </Grid>
      {list.children.map((item) => (
        <Grid container direction="row" key={item.key}>
          <Grid
            className="section-info-feature"
            item
            xs
            container
            justify="flex-start"
            alignItems="center"
          >
            {item.feature}
          </Grid>
          {item.zero && getSectionType(userType) === "SS" ? (
            <Grid
              className="section-info-value"
              item
              xs
              container
              justify="center"
              alignItems="center"
            >
              {item.zero}
            </Grid>
          ) : null}
          {item.first ? (
            <Grid
              className="section-info-value"
              item
              xs
              container
              justify="center"
              alignItems="center"
            >
              {item.first}
            </Grid>
          ) : null}
          {item.second ? (
            <Grid
              className="section-info-value"
              item
              xs
              container
              justify="center"
              alignItems="center"
            >
              {item.second}
            </Grid>
          ) : null}
          {item.third ? (
            <Grid
              className="section-info-value"
              item
              xs
              container
              justify="center"
              alignItems="center"
            >
              {item.third}
            </Grid>
          ) : null}
        </Grid>
      ))}
    </div>
  );
};

function SubscriptionPlan(props) {
  const { freeTrial } = props;
  const urlParams = getUrlParams(window.location.href);
  const type = urlParams["type"] || "S";
  const userKind = props.clientData
    ? props.clientData.basics.kind
    : type.charAt(0);
  const nextBill = props.clientData ? props.clientData.basics.next_bill : null;
  const userType = props.clientData
    ? props.clientData.basics.type
    : props.sellerType;
  const subscriptionType = props.clientData
    ? props.clientData.basics.subscriptionType
    : null;
  const subscriptionPeriod = props.clientData
    ? props.clientData.basics.period
    : null;
  const currentPeriod = props.subsPeriod;
  const currentSubs = props.subscription;
  //const currentPlanText = getCurrentPlanText(getSubsType(currentSubs, userKind), currentPeriod, props.subsPlans);
  const isFreeTrial = Boolean(currentSubs == 0 && freeTrial);
  const subscriptionList =
    userKind === "B" ? subscriptionListBuyer : subscriptionListSeller;
  const isFreeBuyer = Boolean(userKind === "B" && currentSubs == 0);

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.verifyAccount);
  const [isRequest, setIsRequest] = useState(false);
  const [inFull, setInFull] = useState(
    props.clientData ? props.clientData.basics.in_full : false
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    props.subsPeriod
      ? props.subsPeriod !== "quarterly"
        ? props.subsPeriod
        : "semesterly"
      : "semesterly"
  );
  const [subList, setSubList] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discountStatus, setDiscountStatus] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const radioList = [
    {
      key: 1,
      value: "semesterly",
      label: (
        <>
          <div>
            6 months <span>Contract</span>
          </div>
        </>
      ),
    },
    {
      key: 2,
      value: "annually",
      label: (
        <>
          <div>
            12 months <span>Contract</span>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.subsPlans) {
      commonService
        .getSubsPlans(cancelToken)
        .then(function (response) {
          if (response && response.data) {
            const responseData = response.data;

            if (userKind === "B") {
              props.setSubsPlans({
                ...responseData.customer,
                user: "customer",
              });
              return false;
            }

            props.setSubsPlans({
              ...responseData.vendor,
              user: "vendor",
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, []);

  const handleOnSelect = (subscription, type) => {
    props.onSelect(subscription, selectedPeriod, type, inFull);
  };

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
    setSubList(
      getSubsPlans(value, props.subsPlans, userKind, getSectionType(userType))
    );
  };

  const handleApplyCode = () => {
    if (!discountCode) return;

    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });
    setDiscountStatus(null);

    commonService
      .verifyCoupon(
        discountCode,
        props.auth.user_id ? props.auth.user_id : null,
        props.clientData ? props.clientData.basics.type : null,
        cancelToken
      )
      .then(function (response) {
        if (response && response.data) {
          if (props.onApplyCode) {
            props.onApplyCode(discountCode);
          }

          setDiscountStatus(
            `Discount ${response.data.coupon_code.name} applied`
          );
          setDiscountCode("");
          setExpanded(false);
          return;
        }

        setDiscountStatus(errorMessages.couponInvalid);
      })
      .catch(function (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          setDiscountStatus(errorMessages.couponInvalid);
          return;
        }
        setDiscountStatus(errorMessages.couponError);
      });
  };

  if (!props.subsPlans) {
    return <Loading />;
  }

  if (props.subsPlans && !subList) {
    setSubList(
      getSubsPlans(
        selectedPeriod,
        props.subsPlans,
        userKind,
        getSectionType(userType)
      )
    );
    return <Loading />;
  }

  const handleBillingSwitch = () => {
    setInFull(!inFull);
  };

  return (
    <div className="subscriptions-container">
      <>
        {currentPeriod ? (
          <div className="your-plan">
            <TitleHeading title={"Current Plan:"} />
            <Grid className="plan-details">
              <div>
                {getPlan(userKind, subscriptionType) ? (
                  <div className="details">
                    <span>Plan</span>
                    <TitleHeading title={getPlan(userKind, subscriptionType)} />
                  </div>
                ) : null}

                {getSubsPeriodMonths(subscriptionPeriod) ? (
                  <div className="details">
                    <span>Contract Length:</span>
                    <TitleHeading
                      title={
                        getSubsPeriodMonths(subscriptionPeriod) + " Months"
                      }
                    />
                  </div>
                ) : null}

                <div className="details">
                  <span>Billing:</span>
                  <div className="billing-details">
                    <TitleHeading title={"Monthly"} />

                    <div>
                      <FormControlLabel
                        className="billing-switch"
                        control={
                          <Switch
                            checked={inFull}
                            onChange={handleBillingSwitch}
                            name="BillingSwitch"
                          />
                        }
                        label="In Full"
                      />
                    </div>
                  </div>
                  {props.subsPlans[currentPeriod][
                    getSubsType(currentSubs, userKind)
                  ].monthly_price > 0 && props.subsPlans ? (
                    props.subsPlans[currentPeriod][
                      getSubsType(currentSubs, userKind)
                    ].discount > 0 ? (
                      inFull ? (
                        <span className="in-full-discount">
                          You saved{" "}
                          {
                            props.subsPlans[currentPeriod][
                              getSubsType(currentSubs, userKind)
                            ].discount
                          }
                          % over the whole contract
                        </span>
                      ) : (
                        <span className="in-full-discount">
                          Switch to pay in Full and save{" "}
                          {
                            props.subsPlans[currentPeriod][
                              getSubsType(currentSubs, userKind)
                            ].discount
                          }
                          % over the whole contract
                        </span>
                      )
                    ) : null
                  ) : null}
                </div>

                {nextBill &&
                props.subsPlans[currentPeriod][
                  getSubsType(currentSubs, userKind)
                ].monthly_price > 0 ? (
                  <div className="next-bill">
                    <div>
                      <TitleHeading title={"Next Bill:"} />
                      <span>Next Payment Date: {nextBill}</span>
                    </div>
                    {props.subsPlans ? (
                      inFull ? (
                        <div className="price-subs">
                          ${" "}
                          <span>
                            {props.subsPlans[currentPeriod][
                              getSubsType(currentSubs, userKind)
                            ].price * getSubsPeriodMonths(currentPeriod)}
                          </span>{" "}
                          USD
                        </div>
                      ) : (
                        <div className="price-subs">
                          ${" "}
                          <span>
                            {
                              props.subsPlans[currentPeriod][
                                getSubsType(currentSubs, userKind)
                              ].monthly_price
                            }
                          </span>{" "}
                          USD
                        </div>
                      )
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Grid>
          </div>
        ) : null}
      </>

      {/* {
                (!currentSubs && (currentSubs != 0)) ?
                    <div className="try-pro">
                        <div className="title">
                            Try Basic, Growth or Pro Plan free for 14 days - Cancel anytime
                    </div>
                        <div className="sub-title">
                            Enjoy all the benefits of Basic, Growth or Pro plan for 14-days, risk-free. You will not be charged until your trial period comes to an end. You can either change your subscription or cancel it anytime.
                    </div>
                    </div>
                    :
                    <>
                        {
                            (isFreeBuyer && isFreeTrial) ?
                                <div className="try-basic">
                                    <div className="title">
                                        Try Basic Plan free for 14 days - Cancel anytime
                            </div>
                                    <div className="sub-title">
                                        Enjoy all the benefits of our basic plan for 14-days, risk-free. You will not be charged until your trial period comes to an end. You can either change your subscription or cancel it anytime.
                            </div>
                                </div> : null
                        }
                    </>
            } */}
      {(!currentSubs && currentSubs != 0) || isFreeTrial ? null : (
        <TitleHeading
          className="change-plan"
          title={"Change Plan:"}
          subtitle={
            "Changes in billing will take effect at the end of the current billing period"
          }
        />
      )}

      <div className="period-selection">
        {props.radioLabel ? (
          <div className="radio-label">Contract Length:</div>
        ) : (
          <div className="radio-label">Contract:</div>
        )}
        <RadioGroup
          radioList={radioList}
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className={"selected-period"}
        />
      </div>

      <ExpansionPanel
        className="discount-section"
        expanded={expanded}
        onChange={(e, value) => setExpanded(value)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="panel-headings">Have a Discount code?</div>
          <div className="discount-status">{discountStatus}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            className="discount-code"
            container
            direction="row"
            alignItems="center"
          >
            <TextField
              id="discount"
              name="discount"
              placeholder="Discount Code"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <ButtonInverted onClick={handleApplyCode}>
              Apply Code
            </ButtonInverted>
          </Grid>
          <div className="discount-conditions">
            *Discount only applicable to the first instalment of your
            subscriptions. See{" "}
            <a href="https://www.abana.mu/terms-conditions/" target="_blank">
              Terms and Conditions
            </a>{" "}
            for more information.
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <div className="subscription-table-wrapper">
        <div className="subscription-table">
          <SectionButtons
            top={true}
            onSelect={handleOnSelect}
            buttonName={props.buttonName}
            currentSubs={currentSubs}
            isAdmin={props.isAdmin}
            subList={subList}
            currentPeriod={currentPeriod}
            selectedPeriod={selectedPeriod}
            isFreeTrial={isFreeTrial}
            userKind={userKind}
          />
          {subscriptionList.map((list, index) => (
            <SectionInfo list={list} key={index} userType={userType} />
          ))}
          <SectionButtons
            onSelect={handleOnSelect}
            disabled={isRequest}
            buttonName={props.buttonName}
            currentSubs={currentSubs}
            isAdmin={props.isAdmin}
            subList={subList}
            currentPeriod={currentPeriod}
            selectedPeriod={selectedPeriod}
            isFreeTrial={isFreeTrial}
            userKind={userKind}
          />
        </div>
        <p className="billed-para">
          Billed once: You will be charged today for the full amount of your
          contract
        </p>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    subsPlans: commonSelectors.getSubsPlans(state),
  }),
  {
    setSubsPlans: commonActions.setSubsPlans,
  }
)(SubscriptionPlan);
