import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import './MobileStepperComponent.scss';

function MobileStepperComponent(props) {
    return (
        <MobileStepper {...props} />
    );
}

export default MobileStepperComponent;