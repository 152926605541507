import React, { useState } from 'react';
import { 
    TitleHeading, GroupCheckboxColumn, TextFieldOther 
} from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './UniqueSellingPoints.scss';

function Form(props) {
    const {
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched,
        checkboxNaming,
        checkboxValues,
        tagsList,
        inputName
    } = props;

    const inputChange = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.map((item) => 
                    <GroupCheckboxColumn 
                        naming={checkboxNaming}
                        title={item.name}
                        name={item.code}
                        checkboxList={item.children}
                        key={item.code}
                        values={checkboxValues[item.code]}
                        onChange={props.onCheckboxChange}
                    />
                )
            }
            <TextFieldOther 
                className="text-field-other-override"
                id={inputName}
                name={inputName}
                value={props.values[inputName]}
                helperText={touched[inputName] ? errors[inputName] : ''}
                error={touched[inputName] && Boolean(errors[inputName])}
                onChange={inputChange.bind(null, inputName)}
            />  
        </form>
    );
}

function UniqueSellingPoints(props){
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    const inputName = 'uspOther';
    let inputOtherInit = {
        [inputName]: (dataList['other'] || '')
    };
    let yupObject = {
        // [inputName]: Yup.string('')
        //     .max(20, 'Maximum number of characters is 20')
    };

    const [checkboxValues, setCheckboxValues] = useState(dataList);
    const validationSchema = Yup.object(yupObject);

    const handleCheckboxChange = (name, value) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name][value.name] = value.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const handleFormSubmit = (values) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues['other'] = values[inputName];
        props.onSubmit(newCheckboxValues);
    };

    if(tagsList.length === 0 || !checkboxValues) { 
        return null;
    }

    return (
        <div className="usp-container">
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                        inputName={inputName}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={inputOtherInit}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
        </div>
    );
}

export default UniqueSellingPoints;