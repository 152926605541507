import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import '../../../variables.scss';

const useStyles = makeStyles({
    checkbox: {
        color: '#FF6158;'
    }
})

function CheckboxComponent(props) {
    const classes = useStyles();

    return (
        <Checkbox
            {...props}
            className={classes.checkbox}
            defaultChecked color="#FF6158;"
        />

    );
}

export default CheckboxComponent;
