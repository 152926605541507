import React from 'react';
import { Link } from "react-router-dom";
import './TermsAndConditionsComponent.scss';

function TermsAndConditionsComponent(props) {
    return (
        <div className="terms-and-conditions-container">
            <span className="terms-and-conditions">
                By creating an account you agree with our
                <a href="https://www.abana.mu/terms-conditions" target="_blank"> Terms & Conditions</a>, and our
                <a href="https://www.abana.mu/privacy" target="_blank"> Privacy Policy</a>.
            </span>
        </div>
    );
}

export default TermsAndConditionsComponent;
