import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Grid, InputAdornment } from "@material-ui/core";
import { 
    TextField, TitleHeading, UploadFile, ButtonInverted, BorderedContent, UploadValidator 
} from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './YourFactory.scss'

function Form(props){
    const{
        values:{
            revenue,
            monthlyCapacity,
            employeeFull,
            employeeSub,
            perStyle,
            perColour,
            fromRawMaterials,
            fromOrder,
        },
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched,
        supplierType,
        getErrors
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    if (errors) {
        getErrors(errors);
    }

    return(
        <form onSubmit={handleSubmit} id="factory-form">
            <Grid container direction="column">
                <Grid container direction="row" className='form-container'>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            id="revenue"
                            name="revenue"
                            labelname="Yearly Revenue in USD"
                            value={revenue}
                            helperText={touched.revenue ? errors.revenue : ''}
                            error={touched.revenue && Boolean(errors.revenue)}
                            onChange={change.bind(null, 'revenue')}
                            placeholder="Yearly Revenue in US$ Dollars"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {
                        supplierType === 'SG' ?
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="monthlyCapacity"
                                name="monthlyCapacity"
                                labelname="Monthly Capacity (Pieces/Month)"
                                value={monthlyCapacity}
                                helperText={touched.monthlyCapacity ? errors.monthlyCapacity : ''}
                                error={touched.monthlyCapacity && Boolean(errors.monthlyCapacity)}
                                onChange={change.bind(null, 'monthlyCapacity')}
                                placeholder="Pieces"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">pieces</InputAdornment>,
                                }}
                            />
                        </Grid> : null
                    }                  
                </Grid>
                <Grid container direction="row"  className="cb-title">
                    <Grid item xs={12} sm={4}>
                        Employees
                    </Grid>
                </Grid>

                <Grid container direction="row" className='form-container'  spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="employeeFull"
                            name="employeeFull"
                            labelname="Number of Full-Time"
                            value={employeeFull}
                            helperText={touched.employeeFull ? errors.employeeFull : ''}
                            error={touched.employeeFull && Boolean(errors.employeeFull)}
                            onChange={change.bind(null, 'employeeFull')}
                            placeholder="Number"
                        />
                    </Grid>
                    {
                        supplierType === 'SG' ?
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="employeeSub"
                                name="employeeSub"
                                labelname="Number of Subcontractors"
                                value={employeeSub}
                                helperText={touched.employeeSub ? errors.employeeSub : ''}
                                error={touched.employeeSub && Boolean(errors.employeeSub)}
                                onChange={change.bind(null, 'employeeSub')}
                                placeholder="Number"
                            />
                        </Grid> : null
                    }     
                </Grid>
                {
                    supplierType === 'SG' ?
                    <>
                        <Grid container direction="row" className="cb-title" >
                            <Grid item xs={12} sm={6}>
                                MOQ
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className='form-container' spacing={2} >
                            <Grid item xs={12}md={6}>
                                <TextField 
                                    id="perStyle"
                                    name="perStyle"
                                    labelname="Per Style" 
                                    value={perStyle}
                                    helperText={touched.perStyle ? errors.perStyle : ''}
                                    error={touched.perStyle && Boolean(errors.perStyle)}
                                    onChange={change.bind(null, 'perStyle')}
                                    placeholder="Pieces"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="perColour"
                                    name="perColour"
                                    labelname="Per Colour" 
                                    value={perColour}
                                    helperText={touched.perColour ? errors.perColour : ''}
                                    error={touched.perColour && Boolean(errors.perColour)}
                                    onChange={change.bind(null, 'perColour')}
                                    placeholder="Pieces"
                                />
                            </Grid>
                        </Grid>
                    </> : null
                }   
                <Grid container direction="row" className="cb-title">
                    <Grid item xs={12} sm={6}>
                        Lead Time
                    </Grid>
                </Grid>
                <Grid container direction="row" className='form-container' spacing={2} >
                    {
                        supplierType === 'SG' ?
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="fromRawMaterials"
                                name="fromRawMaterials"
                                labelname="From Raw Material receipt"
                                value={fromRawMaterials}
                                helperText={touched.fromRawMaterials ? errors.fromRawMaterials : ''}
                                error={touched.fromRawMaterials && Boolean(errors.fromRawMaterials)}
                                onChange={change.bind(null, 'fromRawMaterials')}
                                placeholder="Number of days"
                            />
                        </Grid> : null
                    }    
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="fromOrder"
                            name="fromOrder"
                            labelname="From Order"
                            value={fromOrder}
                            helperText={touched.fromOrder ? errors.fromOrder : ''}
                            error={touched.fromOrder && Boolean(errors.fromOrder)}
                            onChange={change.bind(null, 'fromOrder')}
                            placeholder="Number of days"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

function YourFactory(props){
    const initialValuesSG = {
        revenue: '',
        monthlyCapacity: '',
        employeeFull: '',
        employeeSub: '',
        perStyle:'',
        perColour:'',
        fromRawMaterials:'',
        fromOrder:'',
    };
    const initialValuesSI = {
        revenue: '',
        employeeFull: '',
        fromOrder:'',
    };
    const integerError = 'A whole number is required';
    const numberError = 'A positive number is required';
    const [formValues, setFormValues] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showFileError, setShowFileError] = useState(false);
    const supplierType = props.supplierType;

    if(!formValues) {
        const dataList = props.dataList;
        let initialValues = {};

        switch(supplierType) {
            case 'SG':
                initialValues = initialValuesSG;
                break;
            case 'SI':
                initialValues = initialValuesSI;
                break;
        }
        if(dataList) {
            for(let property in initialValues) {
                initialValues[property] = dataList[property] ? dataList[property] : '';
            }

            if(dataList['attached_file']) {
                setUploadedFile(dataList['attached_file']);
            }
        } 
        setFormValues(initialValues);
    }

    const yupObjectSG = {
        revenue: Yup.number('')
            .min(0, numberError)
            .typeError(numberError),
        monthlyCapacity:Yup.number('')
            .required('Monthly Capacity is required')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        employeeFull:Yup.number('')
            .required('Please state no. of full-time employees')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        employeeSub:Yup.number('')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        perStyle: Yup.number('')
            .required('Please state quantity per style')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError), 
        perColour: Yup.number('')
            .required('Please state quantity per colour')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        fromRawMaterials: Yup.number('')
            .required('Number of days for Raw Material is required')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        fromOrder: Yup.number('')
            .required('Number of days for order is required')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
     
    };
    const yupObjectSI = {
        revenue: Yup.number('')
            .min(0, numberError)
            .typeError(numberError),
        employeeFull:Yup.number('')
            .required('Please state no. of full-time employees')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
        fromOrder: Yup.number('')
            .required('Number of days for order is required')
            .integer(integerError)
            .min(0, numberError)
            .typeError(numberError),
     
    };

    let validationSchema = Yup.object(yupObjectSG);

    if(supplierType === 'SI') {
        validationSchema = Yup.object(yupObjectSI);
    }

    const handleUploadFile = (uploadedFile) => {
        setUploadedFile(uploadedFile);
    };

    const handleDeleteFile = () => {
        setUploadedFile(null);
    };

    const handleFileError = (value) => {
        setShowFileError(value);
    };

    const handleFormSubmit = (values, actions) => {
        let yourFactoryData = cloneDeep(values);
        yourFactoryData['attached_file'] = uploadedFile;
        props.onSubmit(yourFactoryData);
    };

    const getErrors = (errors) => {
        props.getErrors(errors);
    }

    return(
        <div className="factory-container">
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
           <Formik children={props =>
                <Form
                    {...props}
                    supplierType={supplierType}
                    getErrors={getErrors}
                />
            }
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}        
            />  
            <Grid
                container
                alignItems="center"
            >
                {
                    uploadedFile ?
                    <BorderedContent 
                        text={uploadedFile['name']}
                    /> : null
                }
                <Grid container item className="button-container">
                    <ButtonInverted
                        onClick={handleDeleteFile}
                    >
                        Delete
                    </ButtonInverted>
                    <UploadFile  
                        onUploadImage={handleUploadFile}
                        onFileError={handleFileError}
                        buttonText="Upload File"
                    />
                    {
                        !uploadedFile ?
                        <UploadValidator 
                            size={'5MB'}
                            types={'PNG, JPEG, PDF, XLS, XLSX, DOC, DOCX'}
                            showError={showFileError}
                        /> : null
                    } 
                </Grid>
            </Grid>   
        </div>
    )
}

export default YourFactory;
