import React from 'react';
import { Grid, Card, ButtonBase } from '@material-ui/core';
import { Chip } from '../../index';
import './SideImage.scss';

function SideImage(props) {
    const templateData = props.templateData;

    if(!templateData) {
        return null;
    }

    return (
        <div className={"side-image-container" + (props.right ? ' image-right' : '')}>
            <Card>  
                <ButtonBase onClick={() => props.onClick(templateData)}>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                    >
                        {
                            templateData.image ?
                            <Grid
                                className="left-section"
                                item
                                xs={12}
                                sm
                            >
                                <img src={templateData.image} alt="abana" />
                            </Grid> : null
                        }  
                        <Grid
                            className="right-section"
                            item
                            xs={12}
                            sm
                        >
                            <div className="side-image-title">{templateData.title}</div>
                            <div className="side-image-desc">{templateData.description}</div>
                            {
                                templateData.buttonText ?
                                <Chip                      
                                    label={templateData.buttonText}
                                /> : null
                            }   
                        </Grid>
                    </Grid>
                </ButtonBase>
            </Card>
        </div>
    );
}

export default SideImage;