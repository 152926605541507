import React, { useState } from "react";
import { Grid, IconButton, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  TitleHeading,
  Button,
  PaginationComponent,
  TagsList,
} from "../../_common/index";
import { SellerSections } from "../../_common/ProfileSections/index";
import Avatar from "@material-ui/core/Avatar";
import { getCountryNameByCode } from "../../../functions/converters";
import { getSectionType, getUserKind } from "../../../functions/compute";
import CountryRegionData from "country-region-data";
import Rating from "@material-ui/lab/Rating";
import RoomIcon from "@material-ui/icons/Room";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { findPageCount } from "../../../functions/compute";
import { setSelectedCon } from "../../../functions/mutators";
import { connect } from "react-redux";
import { selectors as authSelectors } from "../../../redux/modules/auth";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import { actions as authActions } from "../../../redux/modules/auth";
import { actions as messagesActions } from "../../../redux/modules/messages";
import { actions as resultsActions } from "../../../redux/modules/results";
import messagesService from "../../../services/messagesService";
import { errorMessages, successMessages } from "../../../constants/messages";
import { useHistory } from "react-router-dom";
import "./Results.scss";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DialogComponent from "../DialogComponent/DialogComponent";
import DialogConnectionBuyer from "../../DialogConnectionBuyer/DialogConnectionBuyer";

function Results(props) {
  let history = useHistory();

  const userKind = props.clientData.basics.kind;
  const subsLevel = props.clientData.basics.subscription;
  const total_results = props.resultsList.totalCount || null;
  const itemsPerPage = props.itemsPerPage;
  const resultsTitle = "Results";
  const pageCount = findPageCount(total_results, itemsPerPage);
  const resultsList = props.resultsList.results;
  let resultSubtitle = "0 results";
  let showResults = false;

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.errorApi);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);

  if (!props.resultsList) {
    return null;
  }

  if (total_results && resultsList && resultsList.length > 0) {
    const text = resultsList.length === 1 ? "result" : "results";
    resultSubtitle = total_results + " " + text;
    showResults = true;
  } else {
    return (
      <div className="results-container" id="results-container">
        <div className="results-heading">
          <TitleHeading title={resultsTitle} subtitle={resultSubtitle} />
        </div>

        <div className="no-results-content">
          <span>Unfortunately your Search led to no results</span>
        </div>
      </div>
    );
  }

  const getSupplierName = (profileType) => {
    let supplierInfo = "";

    if (!profileType) {
      return supplierInfo;
    }

    switch (getSectionType(profileType)) {
      case "SG":
        supplierInfo = "Apparel Manufacturer";
        break;
      case "SI":
        supplierInfo = "Yarn, Fabrics, Accessories and Equipment Supplier";
        break;
      case "SS":
        supplierInfo = "Service Provider";
        break;
      default:
        break;
    }

    return supplierInfo;
  };

  const handleAgree = (profileUser = null) => {
    const profileData = profileUser || currentProfile || null;
    if (profileData?.profile_id && profileData?.profile_type) {
      const profileKind = getUserKind(profileData?.profile_type);
      const requestData = {
        user_id: props.auth.user_id,
        user_type: props.clientData.basics.type,
        connection_id: profileData?.profile_id,
        connection_type: profileData?.profile_type,
        message,
      };

      setShowError(false);
      setShowSuccess(false);
      setIsRequest(true);

      messagesService
        .addConnection(requestData, props.auth.token)
        .then(function (response) {
          setIsRequest(false);
          setOpen(false);

          if (response && response.status === 200) {
            if (response.data.code === 403) {
              setErrorMsg(errorMessages.limitExceeded);
              setShowError(true);
              return;
            }

            if (response.data.code === 401) {
              setErrorMsg(errorMessages.connRequestExceeded);
              setShowError(true);
              return;
            }

            if (response.data.code === 402) {
              setSuccessMsg(successMessages.connectionRequestExist);
              setShowSuccess(true);
              return;
            }

            if (response.data.code === 204) {
              setSelectedCon(response.data);
              props.setShouldUpdateMsg(true);
              return;
            }

            if (profileKind === "B") {
              setSuccessMsg(successMessages.connectionRequestMade);
              setShowSuccess(true);
              return;
            }

            setSelectedCon(response.data);
            props.setShouldUpdateMsg(true);
            return;
          }

          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
        })
        .catch(function (error) {
          setIsRequest(false);
          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
        });
    }
  };

  const handleContact = (profile) => {
    if (profile.profile_id && profile.profile_type) {
      if (profile?.basics?.companyName) {
        setTitle(`Add a note to ${profile?.basics?.companyName}`);
      } else {
        setTitle(`Add a note`);
      }
      setMessage("");

      const profileUserToConnect = getUserKind(profile.profile_type);

      if (userKind === "S" && profileUserToConnect === "B") {
        setCurrentProfile(profile);
        setOpen(true);
      } else {
        handleAgree(profile);
      }
    }
  };

  const handleViewProfile = (profile) => {
    if (profile.profile_id && profile.profile_type) {
      if (props.setProfileId) {
        props.setProfileId(profile.profile_id);
      }
      history.push(
        `/auth/profile-view/${profile.profile_id}/${profile.profile_type}`
      );
    }
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  return (
    <div className="results-container" id="results-container">
      <div className="results-heading">
        <TitleHeading title={resultsTitle} subtitle={resultSubtitle} />
      </div>

      {showResults ? (
        <>
          <div className="results-content">
            {resultsList.map((item, index) => (
              <Grid
                key={index}
                className={
                  "results-section" +
                  (item.basics.top_member ? " card-feed-highlight" : "")
                }
                id={item.profile_id ? "profile_" + item.profile_id : ""}
                container
                direction="column"
              >
                {/* <div className="ribbon">
                                    <span>
                                        Top Member
                                    </span>
                                </div> */}
                <Grid container direction="row" className="top-section">
                  <Grid direction="column" container item md={7} xs={12}>
                    <Grid container className="info-container">
                      <Grid
                        item
                        md={5}
                        xs={12}
                        container
                        direction="row"
                        alignItems="flex-start"
                      >
                        {item.basics && item.basics.logo ? (
                          <Avatar alt="Profile" src={item.basics.logo.path} />
                        ) : (
                          <Avatar>
                            {item.basics.companyName
                              ? item.basics.companyName.charAt(0).toUpperCase()
                              : null}
                          </Avatar>
                        )}
                        <span className="title">
                          {item.basics.companyName}{" "}
                          {item.basics.verified ? <CheckCircle /> : null}
                        </span>
                      </Grid>

                      <Grid item>
                        <div className="results-title">
                          {getUserKind(item.profile_type) === "S" &&
                          item.basics.rating ? (
                            <Rating
                              name="read-only"
                              value={parseInt(item.basics.rating)}
                              readOnly
                            />
                          ) : null}
                          <div className="location">
                            <RoomIcon />
                            <span>
                              {getCountryNameByCode(
                                CountryRegionData,
                                item.basics.country
                              ) || null}
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item className="description-container">
                      <p className="description">{item.basics.description}</p>
                    </Grid>
                  </Grid>

                  <Grid item md={5} xs={12} container justify="flex-end">
                    <span className="supplier-type">
                      {getSupplierName(item.profile_type)}
                    </span>
                  </Grid>
                </Grid>
                {getUserKind(item.profile_type) === "S" ? (
                  <div className="profile-basics">
                    <SellerSections
                      dataList={item}
                      tagsNameList={props.tagsNameData}
                      profileType={item.profile_type}
                    />
                  </div>
                ) : null}
                {getUserKind(item.profile_type) === "B" ? (
                  <TagsList title="Tags:" list={item.tags} name="name" />
                ) : null}

                <Grid
                  item
                  container
                  justify="flex-end"
                  className="button-container"
                >
                  {item.profile_type === "BF" ||
                  (userKind === "S" && subsLevel == 0) ? null : (
                    <Grid item className="icon-button">
                      <IconButton
                        className="button-message"
                        onClick={() => handleContact(item)}
                      >
                        <MailOutlineIcon />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item>
                    <Button onClick={() => handleViewProfile(item)}>
                      Full Profile
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </div>
          <PaginationComponent
            count={pageCount}
            page={props.page}
            onChange={props.onChange}
          />
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <Alert
              elevation={0}
              variant="filled"
              onClose={handleErrorClose}
              severity="error"
              children={errorMsg}
            />
          </Snackbar>
          <Snackbar
            open={showSuccess}
            autoHideDuration={15000}
            onClose={handleSuccessClose}
          >
            <Alert
              elevation={0}
              variant="filled"
              onClose={handleSuccessClose}
              severity="success"
              children={successMsg}
            />
          </Snackbar>
          <DialogComponent
            registerClassName="dialogRequest"
            open={open}
            title={title}
            onClose={() => setOpen(false)}
            content={
              <DialogConnectionBuyer
                setMessage={setMessage}
                message={message}
              />
            }
            isRequest={isRequest}
            buttonAgree="Send"
            section=""
            buttonCancel="Cancel"
            onAgree={handleAgree}
          />
        </>
      ) : null}
    </div>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
  }),
  {
    setAuth: authActions.setAuth,
    setShouldUpdateMsg: messagesActions.setShouldUpdateMsg,
    setProfileId: resultsActions.setProfileId,
  }
)(Results);
