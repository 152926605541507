const axios = require("axios");
const API_URL = process.env.REACT_APP_API_URL;

const profileService = {
  getProfileBasics: function (user_id, token, cancelToken) {
    const url = `${API_URL}/get-profile-basics?user_id=${user_id}`;

    return axios({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    });
  },
  addReportCount: function (data, token) {
    const url = `${API_URL}/view-profile-report`;

    return axios({
      method: "POST",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getProfileView: function (
    user_id,
    user_type,
    profile_id,
    profile_type,
    token,
    cancelToken
  ) {
    const url = `${API_URL}/get-profile-view?user_id=${user_id}&user_type=${user_type}&profile_id=${profile_id}&profile_type=${profile_type}`;

    return axios({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    });
  },
  getProfileComplete: function (user_id, user_type, token, cancelToken) {
    const url = `${API_URL}/get-profile-complete?user_id=${user_id}&user_type=${user_type}`;

    return axios({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    });
  },
  editProfile: function (data, token) {
    const url = `${API_URL}/edit-profile`;

    return axios({
      method: "PUT",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getCatalogueView: function (
    user_id,
    user_type,
    profile_id,
    profile_type,
    token,
    cancelToken
  ) {
    const url = `${API_URL}/profile-catalogue?user_id=${user_id}&user_type=${user_type}&profile_id=${profile_id}&profile_type=${profile_type}`;

    return axios({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    });
  },
  getCatalogue: function (user_id, user_type, token, cancelToken) {
    const url = `${API_URL}/get-product?user_id=${user_id}&user_type=${user_type}`;

    return axios({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    });
  },
  createProduct: function (data, token) {
    const url = `${API_URL}/add-product`;

    return axios({
      method: "POST",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  editProduct: function (data, token) {
    const url = `${API_URL}/edit-product`;

    return axios({
      method: "PUT",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  deleteProduct: function (data, token) {
    const url = `${API_URL}/delete-product`;

    return axios({
      method: "DELETE",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  editBanner: function (data, token) {
    const url = `${API_URL}/edit-banner`;

    return axios({
      method: "PUT",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  bookmark: function (data, token) {
    const url = `${API_URL}/bookmark`;

    return axios({
      method: "PUT",
      url: url,
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
};

export default profileService;
