import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Filter,
  ButtonOutlined,
  CardNews,
  CardFeed,
  Loading,
  ErrorMessage,
  PaginationComponent,
  ModalDisplay,
  DialogComponent,
} from "../_common/index";
import { Grid, Snackbar, Switch, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import { selectors as authSelectors } from "../../redux/modules/auth";
import { selectors as profileSelectors } from "../../redux/modules/profile";
import { selectors as feedSelectors } from "../../redux/modules/feed";
import { selectors as commonSelectors } from "../../redux/modules/common";
import { actions as commonActions } from "../../redux/modules/common";
import { actions as feedActions } from "../../redux/modules/feed";
import { actions as accountActions } from "../../redux/modules/account";
import { actions as messagesActions } from "../../redux/modules/messages";
import { actions as dropsActions } from "../../redux/modules/drops";
import { actions as profileActions } from "../../redux/modules/profile";
import { errorMessages, successMessages } from "../../constants/messages";
import {
  getUserPreferences,
  setUserPreferences,
} from "../../functions/mutators";
import feedService from "../../services/feedService";
import messagesService from "../../services/messagesService";
import profileService from "../../services/profileService";
import { getUserKind } from "../../functions/compute";
import { setSelectedCon } from "../../functions/mutators";
import cloneDeep from "lodash/cloneDeep";
import "./HomeFeed.scss";

import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import commonService from "../../services/commonService";
import DialogConnectionBuyer from "../DialogConnectionBuyer/DialogConnectionBuyer";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function HomeFeed(props) {
  const filtersListBuyer = [
    {
      code: "section1",
      name: "Section1",
      children: [
        {
          code: "all",
          name: "All",
        },
        {
          code: "drops",
          name: "Drops",
        },
        {
          code: "listing",
          name: "Listings",
        },
        {
          code: "supplier",
          name: "New Suppliers",
        },
        {
          code: "report",
          name: "Reports",
        },
      ],
    },
  ];

  const filtersListSeller = [
    {
      code: "section1",
      name: "Section1",
      children: [
        {
          code: "all",
          name: "All",
        },
        {
          code: "rfq",
          name: "RFQ",
        },
        {
          code: "rfi",
          name: "RFI",
        },
        {
          code: "listing",
          name: "Listings",
        },
        {
          code: "report",
          name: "Reports",
        },
      ],
    },
  ];

  let buttonTexts = {
    listing: "Contact Supplier",
    rfi: "Contact Buyer",
    rfq: "See RFQ",
    drop: "See Drop Product",
    report: "See Report",
    supplier: "See Profile",
    customer: "See Profile",
    drops: "See Drop",
  };

  const contactedButtonTexts = {
    listing: "Supplier Contacted",
    rfi: "Buyer Contacted",
  };

  const userKind = props.clientData.basics.kind;
  const userType = props.clientData.basics.type;
  const subsLevel = props.clientData.basics.subscription;
  const filtersList = userKind === "B" ? filtersListBuyer : filtersListSeller;
  const itemsPerPage = 15;
  const matchTagsValue = getUserPreferences()
    ? getUserPreferences().feed_match_tags || false
    : false;
  const showTagsArray = ["all", "listing", "rfi", "rfq"];
  let selectedFeed = null;

  // if (subsLevel === 1 && userKind === 'S') {
  //     buttonTexts = {
  //         ...buttonTexts,
  //         rfq: 'Upgrade to Respond'
  //     };
  // }

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.errorApi);
  const [isRequest, setIsRequest] = useState({});
  const [pageChanged, setPageChanged] = useState(false);
  const [newsData, setNewsData] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  //const [filter, setFilter] = useState('all');
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [viewFeed, setViewFeed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [matchTags, setMatchTags] = useState(matchTagsValue);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [currentFeed, setCurrentFeed] = useState(null);
  const [isConnectionRequest, setIsConnectionRequest] = useState(false);

  let showMatchTags = false;
  let showNoTags = false;

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    setLoading(true);

    if (!props.feedData) {
      feedService
        .getFeeds(
          props.auth.user_id,
          props.clientData.basics.type,
          props.selectedPage,
          itemsPerPage,
          props.selectedFilter,
          matchTags,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200 && response.data) {
            const responseData = response.data;
            props.setFeedData(responseData);
            setPageCount(responseData.pageCount);
            setPageChanged(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } else {
      setPageCount(props.feedData?.pageCount || 1);
    }

    if (!newsData) {
      // setTimeout(() => {
      //     const responseData = dummyFeedNews;
      //     setNewsData(responseData);
      // }, 3000);

      feedService
        .getFeedNews(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200 && response.data) {
            setNewsData(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    if (!props.taxonsData) {
      commonService
        .getTaxons(cancelToken)
        .then(function (response) {
          if (response && response.data.length > 0) {
            props.setTaxonsData(response.data);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    }

    props.setPreviousLocationData("feed");

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [props.feedData]);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.profileData) {
      profileService
        .getProfileComplete(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response.status === 200 && response.data) {
            props.setProfileData(response.data);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, [matchTags]);

  useEffect(() => {
    const appendNewFeed = () => {
      const newFeedData = [props.newFeedData, ...props.feedData.feed];
      props.setFeedData({ ...props.feedData, feed: newFeedData });
      // props.setNewFeedData(null);
      return;
    };

    if (props.newFeedData) {
      if (props.selectedFilter === "all") {
        appendNewFeed();
      } else if (props.selectedFilter === props.newFeedData?.type) {
        appendNewFeed();
      }
    }
  }, [props.newFeedData]);

  if (props.feedData) {
    if (loading) {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (!loading && !props.feedData) {
    return (
      <div className="request-failed home-container">
        <ErrorMessage message={errorMessages.requestFailed} showError={true} />
      </div>
    );
  }

  const cardDataList = props.feedData.feed;

  if (selectedIndex || selectedIndex === 0) {
    selectedFeed = cardDataList[selectedIndex];
  }

  const handleQuickLink = (section) => {
    switch (section) {
      case "rfi":
        props.history.push("/auth/requests/RFI/create");
        break;
      case "rfq":
        props.history.push("/auth/requests/quotes/create");
        break;
      case "listing":
        props.history.push("/auth/listings/create");
        break;
      default:
        break;
    }
  };

  const handlePageChange = (event, value) => {
    setPageChanged(true);
    props.setSelectedPage(value);
    props.setFeedData(null);
    setLoading(true);
  };

  const handleFilterSelected = (chip) => {
    const noTags = ["drops", "supplier", "report"];

    if (chip && chip.code !== props.selectedFilter) {
      props.setSelectedFilter(chip.code);
      props.setSelectedPage(1);
      props.setFeedData(null);
      setLoading(true);
      setMatchTags(matchTagsValue);

      noTags.map((item) => {
        if (item === chip.code) {
          setMatchTags(false);
        }
      });
    }
  };

  const handleBookmarkChange = (feed, checked) => {
    const requestData = {
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
      feed_id: feed.id,
      feed_type: feed.type,
    };

    let newFeedData = cloneDeep(props.feedData);
    newFeedData.feed.forEach((item, index) => {
      if (item.id === feed.id && item.type === feed.type) {
        newFeedData.feed[index].bookmark = checked;
      }
    });

    setShowSuccess(false);
    setShowError(false);

    feedService
      .bookmark(requestData, props.auth.token)
      .then(function (response) {
        if (response && response.status === 204) {
          props.setFeedData(newFeedData);
          if (checked) {
            setSuccessMsg("Item succcessfully bookmarked!");
            setShowSuccess(true);
          }

          return;
        }

        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
      })
      .catch(function (error) {
        console.error(error);
        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
      });
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  const handleViewFeed = (feed, index) => {
    if (userType === "BF") {
      if (feed.type === "listing" || feed.type === "supplier") {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    if (userKind === "S" && subsLevel == 0) {
      if (
        feed.type === "listing" ||
        feed.type === "rfq" ||
        feed.type === "rfi"
      ) {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    if (userKind === "S" && subsLevel == 1) {
      if (feed.type === "rfq") {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    switch (feed.type) {
      case "rfq":
        if (userKind === "S") {
          props.history.push(`/auth/listings/quotes/response/${feed.id}`);
        }
        break;
      default:
        setSelectedIndex(index);
        setViewFeed(true);
    }
  };

  const handleAgree = (feedData = null) => {
    const feed = feedData || currentFeed || null;

    if (feed.id && feed.type) {
      const uniqueKey = feed.type + feed.id;
      let requestData;

      let newFeedData = cloneDeep(props.feedData);
      newFeedData.feed.forEach((item, index) => {
        if (item.id === feed.id && item.type === feed.type) {
          newFeedData.feed[index].contact = true;
        }
      });

      const requestDataContact = {
        user_id: props.auth.user_id,
        user_type: props.clientData.basics.type,
        feed_id: feed.id,
        feed_type: feed.type,
      };

      if (feed.profile_id && feed.profile_type) {
        const profileKind = getUserKind(feed.profile_type);
        requestData = {
          user_id: props.auth.user_id,
          user_type: props.clientData.basics.type,
          connection_id: feed.profile_id,
          connection_type: feed.profile_type,
          message,
        };

        setShowError(false);
        setShowSuccess(false);
        setIsRequest({
          ...isRequest,
          [uniqueKey]: true,
        });
        setIsConnectionRequest(true);

        feedService
          .trackContact(requestDataContact, props.auth.token)
          .then(function (response) {
            if (response && response.status === 204) {
              messagesService
                .addConnection(requestData, props.auth.token)
                .then(function (response) {
                  props.setFeedData(newFeedData);
                  setIsRequest({
                    ...isRequest,
                    [uniqueKey]: false,
                  });
                  setIsConnectionRequest(false);
                  setOpen(false);

                  if (response && response.status === 200) {
                    if (response.data.code === 403) {
                      setErrorMsg(errorMessages.limitExceeded);
                      setShowError(true);
                      return;
                    }

                    if (response.data.code === 401) {
                      setErrorMsg(errorMessages.connRequestExceeded);
                      setShowError(true);
                      return;
                    }

                    if (response.data.code === 402) {
                      setSuccessMsg(successMessages.connectionRequestExist);
                      setShowSuccess(true);
                      return;
                    }

                    if (response.data.code === 204) {
                      setSelectedCon(response.data);
                      props.setShouldUpdateMsg(true);
                      return;
                    }

                    if (profileKind === "B") {
                      setSuccessMsg(successMessages.connectionRequestMade);
                      setShowSuccess(true);
                      return;
                    }

                    setSelectedCon(response.data);
                    props.setShouldUpdateMsg(true);
                    return;
                  }

                  setErrorMsg(errorMessages.errorApi);
                  setShowError(true);
                })
                .catch(function (error) {
                  setIsRequest({
                    ...isRequest,
                    [uniqueKey]: false,
                  });
                  setIsConnectionRequest(false);
                  setErrorMsg(errorMessages.errorApi);
                  setShowError(true);
                });
            }
          })
          .catch(function (error) {
            setIsConnectionRequest(false);
            setErrorMsg(errorMessages.errorApi);
            setShowError(true);
          });
      }
    }
  };

  const handleBtnClicked = (feed, index) => {
    if (userType === "BF") {
      if (feed.type === "listing" || feed.type === "supplier") {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    if (userKind === "S" && subsLevel == 0) {
      if (
        feed.type === "listing" ||
        feed.type === "rfq" ||
        feed.type === "rfi"
      ) {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    if (userKind === "S" && subsLevel == 1) {
      if (feed.type === "rfq") {
        props.setTabValue(1);
        props.history.push(`/auth/account-settings`);
        return null;
      }
    }

    switch (feed.type) {
      case "listing":
      case "rfi":
        let requestData;
        const uniqueKey = feed.type + feed.id;

        let newFeedData = cloneDeep(props.feedData);
        newFeedData.feed.forEach((item, index) => {
          if (item.id === feed.id && item.type === feed.type) {
            newFeedData.feed[index].contact = true;
          }
        });

        const requestDataContact = {
          user_id: props.auth.user_id,
          user_type: props.clientData.basics.type,
          feed_id: feed.id,
          feed_type: feed.type,
        };
        if (feed.anonymous) {
          const idName = feed.type === "listing" ? "listing_id" : "rfi_id";
          requestData = {
            user_id: props.auth.user_id,
            user_type: props.clientData.basics.type,
            [idName]: feed.id,
          };

          setIsRequest({
            ...isRequest,
            [uniqueKey]: true,
          });

          feedService
            .trackContact(requestDataContact, props.auth.token)
            .then(function (response) {
              messagesService
                .contactAnonymous(requestData, props.auth.token)
                .then(function (response) {
                  props.setFeedData(newFeedData);
                  setIsRequest({
                    ...isRequest,
                    [uniqueKey]: false,
                  });

                  setOpen(false);

                  if (response && response.status === 204) {
                    setSuccessMsg(successMessages.contactAnonymous);
                    setShowSuccess(true);
                    return;
                  }

                  setErrorMsg(errorMessages.errorApi);
                  setShowError(true);
                })
                .catch(function (error) {
                  setIsRequest({
                    ...isRequest,
                    [uniqueKey]: false,
                  });

                  setErrorMsg(errorMessages.errorApi);
                  setShowError(true);
                });
            })
            .catch(function (error) {
              setIsRequest({
                ...isRequest,
                [uniqueKey]: false,
              });

              setErrorMsg(errorMessages.errorApi);
              setShowError(true);
            });

          return;
        }

        if (feed.profile_id && feed.profile_type) {
          const companyName = feed?.companyName || "";

          if (companyName) {
            setTitle(`Add a note to ${companyName}`);
          } else {
            setTitle(`Add a note`);
          }
          setMessage("");

          const profileUserToConnect = getUserKind(feed.profile_type);
          if (userKind === "S" && profileUserToConnect === "B") {
            setCurrentFeed(feed);
            setOpen(true);
          } else {
            handleAgree(feed);
          }
          return;
        }

        break;
      case "rfq":
        if (userKind === "S") {
          props.history.push(`/auth/listings/quotes/response/${feed.id}`);
        }
        break;
      case "drops":
        if (feed.kind === "dropsCategory") {
          props.setDropCollectionData(null);
          props.history.push(`/auth/drops/collection/all/${feed.code}/all`);
        } else if (feed.kind === "dropsItem") {
          props.setDropCollectionData(null);
          props.history.push(
            `/auth/drops/view/${feed.segment.code}/${feed.category.code}/${feed.product_id}`
          );
        }
        break;
      case "report":
        //Download attached file if any
        if (feed.attachment) {
          const attachedFile = feed.attachment;
          const filePath = attachedFile["path"];

          if (filePath) {
            let fileAnchor = document.createElement("a");
            fileAnchor.href = filePath;
            fileAnchor.download = attachedFile["name"];
            fileAnchor.target = "_blank";
            fileAnchor.click();
          }
        }
        break;
      case "supplier":
        if (feed.profile_id && feed.profile_type) {
          props.history.push(
            `/auth/profile-view/${feed.profile_id}/${feed.profile_type}`
          );
        }
        break;
      case "customer":
        if (feed.profile_id && feed.profile_type) {
          props.history.push(
            `/auth/profile-view/${feed.profile_id}/${feed.profile_type}`
          );
        }
        break;
      default:
    }
  };

  const handleModalClose = () => {
    setSelectedIndex(null);
    setViewFeed(false);
  };

  const handleViewProfile = (feed) => {
    if (feed.profile_id && feed.profile_type) {
      props.history.push(
        `/auth/profile-view/${feed.profile_id}/${feed.profile_type}`
      );
    }
  };

  const handleFilterByTags = () => {
    setUserPreferences({
      ...getUserPreferences(),
      feed_match_tags: !matchTags,
    });
    setMatchTags(!matchTags);
    props.setSelectedPage(1);
    props.setFeedData(null);
    setLoading(true);
  };

  const handleAddTags = () => {
    if (userKind === "B") {
      props.history.push(`/auth/profile/edit-buyer`);
    }

    if (userKind === "S") {
      props.history.push(`/auth/profile/edit-seller`);
    }
  };

  const setBtnTextType = (item) => {
    const { type, contact } = item;
    if (type === "listing" || type === "rfi") {
      if (contact) {
        return contactedButtonTexts[type];
      }
    }
    return buttonTexts[type];
  };

  showMatchTags = showTagsArray.some((tag) => {
    return tag == props.selectedFilter;
  });

  if (userKind === "B" && subsLevel == 0) {
    showMatchTags = false;
  }

  showNoTags =
    showMatchTags &&
    matchTags &&
    props.profileData &&
    props.profileData.tags &&
    props.profileData.tags.length < 1;

  return (
    <div className="home-container">
      <Filter
        label={"Filter:"}
        dataList={filtersList}
        selectedFilter={props.selectedFilter}
        onFilterSelected={handleFilterSelected}
      />
      <Grid className="home-contents" container justify="space-between">
        <Grid className="section-left">
          <div className="quick-links">
            {/* <span>Quick Links:</span> */}
            {userKind === "B" ? (
              <>
                <div>
                  <ButtonOutlined
                    onClick={() => handleQuickLink("rfi")}
                    startIcon={<AddIcon className="add-icon" />}
                  >
                    New RFI
                  </ButtonOutlined>
                  <ButtonOutlined
                    onClick={() => handleQuickLink("rfq")}
                    startIcon={<AddIcon className="add-icon" />}
                  >
                    New RFQ
                  </ButtonOutlined>
                </div>
              </>
            ) : (
              <ButtonOutlined
                onClick={() => handleQuickLink("listing")}
                startIcon={<AddIcon className="add-icon" />}
              >
                New Listing
              </ButtonOutlined>
            )}
            {showMatchTags ? (
              <FormControlLabel
                className="tag-switch"
                control={
                  <Switch
                    checked={matchTags}
                    onChange={handleFilterByTags}
                    name="filterByTags"
                  />
                }
                label="Match my Tags only"
              />
            ) : null}
          </div>
          <div>
            {showNoTags ? (
              <div className="no-tags-section">
                <div className="no-tags-texts">
                  There are no tags selected in your profile.
                </div>
                <ButtonOutlined onClick={() => handleAddTags()}>
                  Add Tags Now
                </ButtonOutlined>
              </div>
            ) : (
              <>
                {!cardDataList || cardDataList.length === 0 ? (
                  <div className="no-feeds">No feeds to display.</div>
                ) : (
                  <>
                    {cardDataList.map((item, index) => (
                      <CardFeed
                        key={index}
                        dataList={item}
                        btnText={setBtnTextType(item)}
                        userType={userType}
                        userKind={userKind}
                        subsLevel={subsLevel}
                        onBookmarkChange={handleBookmarkChange}
                        onClick={() => handleViewFeed(item, index)}
                        onBtnClicked={() => handleBtnClicked(item, index)}
                        isRequest={isRequest[item.type + item.id]}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid className="section-right">
          <div className="big-news">
            <CardNews dataList={newsData} title />
          </div>

          <Accordion className="news-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              What's New
            </AccordionSummary>
            <AccordionDetails>
              <CardNews dataList={newsData} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {pageCount ? (
        <Grid
          className="pagination-section"
          container
          justify="center"
          alignItems="center"
        >
          <PaginationComponent
            count={pageCount}
            page={props.selectedPage}
            onChange={handlePageChange}
          />
        </Grid>
      ) : null}
      {selectedFeed ? (
        <ModalDisplay
          open={viewFeed}
          dataList={selectedFeed}
          btnText={selectedFeed ? setBtnTextType(selectedFeed) : ""}
          onClose={handleModalClose}
          onBookmarkChange={handleBookmarkChange}
          onBtnClicked={() => handleBtnClicked(selectedFeed)}
          onViewProfile={() => handleViewProfile(selectedFeed)}
          showProfile={true}
          notSelf={true}
          isRequest={isRequest[selectedFeed.type + selectedFeed.id]}
        />
      ) : null}
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          elevation={0}
          variant="filled"
          onClose={handleErrorClose}
          severity="error"
          children={errorMsg}
        />
      </Snackbar>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert
          elevation={0}
          variant="filled"
          onClose={handleSuccessClose}
          severity="success"
          children={successMsg}
        />
      </Snackbar>
      <DialogComponent
        registerClassName="dialogRequest"
        open={open}
        title={title}
        onClose={() => setOpen(false)}
        content={
          <DialogConnectionBuyer setMessage={setMessage} message={message} />
        }
        isRequest={isConnectionRequest}
        buttonAgree="Send"
        section=""
        buttonCancel="Cancel"
        onAgree={handleAgree}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    selectedPage: feedSelectors.getSelectedPage(state),
    selectedFilter: feedSelectors.getSelectedFilter(state),
    profileData: profileSelectors.getProfileData(state),
    feedData: feedSelectors.getFeedData(state),
    newFeedData: feedSelectors.getNewFeedData(state),
    taxonsData: commonSelectors.getTaxonsData(state),
  }),
  {
    setNewFeedData: feedActions.setNewFeedData,
    setSelectedPage: feedActions.setSelectedPage,
    setSelectedFilter: feedActions.setSelectedFilter,
    setFeedData: feedActions.setFeedData,
    setTabValue: accountActions.setTabValue,
    setShouldUpdateMsg: messagesActions.setShouldUpdateMsg,
    setDropCollectionData: dropsActions.setDropCollectionData,
    setPreviousLocationData: commonActions.setPreviousLocationData,
    setProfileData: profileActions.setProfileData,
    setTaxonsData: commonActions.setTaxonsData,
  }
)(HomeFeed);
