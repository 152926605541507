import React from 'react';
import Grid from '@material-ui/core/Grid';
import './AccessHeadingComponent.scss';

function AccessHeadingComponent(props) {
    return (
        <Grid container direction="column" className="access-heading-container">
            <Grid item className="access-heading-logo">
                <img src="/assets/images/logo-full.svg" alt="abana" />
            </Grid>
            <Grid item className="access-heading-text">
                {props.heading}
            </Grid>
        </Grid>
    );
}

export default AccessHeadingComponent;