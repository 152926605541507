import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.scss';

function Loading(props) {
    return (
        <div 
            className={
                "app-loading-container" + 
                (props.className ? (" " + props.className) : "")}
        >
            <CircularProgress 
                size={props.size || 25}
                thickness={props.thickness || 4.5}
            />
        </div>
    );
}

export default Loading;