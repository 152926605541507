const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

const feedService = {
    getFeeds: function(user_id, user_type, page, per_page, filter, tags_only, token, cancelToken){
        const url = `${API_URL}/get-feed?user_id=${user_id}&user_type=${user_type}&page=${page}&per_page=${per_page}&filter=${filter}&tags_only=${tags_only}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getFeedNews: function(user_id, user_type, token, cancelToken){
        const url = `${API_URL}/get-feed-news?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    bookmark: function(data, token){
        const url = `${API_URL}/feed-bookmark`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    trackContact: function(data, token){
        const url = `${API_URL}/feed-contact`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
};

export default feedService;