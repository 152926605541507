//Module Name
export const name = 'register';

// Actions
export const types = {
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    SET_REGISTER_SELLER_DATA: 'SET_REGISTER_SELLER_DATA',
    SET_TAXONS_LIST: 'SET_TAXONS_LIST',
    SET_TAXONS_SELECTED: 'SET_TAXONS_SELECTED',
};

const initialState = {
    activeStep: 1,
    registerSellerData: {},
    taxonsList: [],
    taxonsSelected: []
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_ACTIVE_STEP: {
            const { content } = action.payload;

            return {
                ...state,
                activeStep: content
            };
        }
        case types.SET_REGISTER_SELLER_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                registerSellerData: content
            };
        }
        case types.SET_TAXONS_LIST: {
            const { content } = action.payload;

            return {
                ...state,
                taxonsList: content
            };
        }
        case types.SET_TAXONS_SELECTED: {
            const { content } = action.payload;

            return {
                ...state,
                taxonsSelected: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setActiveStep: content => ({
        type: types.SET_ACTIVE_STEP,
        payload: {
            content
        }
    }),
    setRegisterSellerData: content => ({
        type: types.SET_REGISTER_SELLER_DATA,
        payload: {
            content
        }
    }),
    setTaxonsList: content => ({
        type: types.SET_TAXONS_LIST,
        payload: {
            content
        }
    }),
    setTaxonsSelected: content => ({
        type: types.SET_TAXONS_SELECTED,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getActiveStep: store => store[name].activeStep,
    getRegisterSellerData: store => store[name].registerSellerData,
    getTaxonsList: store => store[name].taxonsList,
    getTaxonsSelected: store => store[name].taxonsSelected
};