import { sha256 } from 'js-sha256';

export function stringToSha256(value) {
    var hash = sha256.create();
    hash.update(value);
    return hash.hex();
}

export function getUrlParams(url) {
    let params = {};
    const decodedURI = decodeURIComponent(url);

    decodedURI.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        return params[key] = value;
    });

    return params;
}

export function filterArray(array, key, value) {
    return array.filter(element => {
        return element[key] === value;
    });
};

export function filterContriesByContinent(countries, key, continent) {
    return countries.filter(element => {
            return continent.includes(element[key]);
    });
};

export function getCountryNameByCode(array, shortCode) {
    const result = array.filter(element => {
        return element['countryShortCode'] === shortCode;
    });

    return (result.length > 0) ? result[0]['countryName'] : null;
};

export function getItemsPerPage(list, currentPage, itemsPerPage) {
    const startIndex = itemsPerPage * (currentPage - 1);
    const endIndex = startIndex + itemsPerPage;

    if(list && list.length > 0) {
        return list.slice(startIndex, endIndex);
    } else {
        return [];
    }
};

export function getFirstChar(value) {
    if (typeof value === 'string' || value instanceof String) {
        return value.toUpperCase().charAt(0);
    } else {
        return null;
    }
};

export function capitalize(value) {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export function convertToInches(value) {
    const inches = (value/2.54).toFixed(1);
    return inches
};

export function arrayUnique(array) {
    var a = [];
    array.forEach(element => {
        if (!a.hasOwnProperty(element.code)) {
            a[element.code] = element;
        }
    });

    array = [];
    for (const [key, value] of Object.entries(a)) {
        array.push(value);
    }
    return array;
}
