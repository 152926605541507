import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AccessImage } from '../_common/index';
import './AccessLayout.scss';

function AccessLayout(props) {

    return (
        <Grid container className="access-layout-container">
            <Grid
                container
                item
                xs={12}
                md={5}
                justify="center"
                alignItems="center"
                className="access-layout-left"
            >
                <AccessImage />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={7}
                justify="center"
                alignItems="center"
                className="access-layout-right-container"
            >
                <div className="access-layout-right">
                    {props.children}
                </div>
            </Grid>

        </Grid>
    );
}

export default AccessLayout;