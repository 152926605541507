export const africanCodeCountriesList = [
    'DZ', 
    'AO', 
    'BJ', 
    'BW', 
    'BF', 
    'BI', 
    'CM', 
    'CV', 
    'CF', 
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'KE',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'YT',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RE',
    'RW',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SD',
    'SR',
    'TZ',
    'TG',
    'TN',
    'UG',
    'ZM',
    'ZW'
]