import React, { useState } from 'react';
import { 
    TitleHeading, UploadFile, ButtonInverted, BorderedContent, UploadValidator
} from '../../../_common/index';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from "formik";
import './AdditionalInfo.scss';

function Form(props) {
    const {
        handleSubmit,
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            
        </form>
    );
}

function AdditionalInfo(props){
    const dataList = props.dataList || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    const inputName = 'uspOther';
    let inputOtherInit = {
        [inputName]: (dataList['other'] || '')
    };
    let yupObject = {
        // [inputName]: Yup.string('')
        //     .max(20, 'Maximum number of characters is 20')
    };
    let uploadedFileInit = dataList['attached_file'] || null;

    const [uploadedFile, setUploadedFile] = useState(uploadedFileInit);
    const [showFileError, setShowFileError] = useState(false);
    const validationSchema = Yup.object(yupObject);

    const handleUploadFile = (uploadedFile) => {
        setUploadedFile(uploadedFile);
    };

    const handleDeleteFile = () => {
        setUploadedFile(null);
    };

    const handleFileError = (value) => {
        setShowFileError(value);
    };

    const handleFormSubmit = (values) => { 
        props.onSubmit({
            attached_file: uploadedFile
        });
    };

    return (
        <div className="add-info-container">
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        inputName={inputName}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={inputOtherInit}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
            <Grid
                container
                alignItems="center"
            >
                {
                    uploadedFile ?
                    <BorderedContent 
                        text={uploadedFile['name']}
                    /> : null
                }
                <Grid container item className="button-container">
                    <ButtonInverted
                        onClick={handleDeleteFile}
                    >
                        Delete
                    </ButtonInverted>
                    <UploadFile  
                        onUploadImage={handleUploadFile}
                        onFileError={handleFileError}
                        buttonText="Upload File"
                    />
                    {
                        !uploadedFile ?
                        <UploadValidator 
                            size={'5MB'}
                            types={'PNG, JPEG, PDF, XLS, XLSX, DOC, DOCX'}
                            showError={showFileError}
                        /> : null
                    } 
                </Grid>
            </Grid>   
        </div>
    );
}

export default AdditionalInfo;