export function getSelectedCon() {
    return JSON.parse(sessionStorage.getItem('abana-selected-con'));
}

export function setSelectedCon(data) {
    return sessionStorage.setItem('abana-selected-con', JSON.stringify(data));
}

export function getUserPreferences() {
    return JSON.parse(localStorage.getItem('abana-user-preferences'));
}

export function setUserPreferences(data) {
    return localStorage.setItem('abana-user-preferences', JSON.stringify(data));
}