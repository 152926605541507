import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { MobileStepper } from '../index';
import './AppBarStepper.scss';

function AppBarStepper(props) {

    return (
        <div className="app-bar-stepper-container">
            <AppBar 
                position="relative" 
                color="inherit" 
            >
                <Toolbar>
                    {
                        props.disableLogo ? null :
                        <Grid 
                            container 
                            justify="center" 
                            alignItems="center"
                            className="section-left"
                        >
                            <img src="/assets/images/logo-full.svg" alt="abana" />
                        </Grid>
                    }      
                    <div className="section-right">
                        { props.children }
                    </div>
                </Toolbar>
            </AppBar>
            {
                props.progressBar ?
                <MobileStepper 
                    variant={props.variant}
                    steps={props.steps}
                    position={props.position}
                    activeStep={props.activeStep}
                /> : null
            }
            
        </div>
    );
}

export default AppBarStepper;