import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function DropzoneComponent(props) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.warn("file reading was aborted");
        reader.onerror = () => console.error("file reading has failed");
        reader.onload = () => {
          const base64 = reader.result;
          props.onChange(file, base64);
        };
        reader.readAsDataURL(file);
      });
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: props.onDropRejected,
    accept: props.accept,
    multiple: props.multiple,
    maxSize: props.maxSize,
  });

  return (
    <div
      {...getRootProps()}
      className="dropzone-container"
      id={props.id || "dropzone-container"}
    >
      <input {...getInputProps()} disabled={props.disabled} />
      {/* <p>Add Your Company Picture / Logo</p>  */}
      {props.contents}
    </div>
  );
}

export default DropzoneComponent;
