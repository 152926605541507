//Module Name
export const name = 'results';

// Actions
export const types = {
    SET_RESULTS_DATA: 'SET_RESULTS_DATA',
    SET_CURRENT_PROFILE_ID: 'SET_CURRENT_PROFILE_ID',
};

const initialState = {
    resultsData: [],
    currentProfileId: null
}

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_RESULTS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                resultsData: content
            };
        }
        case types.SET_CURRENT_PROFILE_ID: {
            const { content } = action.payload;

            return {
                ...state,
                currentProfileId: content
            };
        }
        default:
            return state;
    }
}


// Action Creators
export const actions = {
    setResultsData : content => ({
        type: types.SET_RESULTS_DATA,
        payload: {
            content
        }
    }),
    setProfileId : content => ({
        type: types.SET_CURRENT_PROFILE_ID,
        payload: {
            content
        }
    })
}


// Selectors
export const selectors = {
    getResultsData: store => store[name].resultsData,
    getProfileId: store => store[name].currentProfileId
}