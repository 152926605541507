import { capitalize } from "./converters";

export function findPageCount(itemsCount, itemsPerPage) {
    return Math.ceil(itemsCount / itemsPerPage);
}

export function getSellerPrefix(sectionType) {
    let prefix;

        switch(sectionType) {
            case 'SG':
                prefix = 'sg_';
                break;
            case 'SI':
                prefix = 'si_';
                break;
            case 'SS':
                prefix = 'ss_';
                break;
            default:
                prefix = null;
        }

    return prefix;
}

export function getUserKind(userType) {
    return userType ? userType.charAt(0).toUpperCase() : null;
}

export function getPlan(userKind, subscriptionType){
    let userPlan = null;

    if (userKind) {
        userPlan = "Seller - ";
        if (userKind === 'B') {
            userPlan = "Buyer - ";
        }
    }
  
    if (subscriptionType) {
        userPlan += capitalize(subscriptionType)
    }
    return userPlan;
}

export function getSubscriptionLevel(userType) {
    const userKind = getUserKind(userType);

    switch(userKind) {
        case 'B':
            return (parseInt(userType.charAt(1)) || 0);
        case 'S':
            return (parseInt(userType.charAt(2)) || 0);
        default:
            return 0;
    }
}

export function getSectionType(userType) {
    return userType ? userType.slice(0,2).toUpperCase() : null;
}


export function getProductsLimit(subscriptionLevel, userKind) {
    let productsLimit = 0;

    if(userKind === 'B') {
        switch(parseInt(subscriptionLevel)) {
            case 1: 
                productsLimit = 3;
                break;
            case 2: 
                productsLimit = 24;
                break;
        }
    } else {
        switch(parseInt(subscriptionLevel)) {
            case 0: 
                productsLimit = 4;
                break;
            case 1: 
                productsLimit = 8;
                break;
            case 3: 
                productsLimit = 24;
                break;
        }
    }

    return productsLimit;
}

export function getListingsLimit(userKind, subscriptionLevel) {
    let RFIsLimit = 0;

    switch(userKind) { 
        case 'B':
            switch(parseInt(subscriptionLevel)) {
                case 1: 
                    RFIsLimit = 15;
                    break;
            }
            break;
        case 'S':
            switch(parseInt(subscriptionLevel)) {
                case 1: 
                    RFIsLimit = 5;
                    break;
            }
            break;
    }

    return RFIsLimit;
}

export function getSizeRatio(data) {
    let sizes = '';
    let ratio = '';

    function getSize(size) {
        switch(size) {
            case 'xxl':
            case 'XXL':
                return '2XL';
            case 'xxxl':
            case 'XXXL':
                return '3XL';
            default:
                return size.toUpperCase();
        }
    }

    for(let prop in data) {
        if(data[prop]) {    
            if(!sizes) {
                sizes += getSize(prop);
                ratio += data[prop];
                continue;
            }

            sizes += ':' + getSize(prop);
            ratio += ':' + data[prop];
        }
    }

    return sizes + ' - ' + ratio;
}

export function getSumOfPieces(data) {
    let sum = 0;

    for(let prop in data) {
        if(data[prop]) {    
            sum += parseInt(data[prop])
            continue;
        }
    }

    return sum;
}

const subsPlansTexts = {
    customer: {
        second: 'Basic Free',
        third: 'Pro',
    },
    vendor: {
        zero: 'Freelancer',
        first: 'Basic',
        third: 'Pro',
    },
};


export function getPlanText(user, subscription) {
    return subsPlansTexts[user][subscription];
};

export function getSubsType(currentSubs, userKind) { 
    if(userKind === 'B') {
        switch(currentSubs) {
            case 1:
                return 'second';
            case 2:
                return 'third';
        }
    } else {
        switch(currentSubs) {
            case 0:
                return 'zero';
            case 1:
                return 'first';
            case 2:
                return 'third';
            case 3:
                return 'third';
        }
    }

    return 'third';
}

export function getSubsPlans(period, subsPlans, userKind, userType) {
   let subsListInit = [
        {
            id: 'second',
            index: 1
        },
        {
            id: 'third',
            index: 2
        },
    ];

    if (userKind == 'S') {
        subsListInit = [
            {
                id: 'first',
                index: 1
            },
            {
                id: 'third',
                index: 3
            },
        ];
    }

    if(userType === 'SS') {
        subsListInit = [
            {
                id: 'zero',
                index: 0 
            },
            {
                id: 'first', 
                index: 1
            },
            {
                id: 'third', 
                index: 3    
            }
        ];
    }

    if(subsPlans.user) {
        subsListInit = subsListInit.map((item, index) => {
            return {
                ...item,
                name: subsPlansTexts[subsPlans.user][item.id],
                subscriptionLevel: item.index
            }
        });
    }

    if(subsPlans[period]) {
        if(userKind === 'B') {
            subsListInit[0].price = subsPlans[period].second ? subsPlans[period].second.price : null;
            subsListInit[0].discount = subsPlans[period].second ? subsPlans[period].second.discount : null;
            subsListInit[0].monthly_price = subsPlans[period].second ? subsPlans[period].second.monthly_price : null;
            subsListInit[1].price = subsPlans[period].third ? subsPlans[period].third.price : null;
            subsListInit[1].discount = subsPlans[period].third ? subsPlans[period].third.discount : null;
            subsListInit[1].monthly_price = subsPlans[period].third ? subsPlans[period].third.monthly_price : null;

        } else {
            if (userType !== 'SS') {
                subsListInit[0].price = subsPlans[period].first ? subsPlans[period].first.price : null;
                subsListInit[0].discount = subsPlans[period].first ? subsPlans[period].first.discount : null;
                subsListInit[0].monthly_price = subsPlans[period].first ? subsPlans[period].first.monthly_price : null;
                subsListInit[1].price = subsPlans[period].third ? subsPlans[period].third.price : null;
                subsListInit[1].discount = subsPlans[period].third ? subsPlans[period].third.discount : null;
                subsListInit[1].monthly_price = subsPlans[period].third ? subsPlans[period].third.monthly_price : null;
            } else {
                subsListInit[0].price = subsPlans[period].zero ? subsPlans[period].zero.price : null;
                subsListInit[0].discount =  subsPlans[period].zero ? subsPlans[period].zero.discount : null;
                subsListInit[0].monthly_price =  subsPlans[period].zero ? subsPlans[period].zero.monthly_price : null;
                subsListInit[1].price = subsPlans[period].first ? subsPlans[period].first.price : null;
                subsListInit[1].discount = subsPlans[period].first ? subsPlans[period].first.discount : null;
                subsListInit[1].monthly_price = subsPlans[period].first ? subsPlans[period].first.monthly_price : null;
                subsListInit[2].price = subsPlans[period].third ? subsPlans[period].third.price : null;
                subsListInit[2].discount = subsPlans[period].third ? subsPlans[period].third.discount : null;
                subsListInit[2].monthly_price = subsPlans[period].third ? subsPlans[period].third.monthly_price : null;
            } 
        }
    }

    return subsListInit;
}

export function getCurrentPlanText(currentSubs, currentPeriod, subsPlans) {
    if(!currentPeriod || !subsPlans || !subsPlans.user) {
        return '';
    }

    const planText = subsPlansTexts[subsPlans.user][currentSubs];
    const price = subsPlans[currentPeriod][currentSubs] ? subsPlans[currentPeriod][currentSubs].price : '';
    const period = getSubsPeriodMonths(currentPeriod);

    if(planText && price && period) {
        return `${planText} - $${price} per month (billed every ${period} months)`;
    } 

    return '';
}

export function getSubsPeriodLevel(subsPeriod) {
    switch(subsPeriod) {
        case 'quarterly':
            return 1;
        case 'semesterly':
            return 2;
        case 'annually':
            return 3;
        default:
            return '';
    }
}

export function getSubsPeriodMonths(subsPeriod) {
    switch(subsPeriod) {
        case 'quarterly':
            return 3;
        case 'semesterly':
            return 6;
        case 'annually':
            return 12;
        default:
            return '';
    }
}

export function getQuotas(total, used) {
    let remaining = parseInt(total) - parseInt(used);

    if (remaining < 0) {
        remaining = 0;
    }

    return {
        remaining,
        quota: `${remaining}/${total}`
    };
}