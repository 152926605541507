import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './DatePicker.scss';
import { Grid } from '@material-ui/core';

function DatePicker(props){

  const handleDateChange = (date) => {
    props.onChange(date);
  };

    return(
       <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container direction="column" className="date-Picker-container">
            <label htmlFor={props.name}>{props.labelname}</label>
              <KeyboardDatePicker
                {...props}
                disableToolbar
                disablePast={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleDateChange}
              />
          </Grid>
       </MuiPickersUtilsProvider>
    )
}

export default DatePicker;