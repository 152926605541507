import React from 'react';
import { Link } from "react-router-dom";
import { ButtonOutlined } from '../../_common/index';

import './AreYouASellerComponent.scss';

function AreYouASellerComponent(props) {
    return (
        <div className="are-you-a-seller-container">

            <div className={"are-you-a-seller-text " + (props.bottom ? 'bottom' : null)}>
                <Link to="/register-seller">
                    <ButtonOutlined color="primary">Are you a Supplier or Service Provider? Click Here</ButtonOutlined>
                </Link>
            </div>


        </div>
    );
}

export default AreYouASellerComponent;