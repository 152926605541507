import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import './TextFieldComponent.scss';

function TextFieldComponent(props) {
    return (
        <Grid container direction="column" className="text-field-container">
            {
                props.subtitle ?
                <Grid
                    container
                    direction="row"
                    className="title-container"
                >
                    <label htmlFor={props.name}>{props.labelname}</label>
                    <label className="subtitle">{props.subtitle}</label>
                </Grid>
                :
                <label htmlFor={props.name}>{props.labelname}</label>
            }
           
            <TextField 
                variant="outlined"
                margin="dense"
                {...props}
            />
        </Grid>       
    );
}

export default TextFieldComponent;