import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpIconOutlined from "@material-ui/icons/HelpOutline";
import './ToolTipComponent.scss';

function ToolTipComponent(props) {
    return (
        <Tooltip
        title={props.label}
        placement={props.placement}
        arrow
      >
        <IconButton disableRipple >
          <HelpIconOutlined color="disabled" fontSize="small" />
        </IconButton>
      </Tooltip>
    );
}

export default ToolTipComponent;