import React from 'react';
import Button from '@material-ui/core/Button';
import './ButtonComponent.scss';

function ButtonComponent(props) {
    return (
        <Button 
            variant="contained"
            disableElevation={true}
            {...props}
        >
            {props.children}
        </Button>
    );
}

export default ButtonComponent;