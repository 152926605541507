import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  Dialog,
  Avatar,
  Fab,
  Grid,
  FormControlLabel,
  Checkbox,
  MobileStepper,
  CircularProgress,
} from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ButtonOutlined, TagsList } from "../index";
import { getSizeRatio } from "../../../functions/compute";
import {
  getCountryNameByCode,
  capitalize,
} from "../../../functions/converters";
import CountryRegionData from "country-region-data";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import CheckCircle from "@material-ui/icons/CheckCircle";
import "./ModalDisplay.scss";

const dateFormat = require("dateformat");

function Bookmark(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<BookmarkOutlinedIcon fontSize="small" />}
          checkedIcon={<BookmarkIcon fontSize="small" />}
          name="bookmark"
          checked={props.checked}
          onChange={props.onChange}
        />
      }
    />
  );
}

function ModalDisplay(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dataList, open, btnText } = props;
  // const [activeStepProduct, setActiveStepProduct] = useState(props.productIndex);

  const [activeStepImage, setActiveStepImage] = useState(0);

  if (!open || !dataList) {
    return null;
  }

  // const profileBasics = props.profileData.basics;
  // const catalogue = cloneDeep(props.catalogueData);
  // const maxStepsProduct = catalogue.length;
  // const activeProduct = catalogue[activeStepProduct];
  // const maxStepsImage = activeProduct.images.length;
  // const activeImage = activeProduct.images[activeStepImage];
  // const activeImagePath = activeImage.path || activeImage.base64;
  // const profileTags = activeProduct.tags || null;
  // let buttonText = 'Contact Buyer';

  // if(props.profileKind === 'S') {
  //     buttonText = 'Contact Seller';
  // }

  const activeProduct = {};

  let avatar = dataList.logo ? dataList.logo.path : null;
  let title =
    dataList.title || dataList.companyName || dataList.basics.title || null;
  const createdDate = dataList.createdDate
    ? dateFormat(dataList.createdDate, "d mmmm yyyy")
    : null;
  const image1 = dataList.basics.image1 ? dataList.basics.image1.path : null;
  const description = dataList.basics.description || null;
  const tags = dataList.basics.tags || null;
  const itemTitle = null;
  const anonymous = dataList.anonymous;
  const verified = dataList.verified;
  const maxStepsImage = 2;
  let type = dataList.type;

  let tagLine = "";

  if (type === "rfi") {
    tagLine = "is looking for";
  }

  if (type === "listing") {
    tagLine = dataList?.basics?.requestType || "can supply";
  }

  let image2, file, infoList, imageArray;

  if (dataList.details) {
    image2 = dataList.details.image2 ? dataList.details.image2.path : null;
    file = dataList.details.file ? dataList.details.file : null;
    infoList = [
      { name: "Colours", value: dataList.basics.colours || null },
      {
        name: "MOQ",
        value: dataList.basics.order_quantity
          ? dataList.basics.order_quantity + " pieces"
          : null,
      },
      {
        name: "Size Ratio",
        value: dataList.details.size_ratio
          ? getSizeRatio(dataList.details.size_ratio)
          : null,
      },
      {
        name: "Packaging",
        value:
          dataList.details.other_packaging ||
          capitalize(dataList.details.packaging) ||
          null,
      },
      {
        name: "Delivery Country",
        value:
          getCountryNameByCode(CountryRegionData, dataList.terms.country) ||
          null,
      },
      {
        name: "Delivery Date",
        value: dataList.terms.delivery_date
          ? dateFormat(dataList.terms.delivery_date, "d mmmm yyyy")
          : null,
      },
      {
        name: "Price Terms",
        value:
          dataList.terms.other_price_terms ||
          dataList.terms.price_terms ||
          null,
      },
    ];
  }

  if (dataList.anonymous && props.notSelf) {
    if (dataList.type === "rfi" || dataList.type === "listing") {
      avatar = "/assets/images/background_grey.png";
    }

    if (dataList.type === "rfi") {
      title = "A Buyer's RFI";
    }
    if (dataList.type === "listing") {
      title = "A Supplier’s Listing";
    }
  }

  if (image2) {
    imageArray = [image1, image2];
  }

  const handleClose = () => {
    props.onClose();
  };

  const handleImagePrev = () => {
    if (activeStepImage === 0) {
      setActiveStepImage(maxStepsImage - 1);
    } else {
      setActiveStepImage((prevActiveStepImage) => prevActiveStepImage - 1);
    }
  };

  const handleImageNext = () => {
    if (activeStepImage === maxStepsImage - 1) {
      setActiveStepImage(0);
    } else {
      setActiveStepImage((prevActiveStepImage) => prevActiveStepImage + 1);
    }
  };

  const handleBookmarkChange = (event) => {
    props.onBookmarkChange(dataList, event.target.checked);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="products-carousel"
      classes={{ container: "modal-display-container" }}
    >
      <Grid
        className="dialog-content"
        container
        direction="column"
        wrap="nowrap"
      >
        {fullScreen ? (
          <Grid
            className="header-container"
            container
            direction="row"
            justify="flex-end"
            item
          >
            <Fab
              aria-label="close"
              onClick={handleClose}
              disableFocusRipple={true}
              disableRipple={true}
            >
              <CloseIcon />
            </Fab>
          </Grid>
        ) : null}
        <Grid
          className="content-container"
          container
          direction="row"
          wrap="nowrap"
          justify="center"
          alignItems="center"
          item
          xs
        >
          <Grid container justify="center" alignItems="center" item xs>
            <div className="product-container">
              <Grid
                className="product-container-inner"
                container
                direction="row"
              >
                {image1 ? (
                  image2 ? (
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      item
                      xs={12}
                      md={6}
                      className="two-image-container"
                    >
                      <Grid
                        className="image-btn"
                        container
                        justify="center"
                        alignItems="center"
                        item
                      >
                        <Fab
                          aria-label="left"
                          onClick={handleImagePrev}
                          disableFocusRipple={true}
                          disableRipple={true}
                        >
                          <ChevronLeftIcon />
                        </Fab>
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        item
                        xs
                      >
                        <img src={imageArray[activeStepImage]} alt="product" />
                        <MobileStepper
                          steps={maxStepsImage}
                          position="static"
                          variant="dots"
                          activeStep={activeStepImage}
                        />
                      </Grid>
                      <Grid
                        className="image-btn"
                        container
                        justify="center"
                        alignItems="center"
                        item
                      >
                        <Fab
                          aria-label="right"
                          onClick={handleImageNext}
                          disableFocusRipple={true}
                          disableRipple={true}
                        >
                          <ChevronRightIcon />
                        </Fab>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      className={
                        type === "report"
                          ? "product-image-report-section"
                          : "product-image-section"
                      }
                      container
                      direction="row"
                      wrap="nowrap"
                      item
                      xs={12}
                      md={6}
                    >
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        item
                        xs
                      >
                        <img src={image1} alt="feed" />
                      </Grid>
                    </Grid>
                  )
                ) : null}

                <Grid
                  className="product-details"
                  container
                  direction="column"
                  item
                  xs={12}
                  md={image1 ? 6 : 12}
                >
                  <Grid
                    className="product-details-title"
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    item
                  >
                    <div className="avatar-details">
                      {avatar ? (
                        <Avatar
                          alt="Profile"
                          src={avatar}
                          className="toolbar-avatar"
                        />
                      ) : null}
                      <div className="avatar-name">
                        <div className="avatar-name-company">
                          {title}{" "}
                          {verified && !anonymous ? <CheckCircle /> : null}
                        </div>
                        {tagLine && (
                          <p className="tagline-company">{tagLine}</p>
                        )}
                      </div>
                    </div>
                    {(type === "rfi" || type === "listing" || type === "rfq") &&
                    props.showProfile ? (
                      <ButtonOutlined
                        onClick={props.onViewProfile}
                        disabled={dataList.anonymous}
                      >
                        {"Profile"}
                      </ButtonOutlined>
                    ) : null}
                  </Grid>
                  <Grid
                    className="product-details-main"
                    container
                    direction="column"
                    item
                    xs
                  >
                    {itemTitle ? (
                      <Grid className="details-main-title" item>
                        {itemTitle}
                      </Grid>
                    ) : null}
                    <Grid item xs>
                      {ReactHtmlParser(description)}
                    </Grid>
                    <Grid item className="card-tags-description">
                      {infoList &&
                        infoList.map((item, index) =>
                          item.value ? (
                            <div key={index} className="card-tags">
                              <span className="tags-name">{item.name}:</span>
                              {" " + item.value}
                            </div>
                          ) : null
                        )}
                    </Grid>
                    {tags ? (
                      <Grid item className="details-tags-container">
                        {tags.map((tag) => (
                          <div className="details-tag" key={tag.code}>
                            {tag.name}
                          </div>
                        ))}
                      </Grid>
                    ) : null}
                  </Grid>
                  {props.viewCard ? (
                    <Grid
                      className="card-footer"
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      item
                    >
                      <div className="date-section">{createdDate}</div>
                      {(type === "rfi" || type === "listing") && anonymous ? (
                        <FormControlLabel
                          control={
                            <Checkbox checked={anonymous} disabled={true} />
                          }
                          label={"Anonymous"}
                        />
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid
                      className="product-details-btn"
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      item
                    >
                      {props.showCreatedDate ? (
                        <div className="date-section">{createdDate}</div>
                      ) : (
                        <Bookmark
                          checked={dataList.bookmark}
                          onChange={handleBookmarkChange}
                        />
                      )}

                      <ButtonOutlined
                        onClick={props.onBtnClicked}
                        disabled={dataList?.contact ? true : props.isRequest}
                      >
                        {props.isRequest ? (
                          <span className="dl-container">
                            <span className="dl-text">Processing</span>
                            <CircularProgress size={18} />
                          </span>
                        ) : (
                          <>{btnText}</>
                        )}
                      </ButtonOutlined>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ModalDisplay;
