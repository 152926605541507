import React from 'react';
import { Card, Grid, Avatar, ButtonBase, CircularProgress } from '@material-ui/core';
import {Chip, Button, ButtonOutlined} from '../../_common/index';
import { getCountryNameByCode } from '../../../functions/converters';
import { getSizeRatio } from '../../../functions/compute';
import CountryRegionData from 'country-region-data';
import ReactHtmlParser from 'react-html-parser'; 
import CheckCircle from '@material-ui/icons/CheckCircle';
import './CardQuotes.scss';

const dateFormat = require('dateformat');

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
};

function CardQuotes(props) {
    const dataList = props.dataList;
    const avatar = dataList.logo ? dataList.logo.path : null;
    const companyName = dataList.companyName || null;
    const createdDate = dataList.createdDate ? dateFormat(dataList.createdDate, 'd mmmm yyyy') : null;
    const totalQuotes = (dataList.total_quotes || dataList.total_quotes == 0) ? (dataList.total_quotes + ' Quotes') : null;
    const image1 = dataList.basics.image1 ? dataList.basics.image1.path : null;
    const description = dataList.basics.description || null;
    const tags = dataList.basics.tags || null;
    const image2 = dataList.details.image2 ? dataList.details.image2.path : null;
    const file = dataList.details.file ? dataList.details.file : null;
    const verified = dataList.verified;
    const infoList = [
        { name: 'Colours', value: dataList.basics.colours || null },
        { name: 'MOQ', value: dataList.basics.order_quantity ? (dataList.basics.order_quantity + ' pieces') : null },
        { name: 'Size Ratio', value: dataList.details.size_ratio ? getSizeRatio(dataList.details.size_ratio) : null },
        { name: 'Packaging', value: dataList.details.other_packaging || capitalize(dataList.details.packaging) || null },
        { name: 'Delivery Country', value: getCountryNameByCode(CountryRegionData, dataList.terms.country) || null },
        { name: 'Delivery Date', value: dataList.terms.delivery_date ? dateFormat(dataList.terms.delivery_date, 'd mmmm yyyy') : null },
        { name: 'Price Terms', value: dataList.terms.other_price_terms || dataList.terms.price_terms || null },
    ];

    let section2 = null;

    if(image2) {
        section2 = <img className="card-image" src={image2} alt="Product"/>;
    } else if(file) {
        section2 = (
            <div className="card-attachment">
                <img className="attachment-image" src={'/assets/images/attachment@2x.png'} alt="Product"/>
                <span className="attachment-text">{file.name}</span>
            </div>
        );
    } else {
        section2 = <img className="card-image" src={'/assets/images/default-1.png'} alt="Product"/>;
    }

    return(
        <div className="card-request-component">
            <Card className="card-request-container">
                <ButtonBase onClick={props.onViewQuotes} className="card-button">  
                    <Grid
                        container
                        className="card-request-main"
                        direction="row"
                        wrap="nowrap"
                    >
                        <Grid 
                            className="card-image-container"
                            container
                            direction="column"
                            justify="space-between"
                        >
                            {/* {
                                (dataList.quotes.image).map((item, index) => (
                                    (item !== null ) ?
                                    <div key={index}  className="card-image-content"><img className="card-image" src={dataList.quotes.image[0]} alt="Abana's Quotes"/></div>
                                    : 
                                    null  
                                ))
                            } */}
                            <div className="card-image-content">
                                <img className="card-image" src={image1} alt="Product"/>
                            </div>
                            {
                                <div className="card-image-content">
                                    {section2}
                                </div>
                            }
                        </Grid> 
                      
                        <Grid
                            className="card-content-container"
                            container
                            direction="column"
                            wrap="nowrap"
                        >
                            <Grid
                                container
                                item
                                className="card-request-header"
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                {
                                   avatar ?
                                    <Avatar 
                                        alt="Profile" 
                                        src={avatar}
                                        className="toolbar-avatar"
                                    /> : null
                                }
                                <div className="company-name">
                                    {companyName} {verified ? <CheckCircle/> : null}
                                </div>
                            </Grid>

                            <Grid  
                                item
                                xs
                                className="card-description"
                            >
                                <div>
                                    {ReactHtmlParser(description)}
                                </div>
                            </Grid>

                            <Grid
                                item
                                className="card-tags-description"
                            >
                                {
                                    infoList.map((item, index) => (
                                        item.value ?
                                        <div key={index} className="card-tags">
                                            <span className="tags-name">
                                                {item.name}: 
                                            </span>
                                            {' ' + item.value}
                                        </div> : null
                                    ))
                                }
                            </Grid>

                            {
                                tags ?
                                <Grid 
                                    item
                                    className="card-tags-container"
                                >
                                    {
                                        tags.map(item => 
                                            <Chip 
                                                key={item.code}
                                                label={item.name}
                                            />
                                        )
                                    }
                                </Grid> : null
                            }
                        </Grid>
                    </Grid>
                </ButtonBase> 
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="card-request-footer"
                >  
                    <div className="date-section">{createdDate}</div>
                    {
                        !props.fullList ?
                        <Button 
                            onClick={props.onViewQuotes}
                            disabled={dataList.total_quotes == 0}
                        >
                            {props.userKind === 'B' ? totalQuotes : 'View Quote'}
                        </Button> : 
                        <>
                        {
                            (props.fullList && props.userKind === 'B') ?
                            <ButtonOutlined onClick={props.onDelete}>
                                {'Delete'}
                            </ButtonOutlined>
                            :
                           <div className="quotes-button"> 
                            { props.seeProfile ? 
                                <ButtonOutlined
                                    onClick={props.onViewProfile}
                                    className="view-profile-button"
                                >
                                    {'See Profile'}
                                </ButtonOutlined>
                            : null }
                                <ButtonOutlined 
                                    onClick={props.onDownload}
                                    disabled={props.isRequest}
                                    className="download-button"
                                >
                                    {
                                        props.isRequest ?
                                        <span className="dl-container">
                                            <span className="dl-text">Downloading</span>
                                            <CircularProgress size={18} />
                                        </span> :
                                        'Download PDF'    
                                    }
                                </ButtonOutlined>
                            </div>
                        }  
                        </>
                    } 
                </Grid>       
            </Card>
        </div>
    )
};


export default CardQuotes;