import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Card, ButtonBase, Grid, Avatar} from '@material-ui/core';
import { TitleHeading, Chip, Checkbox } from '../../_common/index';
import ReactHtmlParser from 'react-html-parser'; 
import CheckCircle from '@material-ui/icons/CheckCircle';
import './CardListings.scss';

const dateFormat = require('dateformat');

function CardListings(props) {
    const dataList = props.listingData;

    const avatar = dataList.logo ? dataList.logo.path : null;
    const companyName = dataList.companyName || null;
    const image = dataList.basics.image1 ? dataList.basics.image1.path : null;
    const createdDate = dataList.createdDate ? dateFormat(dataList.createdDate, 'd mmmm yyyy') : null;
    const anonymous = dataList.anonymous || false;
    const description = dataList.basics.description || null;
    const tags = dataList.basics.tags || null;
    const verified = dataList.verified;

   
    return (
        <div className="card-component-container">
            <Card className="card-container">
                <ButtonBase onClick={props.onClick}>
                    <Grid
                        container
                        alignItems="center"
                        className="card-main"
                    >
                        {
                            image ?
                            <Grid className="card-image-container">
                                <img className="card-image" src={image} alt="listing"/>
                            </Grid> : null
                        }       
                        <Grid
                            className="card-right"
                            container
                            direction="column"
                            wrap="nowrap"
                            item
                        >
                            <Grid
                                className="card-header"
                                container
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                                item
                            >
                                {
                                    avatar ?
                                    <Avatar 
                                        alt="Profile" 
                                        src={avatar}
                                        className="toolbar-avatar"
                                    /> : null
                                }
                                <div className="company-name">
                                    {companyName} {verified && !anonymous ? <CheckCircle/> : null}
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs
                                className="card-description"
                            >
                                <div>
                                    {ReactHtmlParser(description)}
                                </div>
                            </Grid>
                            {
                                tags ?
                                <Grid 
                                    item
                                    className="card-tags-container"
                                >
                                    {
                                        tags.map(item => 
                                            <Chip 
                                                key={item.code}
                                                label={item.name}
                                            />
                                        )
                                    }
                                </Grid> : null
                            }
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        className="card-footer"
                    >
                        <div className="date-section">{createdDate}</div>
                        { anonymous ?
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={anonymous}
                                    disabled={true}
                                />
                            }
                            label={'Anonymous'}
                        /> : null
                        }
                    </Grid>
                </ButtonBase>
            </Card>
        </div>     
    );
}

export default CardListings