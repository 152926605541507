import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import './TagsList.scss';

function TagsList(props) {
    return (
        <div className="tags-list-container">
            <div className="panel-tag-headings">{props.title}</div>
            <Grid
                container  
                direction="row" 
                justify="flex-start" 
                alignItems="center"
                className="tags-container"
            >
                {
                    props.list.map((tag, index) => (
                        <Grid
                            key={index}
                            item
                            container  
                            direction="row" 
                            justify="center" 
                            alignItems="center"
                            className="tags-box"
                        >
                            <div className="text-box">
                                {tag[props.name]}
                            </div>
                        </Grid>
                    ))
                }
            </Grid>
            
        </div>      
    );
}

export default TagsList;