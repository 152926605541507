//Module Name
export const name = 'profile';

// Actions
export const types = {
    SET_CLIENT_DATA: 'SET_CLIENT_DATA',
    SET_PROFILE_DATA: 'SET_PROFILE_DATA',
    SET_CATALOGUE_DATA: 'SET_CATALOGUE_DATA',
    SET_PROFILE_VIEW_DATA: 'SET_PROFILE_VIEW_DATA',
    SET_CATALOGUE_VIEW_DATA: 'SET_CATALOGUE_VIEW_DATA',
    SET_SHOULD_UPDATE: 'SET_SHOULD_UPDATE'
};

const initialState = {
    clientData: null,
    profileData: null,
    catalogueData: null,
    profileViewData: null,
    catalogueViewData: null,
    shouldUpdate: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_CLIENT_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                clientData: content
            };
        }
        case types.SET_PROFILE_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                profileData: content
            };
        }
        case types.SET_CATALOGUE_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                catalogueData: content
            };
        }
        case types.SET_PROFILE_VIEW_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                profileViewData: content
            };
        }
        case types.SET_CATALOGUE_VIEW_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                catalogueViewData: content
            };
        }
        case types.SET_SHOULD_UPDATE: {
            const { content } = action.payload;

            return {
                ...state,
                shouldUpdate: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setClientData: content => ({
        type: types.SET_CLIENT_DATA,
        payload: {
            content
        }
    }),
    setProfileData: content => ({
        type: types.SET_PROFILE_DATA,
        payload: {
            content
        }
    }),
    setCatalogueData: content => ({
        type: types.SET_CATALOGUE_DATA,
        payload: {
            content
        }
    }),
    setProfileViewData: content => ({
        type: types.SET_PROFILE_VIEW_DATA,
        payload: {
            content
        }
    }),
    setCatalogueViewData: content => ({
        type: types.SET_CATALOGUE_VIEW_DATA,
        payload: {
            content
        }
    }),
    setShouldUpdate: content => ({
        type: types.SET_SHOULD_UPDATE,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getClientData: store => store[name].clientData,
    getProfileData: store => store[name].profileData,
    getCatalogueData: store => store[name].catalogueData,
    getProfileViewData: store => store[name].profileViewData,
    getCatalogueViewData: store => store[name].catalogueViewData,
    getShouldUpdate: store => store[name].shouldUpdate
};