import React from 'react';
import { Card, CardContent, CardActions, CardActionArea, FormControlLabel, Checkbox } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ReactHtmlParser from 'react-html-parser'; 

import './CatalogueProduct.scss';

function CatalogueProduct(props) {
    if(!props.dataList) return null;

    const dataList = props.dataList;
    const title = dataList.title || '';
    const description = dataList.description || '';
    const image = dataList.images ? dataList.images.find((image, i) => {return (image && image.main)}) : null;
    const imagePath = image ? (image.path || image.base64) : null;

    const handleBookmarkChange = (event) => {
        props.onBookmarkChange(dataList, event.target.checked);
    };

    return (
        <Card 
            className="catalogue-product-container"
            variant="outlined"
        >
            <CardActionArea onClick={() => props.onCardClick(dataList)}>
                <img className="product-image" src={imagePath} alt="product" />
                <CardContent>
                    <div className="product-title">{title}</div >
                    <div className="product-description"> {ReactHtmlParser(description)}</div>
                </CardContent>
            </CardActionArea>
            {
                props.showBookmark ?
                <CardActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<BookmarkOutlinedIcon />}
                                checkedIcon={<BookmarkIcon />}
                                name="checked"
                                color="primary"
                                checked={dataList.bookmark}
                                onChange={handleBookmarkChange}
                            />
                        }
                    />
                </CardActions> : null
            }       
        </Card>
    );
}

export default CatalogueProduct;