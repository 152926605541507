import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './ButtonBackComponent.scss';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    buttonBack: {
        background:'#FF6158;',
        color: '#ffffff;'
    },
    iconColor: {
        color: '#ffffff;'
    }
})

function BackButtonComponent (props){
    const classes = useStyles();

    return(
        <React.Fragment>
            <Button
                {...props}
                disableRipple={true}
                disableElevation
                startIcon={<ArrowBackIosIcon className={classes.iconColor} />}
                className={classes.buttonBack}
            >
                {props.children}
            </Button>

        </React.Fragment>
    )
}

export default BackButtonComponent;
