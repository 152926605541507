import React, { useState, useCallback } from 'react'
import { ButtonOutlined, Dropzone, CropperDialog } from '../index';
import getCroppedImg from '../../../functions/cropImage';
import './UploadPicture.scss';

function UploadPicture(props) {
    const dropzoneId = props.id || 'dropzone-area';
    const accept = props.accept || 'image/jpeg, image/png';
    const multiple = props.multiple || false;
    const maxSize = props.maxSize || 2000000;
    const aspect = props.aspect || 1;
    const buttonText = props.buttonText || 'Upload';

    const [droppedImage, setDroppedImage] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const handleImageDropped = (file, base64) => {
      const result = {
          file: file,
          image: base64
      };

      setDroppedImage(result);
      setDialogOpen(true);

      if(props.onFileError) {
        props.onFileError(false);
      }
    };

    const onDropRejected = () => {
      if(props.onFileError) { 
        props.onFileError(true);
      }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleSaveImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            droppedImage.image,
            croppedAreaPixels
          );
          const file = droppedImage['file'];
          const uploadedImage = {
            name: file['name'],
            type: file['type'],
            size: file['size'],
            base64: croppedImage
          };

          if(props.onUploadImage) {
            props.onUploadImage(uploadedImage);
          }
        } catch (e) {
          console.error(e)
        }

        setDialogOpen(false);
    }, [croppedAreaPixels, droppedImage]);

    const handleUploadImage = () => {
      document.getElementById(dropzoneId).click();
    };

    return (
      <div className="app-upload-picture">
        <Dropzone 
          id={dropzoneId}
          onChange={handleImageDropped}
          contents={null}
          accept={accept}
          multiple={multiple}
          maxSize={maxSize}
          onDropRejected={onDropRejected}
        />
        <ButtonOutlined
              onClick={handleUploadImage}
          >
            {buttonText}
        </ButtonOutlined>
        <CropperDialog 
          dialogOpen={dialogOpen}
          image={droppedImage.image}
          aspect={aspect}
          onDialogClose={handleDialogClose}
          onCropComplete={handleCropComplete}
          onSaveImage={handleSaveImage}
        />
      </div>
    );
  }

export default UploadPicture;

