import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ButtonOutlined, TagsList } from '../index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import './ModalCarousel.scss';

function ModalCarousel(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isRequest = false;
    const {open, productIndex} = props;
    const [activeStepProduct, setActiveStepProduct] = useState(props.productIndex);
    const [activeStepImage, setActiveStepImage] = useState(0);

    useEffect(() => {
        setActiveStepProduct(productIndex);
        setActiveStepImage(0);
    }, [productIndex, open]);

    if(!open || !props.profileData || !props.catalogueData || props.catalogueData.length <= 0) {
        return null;
    }

    const profileBasics = props.profileData.basics;
    const catalogue = cloneDeep(props.catalogueData);
    const maxStepsProduct = catalogue.length;
    const activeProduct = catalogue[activeStepProduct];
    const maxStepsImage = activeProduct.images.length;
    const activeImage = activeProduct.images[activeStepImage];
    const activeImagePath = activeImage.path || activeImage.base64;
    const profileTags = activeProduct.tags || null;
    let buttonText = 'Contact Buyer';

    if(props.profileKind === 'S') {
        buttonText = 'Contact Seller';
    }

    const handleClose = () => {
        props.onClose();
    };

    const handleBookmark = () => {
        props.onBookmark(activeProduct);
    };

    const handleProductPrev = () => {
        if(activeStepProduct === 0) {
            setActiveStepProduct(maxStepsProduct - 1);
        } else {
            setActiveStepProduct((prevActiveStepProduct) => prevActiveStepProduct - 1);
        }

        setActiveStepImage(0);
    };

    const handleProductNext = () => {
        if(activeStepProduct === (maxStepsProduct - 1)) {
            setActiveStepProduct(0);
        } else {
            setActiveStepProduct((prevActiveStepProduct) => prevActiveStepProduct + 1);
        }

        setActiveStepImage(0);
    };

    const handleImagePrev = () => {
        if(activeStepImage === 0) {
            setActiveStepImage(maxStepsImage - 1);
        } else {
            setActiveStepImage((prevActiveStepImage) => prevActiveStepImage - 1);
        }
    };

    const handleImageNext = () => {
        if(activeStepImage === (maxStepsImage - 1)) {
            setActiveStepImage(0);
        } else {
            setActiveStepImage((prevActiveStepImage) => prevActiveStepImage + 1);
        }
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="products-carousel"
            classes={{ container: 'modal-carousel-container'}}
        >
            <Grid
                className="dialog-content"
                container
                direction="column"
                wrap="nowrap"
            >
                {
                    fullScreen ?
                    <Grid
                        className="header-container"
                        container
                        direction="row"
                        justify="flex-end"
                        item
                    >
                        <Fab 
                            aria-label="close"
                            onClick={handleClose}
                            disableFocusRipple={true}
                            disableRipple={true}
                        >
                            <CloseIcon />
                        </Fab>
                    </Grid> : null
                }        
                <Grid
                    className="content-container"
                    container
                    direction="row"
                    wrap="nowrap"
                    justify="center"
                    alignItems="center"
                    item
                    xs
                >
                    {/* <Grid
                        className="carousel-btn"
                        container
                        justify="center"
                        alignItems="center"
                        item
                        xs
                    >
                        <Fab
                            aria-label="left"
                            onClick={handleProductPrev}
                            disableFocusRipple={true}
                            disableRipple={true}
                        >
                            <ChevronLeftIcon />
                        </Fab>
                    </Grid> */}

                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        item
                        xs
                    >
                        <div className="product-container">
                            <Grid
                                className="product-container-inner"
                                container
                                direction="row"
                            >
                                <Grid
                                    className="product-image-section"
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Grid
                                        className="image-btn"
                                        container
                                        justify="center"
                                        alignItems="center"
                                        item
                                    >
                                        <Fab 
                                            aria-label="left"
                                            onClick={handleImagePrev}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <ChevronLeftIcon />
                                        </Fab>
                                    </Grid>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs
                                    >
                                        <img 
                                            src={activeImagePath} 
                                            alt="product" 
                                        />
                                        <MobileStepper
                                            steps={maxStepsImage}
                                            position="static"
                                            variant="dots"
                                            activeStep={activeStepImage}      
                                        />
                                    </Grid>
                                    <Grid
                                        className="image-btn"
                                        container
                                        justify="center"
                                        alignItems="center"
                                        item
                                    >
                                        <Fab 
                                            aria-label="right"
                                            onClick={handleImageNext}
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <ChevronRightIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className="product-details"
                                    container
                                    direction="column"
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Grid
                                        className="product-details-title"
                                        container
                                        direction="row"
                                        alignItems="center"
                                        item
                                    >
                                        {
                                            (profileBasics &&
                                                profileBasics.logo) ?
                                            <Avatar 
                                                alt="Profile" 
                                                src={
                                                    profileBasics.logo.path || 
                                                    profileBasics.logo.base64
                                                }
                                                className="toolbar-avatar"
                                            /> :
                                            <Avatar>
                                                {
                                                    profileBasics.firstName ? 
                                                    profileBasics.firstName.charAt(0).toUpperCase() : 
                                                    null
                                                }
                                            </Avatar>
                                        }
                                        <div>
                                            {profileBasics.companyName}
                                        </div>
                                    </Grid>
                                    <Grid 
                                        className="product-details-main"
                                        container
                                        direction="column"
                                        item
                                        xs
                                    >
                                        <Grid 
                                            className="details-main-title"
                                            item
                                        >
                                            {activeProduct.title}
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                        >
                                            {activeProduct.description}
                                        </Grid>
                                        {
                                            profileTags ?
                                            <Grid 
                                                item
                                                className="details-tags-container"
                                            >
                                                {
                                                    profileTags.map(tag => 
                                                        <div 
                                                            className="details-tag"
                                                            key={tag.id}
                                                        >
                                                            {tag.name}
                                                        </div>
                                                    )
                                                }
                                            </Grid> : null
                                        }
                                    </Grid>
                                    {
                                        props.pageType === 'view' ?
                                        <Grid
                                            className="product-details-btn"
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justify="space-between" 
                                            item
                                        >
                                            <div
                                                className={
                                                    activeProduct.bookmark ? 
                                                    'bookmarked' : ''
                                                }
                                            >
                                                <IconButton 
                                                    aria-label="bookmark"
                                                    disableFocusRipple={true}
                                                    disableRipple={true}
                                                    onClick={handleBookmark}
                                                >
                                                    {
                                                        activeProduct.bookmark ?
                                                        <BookmarkIcon /> :
                                                        <BookmarkOutlinedIcon />
                                                    }
                                                </IconButton>
                                            </div>   
                                            {(props.profileType !== 'BF' && props.userType !== 'BF') ?
                                                <ButtonOutlined
                                                    onClick={props.onContact}
                                                >
                                                    {buttonText}
                                                </ButtonOutlined> : null
                                            }                                            
                                        </Grid> : null
                                    }                           
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    
                    {/* <Grid
                        className="carousel-btn"
                        container
                        justify="center"
                        alignItems="center"
                        item
                        xs
                    >
                        <Fab 
                            aria-label="right"
                            onClick={handleProductNext}
                            disableFocusRipple={true}
                            disableRipple={true}
                        >
                            <ChevronRightIcon />
                        </Fab>
                    </Grid> */}
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default ModalCarousel;