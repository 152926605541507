import React from "react";
import { Card, Grid, Avatar, ButtonBase } from "@material-ui/core";
import { Button, ButtonInverted } from "../../_common/index";
import "./CardConnectionRequest.scss";
import CheckCircle from "@material-ui/icons/CheckCircle";

function CardConnectionRequest(props) {
  if (!props.cardData) {
    return null;
  }

  const cardData = props.cardData;
  const basics = cardData.basics;

  return (
    <div className="card-connection-request">
      <Card className="card-feed-container">
        <ButtonBase onClick={() => props.onClick(cardData)}>
          <Grid container className="card-header"></Grid>
          <Grid container direction="column" className="card-contents">
            <Grid container alignItems="flex-end" className="card-contents-top">
              {basics.logo && basics.logo.path ? (
                <Avatar
                  alt="Profile"
                  src={basics.logo.path}
                  className="card-avatar"
                />
              ) : (
                <Avatar
                  alt="Profile"
                  className="card-avatar card-avatar-letter"
                >
                  {basics.firstName
                    ? basics.firstName.charAt(0).toUpperCase()
                    : null}
                </Avatar>
              )}
              <div className="card-company">
                {basics.companyName} {basics.verified ? <CheckCircle /> : null}
              </div>
            </Grid>
            <Grid className="card-desc">{basics.description}</Grid>
            {basics.type ? (
              <Grid className="card-seller-info">
                <span className="card-type">{basics.type}</span>
                {basics.products ? (
                  <span>{" | " + basics.products}</span>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </ButtonBase>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className="card-footer"
          wrap="nowrap"
        >
          <ButtonInverted
            onClick={() => props.onIgnore(cardData)}
            disabled={props.isRequest}
          >
            {"Ignore"}
          </ButtonInverted>
          <Button
            onClick={() => props.onAccept(cardData)}
            disabled={props.isRequest}
          >
            {"Accept"}
          </Button>
        </Grid>
      </Card>
    </div>
  );
}

export default CardConnectionRequest;
