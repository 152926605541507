//Module Name
export const name = 'account';

// Actions
export const types = {
    SET_ACCOUNT_DATA: 'SET_ACCOUNT_DATA',
    SET_TAB_VALUE: 'SET_TAB_VALUE',
    SET_PAYMENT_DATA: 'SET_PAYMENT_DATA',
};

const initialState = {
    accountData: {},
    tabValue: 0,
    paymentData: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_ACCOUNT_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                accountData: content
            };
        }
        case types.SET_TAB_VALUE: {
            const { content } = action.payload;

            return {
                ...state,
                tabValue: content
            };
        }
        case types.SET_PAYMENT_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                paymentData: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setAccountData: content => ({
        type: types.SET_ACCOUNT_DATA,
        payload: {
            content
        }
    }),
    setTabValue: content => ({
        type: types.SET_TAB_VALUE,
        payload: {
            content
        }
    }),
    setPaymentData: content => ({
        type: types.SET_PAYMENT_DATA,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getAccountData: store => store[name].accountData,
    getTabValue: store => store[name].tabValue,
    getPaymentData: store => store[name].paymentData,
};