import React from 'react';
import './ErrorMessageComponent.scss';

function ErrorMessageComponent(props) {
    if(!props.showError) {
        return null;
    }

    return (
        <div className="error-message-container">
            {props.message}
        </div>
    );
}

export default ErrorMessageComponent;