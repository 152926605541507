import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { Grid, InputAdornment } from "@material-ui/core";
import {
  TextField,
  ErrorMessage,
  TitleHeading,
  ButtonBack,
  ButtonNext,
  Dropzone,
  Button,
  UploadValidator,
} from "../../_common/index";
import * as Yup from "yup";
import { Formik } from "formik";
import "./YourFactory.scss";
import getCroppedImg from "../../../functions/cropImage";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  buttonChooseFile: {
    background: "#FF6158;",
    color: "#ffffff;",
  },
});

function Form(props) {
  const {
    values: {
      revenue,
      monthlyCapacity,
      employeeFull,
      employeeSub,
      perStyle,
      perColour,
      fromRawMaterials,
      fromOrder,
    },
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    supplierType,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit} id="factory-form">
      <Grid container direction="column">
        <Grid container direction="row" className="form-container">
          <Grid item xs={12} md={12}>
            <TextField
              id="revenue"
              name="revenue"
              labelname="Yearly Revenue in USD"
              value={revenue}
              helperText={touched.revenue ? errors.revenue : ""}
              error={touched.revenue && Boolean(errors.revenue)}
              onChange={change.bind(null, "revenue")}
              placeholder="Yearly Revenue in US$ Dollars"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          {supplierType === "SG" ? (
            <Grid item xs={12} md={12}>
              <TextField
                id="monthlyCapacity"
                name="monthlyCapacity"
                labelname="Monthly Capacity (Pieces/Month)"
                value={monthlyCapacity}
                helperText={
                  touched.monthlyCapacity ? errors.monthlyCapacity : ""
                }
                error={
                  touched.monthlyCapacity && Boolean(errors.monthlyCapacity)
                }
                onChange={change.bind(null, "monthlyCapacity")}
                placeholder="Pieces"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">pieces</InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container direction="row" className="cb-title">
          <Grid item xs={12} sm={4}>
            Employees
          </Grid>
        </Grid>

        <Grid container direction="row" className="form-container" spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="employeeFull"
              name="employeeFull"
              labelname="Number of Full-Time"
              value={employeeFull}
              helperText={touched.employeeFull ? errors.employeeFull : ""}
              error={touched.employeeFull && Boolean(errors.employeeFull)}
              onChange={change.bind(null, "employeeFull")}
              placeholder="Number"
            />
          </Grid>
          {supplierType === "SG" ? (
            <Grid item xs={12} md={6}>
              <TextField
                id="employeeSub"
                name="employeeSub"
                labelname="Number of Subcontractors"
                value={employeeSub}
                helperText={touched.employeeSub ? errors.employeeSub : ""}
                error={touched.employeeSub && Boolean(errors.employeeSub)}
                onChange={change.bind(null, "employeeSub")}
                placeholder="Number"
              />
            </Grid>
          ) : null}
        </Grid>
        {supplierType === "SG" ? (
          <>
            <Grid container direction="row" className="cb-title">
              <Grid item xs={12} sm={6}>
                MOQ
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className="form-container"
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="perStyle"
                  name="perStyle"
                  labelname="Per Style"
                  value={perStyle}
                  helperText={touched.perStyle ? errors.perStyle : ""}
                  error={touched.perStyle && Boolean(errors.perStyle)}
                  onChange={change.bind(null, "perStyle")}
                  placeholder="Pieces"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="perColour"
                  name="perColour"
                  labelname="Per Colour"
                  value={perColour}
                  helperText={touched.perColour ? errors.perColour : ""}
                  error={touched.perColour && Boolean(errors.perColour)}
                  onChange={change.bind(null, "perColour")}
                  placeholder="Pieces"
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid container direction="row" className="cb-title">
          <Grid item xs={12} sm={6}>
            Lead Time
          </Grid>
        </Grid>
        <Grid container direction="row" className="form-container" spacing={2}>
          {supplierType === "SG" ? (
            <Grid item xs={12} md={6}>
              <TextField
                id="fromRawMaterials"
                name="fromRawMaterials"
                labelname="From Raw Material receipt"
                value={fromRawMaterials}
                helperText={
                  touched.fromRawMaterials ? errors.fromRawMaterials : ""
                }
                error={
                  touched.fromRawMaterials && Boolean(errors.fromRawMaterials)
                }
                onChange={change.bind(null, "fromRawMaterials")}
                placeholder="Number of days"
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <TextField
              id="fromOrder"
              name="fromOrder"
              labelname="From Order"
              value={fromOrder}
              helperText={touched.fromOrder ? errors.fromOrder : ""}
              error={touched.fromOrder && Boolean(errors.fromOrder)}
              onChange={change.bind(null, "fromOrder")}
              placeholder="Number of days"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

function YourFactory(props) {
  const initialValuesSG = {
    revenue: "",
    monthlyCapacity: "",
    employeeFull: "",
    employeeSub: "",
    perStyle: "",
    perColour: "",
    fromRawMaterials: "",
    fromOrder: "",
  };
  const initialValuesSI = {
    revenue: "",
    employeeFull: "",
    fromOrder: "",
  };
  const header = "Your Factory";
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [formValues, setFormValues] = useState(null);
  const supplierType = props.registerSellerData.supplier_type;
  let uploadedFileInit = null;

  if (
    props.registerSellerData["your_factory"] &&
    props.registerSellerData["your_factory"]["attached_file"]
  ) {
    uploadedFileInit = Object.assign(
      {},
      props.registerSellerData["your_factory"]["attached_file"]
    );
  }
  const [uploadedFile, setUploadedFile] = useState(uploadedFileInit);
  const [showFileError, setShowFileError] = useState(false);

  const integerError = "A whole number is required";
  const numberError = "A positive number is required";

  useEffect(() => {
    if (props.registerSellerData["your_factory"]) {
      const your_factory = props.registerSellerData["your_factory"];
      setFormValues({
        ...your_factory,
      });
    }

    window.scrollTo(0, 0);
  }, [props.registerSellerData]);

  if (!formValues) {
    switch (supplierType) {
      case "SG":
        setFormValues(initialValuesSG);
        break;
      case "SI":
        setFormValues(initialValuesSI);
        break;
      default:
        break;
    }
  }

  const yupObjectSG = {
    revenue: Yup.number("").min(0, numberError).typeError(numberError),
    monthlyCapacity: Yup.number("")
      .required("Monthly Capacity is required")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    employeeFull: Yup.number("")
      .required("Please state no. of full-time employees")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    employeeSub: Yup.number("").min(0, numberError).typeError(numberError),
    perStyle: Yup.number("")
      .required("Please state quantity per style")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    perColour: Yup.number("")
      .required("Please state quantity per colour")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    fromRawMaterials: Yup.number("")
      .required("Number of days for Raw Material is required")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    fromOrder: Yup.number("")
      .required("Number of days for order is required")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
  };
  const yupObjectSI = {
    revenue: Yup.number("").min(0, numberError).typeError(numberError),
    employeeFull: Yup.number("")
      .required("Please state no. of full-time employees")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
    fromOrder: Yup.number("")
      .required("Number of days for order is required")
      .integer(integerError)
      .min(0, numberError)
      .typeError(numberError),
  };

  let validationSchema = Yup.object(yupObjectSG);

  if (supplierType === "SI") {
    validationSchema = Yup.object(yupObjectSI);
  }

  const classes = useStyles();

  let dropzoneContent = (
    <div className="acount-user-imgwrapper">
      <div className="acount-user-img">
        <img
          src="/assets/images/icon-download.png"
          srcSet="/assets/images/icon-download.png 1x, /assets/images/icon-download@2x.png 2x"
          alt="abana"
        />
        <p>
          Drag and drop a file here (.pdf, .xls, .doc, .png) <br />
          or
        </p>
        <Button className={classes.buttonChooseFile}>Choose File</Button>
        <UploadValidator
          size={"5MB"}
          types={"PNG, JPEG, PDF, XLS, XLSX, DOC, DOCX"}
          showError={showFileError}
        />
      </div>
    </div>
  );

  const handleFileDropped = (file, base64) => {
    const result = {
      file: file,
      base64: base64,
    };

    setUploadedFile(result);
    setShowFileError(false);
  };

  const onDropRejected = () => {
    setShowFileError(true);
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  const onBackClicked = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleFormSubmit = (values, actions) => {
    const attached_file = uploadedFile || 0;

    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      your_factory: {
        ...values,
        attached_file,
      },
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  let fileName = "";
  let fileSize = "";

  if (uploadedFile) {
    fileName = uploadedFile["file"]["name"];
    fileSize = (parseInt(uploadedFile["file"]["size"]) / 1000).toFixed(2);
    fileSize = "(" + fileSize + " kB)";
  }

  return (
    <div className="factory-container-reg">
      <TitleHeading className="heading-container" title={header} />

      <ErrorMessage showError={showError} message={errorMsg} />
      <Formik
        children={(props) => <Form {...props} supplierType={supplierType} />}
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      />
      <div className="cb-title factory-upload-text">
        If you wish, upload any additional company profile (machinery etc.)
      </div>
      <div className="dropzone-outer dropzone-outer-wrapper">
        {uploadedFile ? (
          <div className="dropzone-uploaded-file">
            <div className="acount-user-imgwrapper">
              <div className="acount-user-img">
                <img
                  src="/assets/images/icon-download.png"
                  srcSet="/assets/images/icon-download.png 1x, /assets/images/icon-download@2x.png 2x"
                  alt="abana"
                />
                <p>{fileName}</p>
                <p>{fileSize}</p>
                <Button onClick={removeUploadedFile}>Remove File</Button>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone
            onChange={handleFileDropped}
            contents={dropzoneContent}
            accept={"image/jpeg, image/png, .pdf, .xls, .xlsx, .doc, .docx"}
            multiple={false}
            maxSize={5000000}
            onDropRejected={onDropRejected}
          />
        )}
      </div>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={onBackClicked} />
        <ButtonNext children="Next" type="submit" form="factory-form" />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(YourFactory);
