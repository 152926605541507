import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import './BreadCrumb.scss'

function BreadCrumb(props){
    return(
        <Breadcrumbs 
            separator={<NavigateNextIcon fontSize="small" />} 
            className="breadcrumbs-container"
        >
            <Link onClick={props.handleLinkSegementClick}>
                {props.linkSegement}
            </Link>
            <Link onClick={props.handleLinkCategoryClick}>
                {props.linkCategory}
            </Link>
            <Link>
                {props.productName}
            </Link>
        </Breadcrumbs>
    )
}

export default BreadCrumb;