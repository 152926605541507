import React from "react";
import NumberFormat from "react-number-format";
import Grid from "@material-ui/core/Grid";
import { BorderedContent } from "../../../_common/index";
import { BuyerUpgrade } from "../index";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { isObject } from "../../../../functions/check";
import "./SellerSections.scss";

function SellerSections(props) {
  const sectionType = props.profileType.slice(0, 2).toUpperCase();
  let sectionsList = {};

  const ItemsSection = (props) => {
    return props.list.length > 0 ? (
      <Grid className="items-section-container" item {...props.parentGrid}>
        <div className="section-title">{props.title}</div>
        <Grid container direction="row">
          {props.list.map((item, index) => (
            <Grid
              className="section-item"
              container
              key={index}
              direction="row"
              wrap="nowrap"
              alignItems="center"
              item
              {...props.childGrid}
            >
              {props.hideCheck ? null : <CheckCircleIcon />}
              <div className="section-item-value">{item.value}</div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    ) : null;
  };

  const AttachedFile = (props) => {
    const attachedFile = props.data;

    const handleDownload = () => {
      const filePath = attachedFile["path"] || attachedFile["base64"];

      if (filePath) {
        let a = document.createElement("a");
        a.href = filePath;
        a.download = attachedFile["name"];
        a.target = "_blank";
        a.click();
      }
    };

    return attachedFile ? (
      <Grid
        className="attached-file-container"
        container
        item
        {...props.parentGrid}
        direction="column"
      >
        <div className="attached-file-title">{props.title}</div>
        <Grid container>
          <div className="attached-file-content" onClick={handleDownload}>
            <BorderedContent
              text={attachedFile["name"]}
              before={
                <div className="attached-file-icon">
                  <AttachFileIcon />
                </div>
              }
            />
          </div>
        </Grid>
      </Grid>
    ) : null;
  };

  const getItemsList = (dataList, tagsList) => {
    let results = [];

    if (!dataList || !tagsList) {
      return results;
    }

    function getItems(property, value) {
      if (isObject(value)) {
        for (let prop in value) {
          getItems(prop, value[prop]);
        }
      } else {
        if (value) {
          const tagName = tagsList[property];

          if (tagName) {
            results.push({
              value: tagName,
            });
          }

          if (property === "other") {
            results.push({
              value: value,
            });
          }
        }
      }
    }
    getItems(null, dataList);

    return results;
  };

  const getSegmentsList = (dataList, tagsList) => {
    let results = [];

    if (!dataList || !tagsList) {
      return results;
    }

    for (let prop in dataList) {
      if (dataList[prop]["checked"]) {
        const tagName = tagsList[prop];

        if (tagName) {
          const value =
            tagName + " " + "(" + dataList[prop]["percentage"] + "%)";
          results.push({ value });
        }
      }
    }

    return results;
  };

  const getRevenue = (dataList) => {
    let results = [];

    if (!dataList) {
      return results;
    }

    if (dataList["revenue"]) {
      results.push({
        value: (
          <NumberFormat
            value={dataList["revenue"]}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        ),
      });
    }

    return results;
  };

  const getMonthlyCapacity = (dataList) => {
    let results = [];

    if (!dataList) {
      return results;
    }

    if (dataList["monthlyCapacity"]) {
      const value = (
        <div>
          <NumberFormat
            value={dataList["monthlyCapacity"]}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          {dataList["monthlyCapacity"] == 1 ? (
            <span> piece</span>
          ) : (
            <span> pieces</span>
          )}
        </div>
      );
      results.push({ value });
    }

    return results;
  };

  const getMOQ = (dataList) => {
    let results = [];

    if (!dataList) {
      return results;
    }

    if (dataList["perStyle"]) {
      results.push({
        value: dataList["perStyle"] + " Pieces per Style",
      });
    }

    if (dataList["perColour"]) {
      results.push({
        value: dataList["perColour"] + " Pieces per Colour",
      });
    }

    return results;
  };

  const getEmployees = (dataList) => {
    let results = [];

    if (!dataList) {
      return results;
    }

    if (dataList["employeeFull"]) {
      results.push({
        value: "Fulltime: " + dataList["employeeFull"],
      });
    }

    if (dataList["employeeSub"]) {
      results.push({
        value: "SubContractors: " + dataList["employeeSub"],
      });
    }

    return results;
  };

  const getLeadTime = (dataList) => {
    let results = [];
    let value;

    if (!dataList) {
      return results;
    }

    if (dataList["fromRawMaterials"]) {
      value = dataList["fromRawMaterials"];

      results.push({
        value:
          "Raw Material receipt: " + value + (value == 1 ? " day" : " days"),
      });
    }

    if (dataList["fromOrder"]) {
      value = dataList["fromOrder"];

      results.push({
        value: "Order Receipt: " + value + (value == 1 ? " day" : " days"),
      });
    }

    return results;
  };

  const getCustomers = (dataList) => {
    let results = [];

    if (!dataList) {
      return results;
    }

    for (let prop in dataList) {
      if (dataList[prop]) {
        results.push({
          value: dataList[prop],
        });
      }
    }

    return results;
  };

  const getMainMarkets = (dataList, tagsList) => {
    let results = [];
    let value = "";

    if (!dataList || !tagsList) {
      return results;
    }

    const itemsList = getItemsList(dataList, tagsList);

    itemsList.forEach((item) => {
      if (!value) {
        value = item["value"];
      } else {
        value = value + ", " + item["value"];
      }
    });

    if (value) {
      results.push({ value });
    }

    return results;
  };

  const getGauges = (dataList, tagsList) => {
    let results = [];

    if (!dataList || !tagsList) {
      return results;
    }

    for (let prop in dataList) {
      let sectionName;
      if (tagsList[prop]) {
        const section = tagsList[prop];
        sectionName = section + ": ";
        let values;

        for (let tag in dataList[prop]) {
          if (dataList[prop][tag] && tagsList[tag]) {
            if (!values) {
              values = tagsList[tag];
            } else {
              values += "," + tagsList[tag];
            }
          }
        }

        if (values) {
          results.push({
            value: sectionName + values,
          });
        }
      }
    }

    return results;
  };

  const getAttachedFile = (dataList) => {
    return dataList ? dataList["attached_file"] || null : null;
  };

  const segmentsData = props.dataList["segments"];
  const productsData = props.dataList["products"];
  const materialsData = props.dataList["main_materials"];
  const productionData = props.dataList["production_capabilities"];
  const yourFactoryData = props.dataList["your_factory"];
  const uspData = props.dataList["unique_selling_points"];
  const gaugesData = props.dataList["gauges"];
  const mainMarketsData = props.dataList["main_markets"];
  const certificationsData = props.dataList["certifications"];
  const servicesData = props.dataList["services"];
  const marketsData = props.dataList["market"];
  const addInfoData = props.dataList["add_info"];

  if (!props.advanced) {
    switch (sectionType) {
      case "SG":
        sectionsList["segments"] = getSegmentsList(
          segmentsData,
          props.tagsNameList
        );
        sectionsList["products"] = getItemsList(
          productsData,
          props.tagsNameList
        );
        sectionsList["main_materials"] = getItemsList(
          materialsData,
          props.tagsNameList
        );
        sectionsList["production_capabilities"] = getItemsList(
          productionData,
          props.tagsNameList
        );
        sectionsList["moq"] = getMOQ(yourFactoryData);
        sectionsList["unique_selling_points"] = getItemsList(
          uspData,
          props.tagsNameList
        );

        break;
      case "SI":
        sectionsList["products"] = getItemsList(
          productsData,
          props.tagsNameList
        );
        sectionsList["main_materials"] = getItemsList(
          materialsData,
          props.tagsNameList
        );
        sectionsList["production_capabilities"] = getItemsList(
          productionData,
          props.tagsNameList
        );
        sectionsList["moq"] = getMOQ(yourFactoryData);
        sectionsList["unique_selling_points"] = getItemsList(
          uspData,
          props.tagsNameList
        );
        break;
      case "SS":
        sectionsList["services"] = getItemsList(
          servicesData,
          props.tagsNameList
        );
        sectionsList["markets"] = getItemsList(marketsData, props.tagsNameList);
        sectionsList["attached_file"] = getAttachedFile(addInfoData);
        break;
      default:
        break;
    }
  }

  if (props.advanced) {
    if (props.userKind === "B" && props.userSubs < 2) {
      sectionsList["buyer_upgrade"] = true;
    } else {
      switch (sectionType) {
        case "SG":
          sectionsList["revenue"] = getRevenue(yourFactoryData);
          sectionsList["monthly_capacity"] =
            getMonthlyCapacity(yourFactoryData);
          sectionsList["main_markets"] = getMainMarkets(
            mainMarketsData,
            props.tagsNameList
          );
          sectionsList["customers"] = mainMarketsData
            ? getCustomers(mainMarketsData["keyCustomers"])
            : null;
          sectionsList["certifications"] = getItemsList(
            certificationsData,
            props.tagsNameList
          );
          sectionsList["gauges"] = getGauges(gaugesData, props.tagsNameList);
          sectionsList["employees"] = getEmployees(yourFactoryData);
          sectionsList["lead_time"] = getLeadTime(yourFactoryData);
          sectionsList["attached_file"] = getAttachedFile(yourFactoryData);
          break;
        case "SI":
          sectionsList["revenue"] = getRevenue(yourFactoryData);
          sectionsList["monthly_capacity"] =
            getMonthlyCapacity(yourFactoryData);
          sectionsList["main_markets"] = getMainMarkets(
            mainMarketsData,
            props.tagsNameList
          );
          sectionsList["customers"] = mainMarketsData
            ? getCustomers(mainMarketsData["keyCustomers"])
            : null;
          sectionsList["certifications"] = getItemsList(
            certificationsData,
            props.tagsNameList
          );
          sectionsList["employees"] = getEmployees(yourFactoryData);
          sectionsList["lead_time"] = getLeadTime(yourFactoryData);
          sectionsList["attached_file"] = getAttachedFile(yourFactoryData);
          break;
        case "SS":
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="seller-basics-container">
      {!props.advanced ? (
        <Grid container>
          {sectionsList["segments"] ? (
            <ItemsSection
              title="Segments"
              list={sectionsList["segments"]}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            />
          ) : null}
          {sectionsList["products"] ? (
            <ItemsSection
              title="Products"
              list={sectionsList["products"]}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
              childGrid={{
                xs: 12,
                sm: 6,
              }}
            />
          ) : null}
          {sectionsList["main_materials"] ? (
            <ItemsSection
              title="Materials"
              list={sectionsList["main_materials"]}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
              childGrid={{
                xs: 12,
                sm: 6,
              }}
            />
          ) : null}
          {sectionsList["production_capabilities"] ? (
            <ItemsSection
              title="Production Capacity"
              list={sectionsList["production_capabilities"]}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            />
          ) : null}
          {sectionsList["moq"] ? (
            <ItemsSection
              title="Minimum Order Quantity"
              list={sectionsList["moq"]}
              hideCheck={true}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            />
          ) : null}
          {sectionsList["unique_selling_points"] ? (
            <ItemsSection
              title="Special Attributes"
              list={sectionsList["unique_selling_points"]}
              parentGrid={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            />
          ) : null}
          {sectionsList["services"] ? (
            <ItemsSection
              title="Services"
              list={sectionsList["services"]}
              parentGrid={{
                xs: 12,
                sm: 6,
              }}
            />
          ) : null}
          {sectionsList["markets"] ? (
            <ItemsSection
              title="Markets"
              list={sectionsList["markets"]}
              parentGrid={{
                xs: 12,
                sm: 6,
              }}
            />
          ) : null}
          {sectionsList["attached_file"] ? (
            <AttachedFile
              title={
                sectionType === "SS"
                  ? "Extra Information"
                  : "Download more company data:"
              }
              data={sectionsList["attached_file"]}
              parentGrid={{
                xs: 12,
              }}
            />
          ) : null}
        </Grid>
      ) : sectionsList["buyer_upgrade"] ? (
        <BuyerUpgrade />
      ) : (
        <>
          <Grid container>
            {sectionsList["revenue"] ? (
              <ItemsSection
                title="Revenue"
                list={sectionsList["revenue"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["monthly_capacity"] ? (
              <ItemsSection
                title="Monthly Capacity"
                list={sectionsList["monthly_capacity"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["main_markets"] ? (
              <ItemsSection
                title="Main Markets"
                list={sectionsList["main_markets"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["customers"] ? (
              <ItemsSection
                title="Customer Examples"
                list={sectionsList["customers"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                }}
                childGrid={{
                  xs: 12,
                  sm: 6,
                  md: true,
                }}
              />
            ) : null}
            {sectionsList["certifications"] ? (
              <ItemsSection
                title="Compliance"
                list={sectionsList["certifications"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                }}
                childGrid={{
                  xs: 12,
                  sm: 6,
                  md: true,
                }}
              />
            ) : null}
            {sectionsList["gauges"] ? (
              <ItemsSection
                title={props.tagsNameList["sg_gauges"]}
                list={sectionsList["gauges"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["employees"] ? (
              <ItemsSection
                title="Employees"
                list={sectionsList["employees"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["lead_time"] ? (
              <ItemsSection
                title="Lead Time"
                list={sectionsList["lead_time"]}
                hideCheck={true}
                parentGrid={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                }}
              />
            ) : null}
            {sectionsList["attached_file"] ? (
              <AttachedFile
                title={
                  sectionType === "SS"
                    ? "Extra Information"
                    : "Download more company data:"
                }
                data={sectionsList["attached_file"]}
                parentGrid={{
                  xs: 12,
                }}
              />
            ) : null}
          </Grid>
        </>
      )}
    </div>
  );
}

export default SellerSections;
