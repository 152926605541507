import { TextField } from "@material-ui/core";
import React from "react";

const INPUT_COUNT = 300;

const DialogConnectionBuyer = ({ message, setMessage }) => {
  const handleChange = (e) => {
    setMessage(e.target.value.slice(0, INPUT_COUNT));
  };

  return (
    <form>
      <p>
        Buyers are more likely to connect with sellers that provide information
        about the purpose of their request
      </p>
      <TextField
        variant="outlined"
        placeholder="Example: Hey! I would like to connect with you to discuss manufacturing possiblilities."
        onChange={handleChange}
        value={message}
        multiline
        rows={5}
        maxLength={INPUT_COUNT}
      />
      <p className="counter">
        <span>
          {message.length}/{INPUT_COUNT}
        </span>
      </p>
    </form>
  );
};

export default DialogConnectionBuyer;
