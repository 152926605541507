import React, { useState } from "react";
import { connect } from "react-redux";
import { selectors as authSelectors } from "../../redux/modules/auth";
import { actions } from "../../redux/modules/auth";
import { Redirect, Link } from "react-router-dom";
import { AccessLayout } from "../index";
import {
  Button,
  TextField,
  Checkbox,
  AccessHeading,
  SignUpNow,
  AreYouASeller,
  ErrorMessage,
} from "../_common/index";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Formik } from "formik";
import * as Yup from "yup";
import { stringToSha256 } from "../../functions/converters";
import { errorMessages } from "../../constants/messages";
import { localStorageName } from "../../constants/auth";
import authService from "../../services/authService";
import "./Login.scss";

function Form(props) {
  const {
    values: { email, password },
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isRequest,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleRememberMe = (event) => {
    props.onRememberMeChange(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <TextField
          id="email"
          name="email"
          labelname="Email Address"
          value={email}
          helperText={touched.email ? errors.email : ""}
          error={touched.email && Boolean(errors.email)}
          onChange={change.bind(null, "email")}
        />
        <TextField
          id="password"
          name="password"
          labelname="Password"
          type="password"
          value={password}
          helperText={touched.password ? errors.password : ""}
          error={touched.password && Boolean(errors.password)}
          onChange={change.bind(null, "password")}
        />
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          className="login-form-inner"
        >
          <Grid item xs={6} className="login-form-remember">
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.rememberMe}
                  onChange={handleRememberMe}
                  inputProps={{ "aria-label": "remember me" }}
                />
              }
              label="Remember Me"
            />
          </Grid>
          <Grid item xs={6} className="login-form-pw-forgotten">
            <Link to="/password-forgotten">Forgot Password?</Link>
          </Grid>
        </Grid>
        <Button type="submit" className="button-md" disabled={isRequest}>
          Log In
        </Button>
      </Grid>
    </form>
  );
}

function Login(props) {
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessages.invalidLogin);
  const [rememberMe, setRememberMe] = useState(true);
  const [isRequest, setIsRequest] = useState(false);

  const values = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email").required("Email is required"),
    password: Yup.string("").required("Password is required"),
  });

  const accessHeading = "Log In to your existing Account";

  const handleRememberMe = (value) => {
    setRememberMe(value);
  };

  const handleFormSubmit = (values, actions) => {
    const data = Object.assign(
      {},
      {
        email: values.email,
        password: stringToSha256(values.password),
      }
    );

    setShowError(false);
    setIsRequest(true);

    authService
      .login(data)
      .then(function (response) {
        if (response && response.status === 200) {
          const authData = response.data;
          props.setAuth(authData);

          if (rememberMe) {
            localStorage.setItem(localStorageName, JSON.stringify(authData));
          } else {
            sessionStorage.setItem(localStorageName, JSON.stringify(authData));
          }

          props.history.push(props.referer ? props.referer : "/auth/home");
        } else {
          setIsRequest(false);
        }
      })
      .catch(function (error) {
        setIsRequest(false);

        if (error.response && error.response.status === 401) {
          setErrorMsg(errorMessages.invalidLogin);
        } else if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.code == 403
        ) {
          setErrorMsg(errorMessages.bankTransferBlocked);
        } else {
          setErrorMsg(errorMessages.errorApi);
        }

        setShowError(true);
      });
  };

  if (props.auth) {
    return <Redirect to="/auth/home" />;
  }

  return (
    <AccessLayout>
      <Grid container direction="column">
        <AccessHeading heading={accessHeading} />
        <ErrorMessage showError={showError} message={errorMsg} />
        <Formik
          children={(props) => (
            <Form
              {...props}
              onRememberMeChange={handleRememberMe}
              rememberMe={rememberMe}
              isRequest={isRequest}
            />
          )}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        />
        <SignUpNow />
        <AreYouASeller />
      </Grid>
    </AccessLayout>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    referer: authSelectors.getReferer(state),
  }),
  { setAuth: actions.setAuth }
)(Login);
