import React from "react";
import {
  Card,
  Grid,
  Avatar,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  ButtonBase,
} from "@material-ui/core";
import { Chip, Button, ButtonOutlined } from "../../_common/index";
import { getCountryNameByCode } from "../../../functions/converters";
import { getSizeRatio, getUserKind } from "../../../functions/compute";
import CountryRegionData from "country-region-data";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import ReactHtmlParser from "react-html-parser";
import "./CardFeed.scss";
import CheckCircle from "@material-ui/icons/CheckCircle";

const dateFormat = require("dateformat");

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function Bookmark(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<BookmarkOutlinedIcon fontSize="small" />}
          checkedIcon={<BookmarkIcon fontSize="small" />}
          name="bookmark"
          checked={props.checked}
          onChange={props.onChange}
        />
      }
    />
  );
}

function CardFeed(props) {
  const dataList = props.dataList;
  const createdDate = dataList.createdDate
    ? dateFormat(dataList.createdDate, "d mmmm yyyy")
    : null;
  const image1 = dataList.basics.image1 ? dataList.basics.image1.path : null;
  const tags = dataList.basics.tags || null;
  const verified = dataList.verified;

  let avatar = dataList.logo ? dataList.logo.path : null;
  let title =
    dataList.title || dataList.companyName || dataList.basics.title || null;
  let description = dataList.basics.description || null;
  let image2, file, infoList;
  let section2 = null;
  let btnText = props.btnText;
  let type = dataList.type;

  let tagLine = "";

  if (type === "rfi") {
    tagLine = "is looking for";
  }

  if (type === "listing") {
    tagLine = dataList?.basics?.requestType || "can supply";
  }

  if (dataList.details) {
    image2 = dataList.details.image2 ? dataList.details.image2.path : null;
    file = dataList.details.file ? dataList.details.file : null;
    infoList = [
      { name: "Colours", value: dataList.basics.colours || null },
      {
        name: "MOQ",
        value: dataList.basics.order_quantity
          ? dataList.basics.order_quantity + " pieces"
          : null,
      },
      {
        name: "Size Ratio",
        value: dataList.details.size_ratio
          ? getSizeRatio(dataList.details.size_ratio)
          : null,
      },
      {
        name: "Packaging",
        value:
          dataList.details.other_packaging ||
          capitalize(dataList.details.packaging) ||
          null,
      },
      {
        name: "Delivery Country",
        value:
          getCountryNameByCode(CountryRegionData, dataList.terms.country) ||
          null,
      },
      {
        name: "Delivery Date",
        value: dataList.terms.delivery_date
          ? dateFormat(dataList.terms.delivery_date, "d mmmm yyyy")
          : null,
      },
      {
        name: "Price Terms",
        value:
          dataList.terms.other_price_terms ||
          dataList.terms.price_terms ||
          null,
      },
    ];
  }

  if (dataList.anonymous || props.userType === "BF") {
    if (
      dataList.type === "rfi" ||
      dataList.type === "rfq" ||
      dataList.type === "listing"
    ) {
      avatar = "/assets/images/background_grey.png";
    }

    if (dataList.type === "rfi") {
      title = "A Buyer's RFI";
    }

    if (dataList.type === "rfq") {
      title = "A Buyer's RFQ";
    }

    if (dataList.type === "listing") {
      title = "A Supplier’s Listing";
    }

    if (props.userType === "BF") {
      if (dataList.type === "listing" || dataList.type === "supplier") {
        // if(description) {
        //     description += '...';
        //     description = <span>{description} <a className="upgrade-txt">Upgrade to see more</a></span>;
        // }

        btnText =
          dataList.type === "supplier"
            ? "Upgrade to see"
            : "Upgrade to contact";
      }
    }
  }

  if (props.userKind === "S" && props.subsLevel == 0) {
    if (dataList.type === "listing" || dataList.type === "rfi") {
      btnText = "Upgrade to contact";
    }
    if (dataList.type === "customer") {
      btnText = "Upgrade to see";
    }

    if (dataList.type === "rfq") {
      btnText = "Upgrade to respond";
    }
  }

  if (props.userKind === "S" && props.subsLevel == 1) {
    if (dataList.type === "rfq") {
      btnText = "Upgrade to respond";
    }
  }

  if (image2) {
    section2 = <img className="card-image" src={image2} alt="Product" />;
  } else if (file) {
    section2 = (
      <div className="card-attachment">
        <img
          className="attachment-image"
          src={"/assets/images/attachment@2x.png"}
          alt="Product"
        />
        <span className="attachment-text">{file.name}</span>
      </div>
    );
  } else {
    if (dataList.details) {
      section2 = (
        <img
          className="card-image"
          src={"/assets/images/default-1.png"}
          alt="Product"
        />
      );
    }
  }

  const handleBookmarkChange = (event) => {
    props.onBookmarkChange(dataList, event.target.checked);
  };

  return (
    <div
      className={
        "card-feed-component" + (dataList.isAbana ? " card-feed-highlight" : "")
      }
    >
      <div className="ribbon">
        <span>
          <img src="/assets/images/logo-inverted.svg" alt="abana" />
        </span>
      </div>
      <Card className="card-feed-container">
        <ButtonBase onClick={props.onClick}>
          <Grid
            container
            className="card-feed-main"
            direction="row"
            wrap="nowrap"
          >
            {image1 ? (
              <Grid
                className={
                  type === "report"
                    ? "card-image-report-container"
                    : "card-image-container"
                }
                container
                direction="column"
                item
                xs={4}
                s={3}
              >
                <div className="card-image-content">
                  <img className="card-image" src={image1} alt="Product" />
                </div>
                {section2 ? (
                  <div className="card-image-content">{section2}</div>
                ) : null}
              </Grid>
            ) : null}

            <Grid
              className="card-content-container"
              container
              direction="column"
              wrap="nowrap"
              item
              xs={image1 ? 8 : 12}
              s={image1 ? 9 : 12}
            >
              <Grid
                container
                item
                className="card-feed-header"
                direction="row"
                alignItems="center"
                wrap="nowrap"
              >
                {avatar ? (
                  <Avatar
                    alt="Profile"
                    src={avatar}
                    className="toolbar-avatar"
                  />
                ) : null}
                {type === "supplier" || type === "customer" ? (
                  <div className="annoncement">
                    <div>New {capitalize(type)} Announcement:</div>
                    <div className="company-name-title company-name">
                      {title}
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      avatar
                        ? "company-name"
                        : "company-name-title company-name"
                    }
                  >
                    <div className="company-title">
                      {title}{" "}
                      {verified && !dataList.anonymous ? <CheckCircle /> : null}
                    </div>
                    {tagLine && <p className="tagline-company">{tagLine}</p>}
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs
                className={
                  type === "report" ||
                  type === "supplier" ||
                  type === "customer"
                    ? "card-description-report"
                    : "card-description"
                }
              >
                <div>{ReactHtmlParser(description)}</div>
              </Grid>

              <Grid item className="card-tags-description">
                {infoList &&
                  infoList.map((item, index) =>
                    item.value ? (
                      <div key={index} className="card-tags">
                        <span className="tags-name">{item.name}:</span>
                        {" " + item.value}
                      </div>
                    ) : null
                  )}
              </Grid>

              {tags ? (
                <Grid item className="card-tags-container">
                  {tags &&
                    tags.map((item) => (
                      <Chip key={item.code} label={item.name} />
                    ))}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </ButtonBase>
        <Grid container className="card-feed-footer">
          <div className="date-section">{createdDate}</div>
          <div>
            <Bookmark
              checked={dataList.bookmark}
              onChange={handleBookmarkChange}
            />
            <ButtonOutlined
              onClick={props.onBtnClicked}
              disabled={dataList?.contact ? true : props.isRequest}
            >
              {props.isRequest ? (
                <span className="dl-container">
                  <span className="dl-text">Processing</span>
                  <CircularProgress size={18} />
                </span>
              ) : (
                <>{btnText}</>
              )}
            </ButtonOutlined>
          </div>
        </Grid>
      </Card>
    </div>
  );
}

export default CardFeed;
