//Module Name
export const name = 'database';

// Actions
export const types = {
    SET_FILTERS_DATA: 'SET_FILTERS_DATA',
    SET_DATABASE_DATA: 'SET_DATABASE_DATA',
    SET_UPGRADE_DATA: 'SET_UPGRADE_DATA',
};

const initialState = {
    filtersData: null,
    databaseData: null,
    upgradeData: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_FILTERS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                filtersData: content
            };
        }
        case types.SET_DATABASE_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                databaseData: content
            };
        }
        case types.SET_UPGRADE_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                upgradeData: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setFiltersData: content => ({
        type: types.SET_FILTERS_DATA,
        payload: {
            content
        }
    }),
    setDatabaseData: content => ({
        type: types.SET_DATABASE_DATA,
        payload: {
            content
        }
    }),
    setUpgradeData: content => ({
        type: types.SET_UPGRADE_DATA,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getFiltersData: store => store[name].filtersData,
    getDatabaseData: store => store[name].databaseData,
    getUpgradeData: store => store[name].upgradeData,
};