import React from 'react';

function IFrame(props) {
    let iframe_ref = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframe_ref = frame;
        let doc = frame.contentDocument;
        doc.open();
        doc.write(props.content);
        doc.close();
        frame.style.width = '100%';
        frame.style.height = props.height || `${frame.contentWindow.document.body.scrollHeight + 50}px`;

        var style = document.createElement('style');
        style.textContent =
            'body {' +
            "margin: 0px;" +
            "font-family: 'Source Sans Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';" +
            "font-size: 16px;" + 
            "color: #4D4F5C;" +
            '}' 
        ;
        frame.contentDocument.head.appendChild(style);
    };

    return (
        <iframe 
            className="iframe-container"
            src="about:blank" scrolling="no" 
            frameBorder="0" ref={writeHTML} 
        />
    );
}

export default IFrame;