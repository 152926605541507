import React from 'react';
import Button from '@material-ui/core/Button';
import './ButtonOutlined.scss';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    buttons: {
        border: "1px solid #FF6158",
        color: '#FF6158;'
    }
})

function ButtonOutlined(props) {
    const classes = useStyles();

    return (
        <Button
            variant="outlined"
            disableRipple={true}
            disableElevation
            {...props}
            className={classes.buttons}
        >
            {props.children}
        </Button>
    );
}

export default ButtonOutlined;
