import React from 'react';
import Button from '@material-ui/core/Button';
import './BorderedContent.scss';

function BorderedContent(props) {
    return (
        <div className="bordered-content">
            {
                props.children ?
                props.children :
                <>
                    {props.before || null}
                    <span className="bordered-content-text">
                        {props.text}
                    </span>
                    {props.after || null}
                </>
            }
        </div>
    );
}

export default BorderedContent;