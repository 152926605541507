import React from "react";
import {
  Card,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { ButtonOutlined } from "../../_common/index";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import "./CardReports.scss";

const dateFormat = require("dateformat");

function Bookmark(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<BookmarkOutlinedIcon fontSize="small" />}
          checkedIcon={<BookmarkIcon fontSize="small" />}
          name="bookmark"
          checked={props.checked}
          onChange={props.onChange}
        />
      }
    />
  );
}

function CardReports(props) {
  const dataList = props.data;

  const image = dataList.basics.image1 ? dataList.basics.image1.path : null;
  const description =
    dataList.basics.description.length > 150
      ? dataList.basics.description.substring(0, 150) + "..."
      : dataList.basics.description;
  const createdDate = dataList.createdDate
    ? dateFormat(dataList.createdDate, "d mmmm yyyy")
    : null;

  const handleBookmarkChange = (event) => {
    props.onBookmarkChange(dataList, event.target.checked);
  };

  const handleDownload = (event) => {
    props.onDownload(dataList, event);
  };

  return (
    <div className="card-reports-container">
      <Card className="card-container">
        <Grid container direction="row" className="card-main">
          {image ? (
            <Grid item className="card-image-container">
              <img className="card-image" src={image} alt="listing" />
            </Grid>
          ) : null}

          <Grid
            item
            className="card-content"
            container
            direction="column"
            wrap="nowrap"
          >
            <div className="report-title">{dataList.basics.title}</div>

            <div className="report-description">{description}</div>
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="card-footer"
        >
          <div className="date-section">{createdDate}</div>

          <div className="button-footer">
            <Bookmark
              checked={dataList.bookmark}
              onChange={handleBookmarkChange}
            />

            <ButtonOutlined onClick={handleDownload} disabled={props.isRequest}>
              {props.isRequest ? (
                <span className="dl-container">
                  <span className="dl-text">Downloading</span>
                  <CircularProgress size={18} />
                </span>
              ) : (
                "See Report"
              )}
            </ButtonOutlined>
          </div>
        </Grid>
      </Card>
    </div>
  );
}

export default CardReports;
