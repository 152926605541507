import React from 'react';
import { 
    TitleHeading, GroupCheckboxGrid, 
    TextFieldOther 
} from '../../../_common/index';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from "formik";
import './MainMarkets.scss';

function Form(props) {
    const {
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched,
        checkboxNaming,
        checkboxValues,
        tagsList,
        keyCustomers,
        values
    } = props;

    const inputChange = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.map((item) =>
                    <div key={item.code}>
                        <GroupCheckboxGrid
                            naming={checkboxNaming}
                            title={item.name}
                            name={item.code}
                            checkboxList={item.children}
                            values={checkboxValues[item.code]}
                            onChange={props.onCheckboxChange}
                            md={4}
                        />

                        <div className='keyCustomers-container'>
                            <TitleHeading title={'Key Customers'}/>
                            {
                                keyCustomers.map((customer, index)=>
                                    <Grid 
                                        key={customer['key']}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <div className="keyCustomers-numbers">
                                            {index+1}.
                                        </div>
                                        <TextFieldOther 
                                            placeholder=''
                                            id={customer['key']}
                                            name={customer['key']} 
                                            value={values[customer['key']]}
                                            helperText={touched[customer['key']] ? errors[customer['key']] : ''}
                                            error={touched[customer['key']] && Boolean(errors[customer['key']])}
                                            onChange={inputChange.bind(null, customer['key'])}
                                        />  
                                    </Grid> 
                                )
                            }
                        </div>
                    </div>
                )
            }
        </form>
    );
}

function MainMarkets(props){
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const keyCustomersValues = dataList['keyCustomers'] || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    let keyCustomersInit = {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
    };
    let keyCustomers = [];
    let yupObject = {};

    for(let property in keyCustomersInit) {
        keyCustomersInit[property] = keyCustomersValues[property] || '';
        keyCustomers.push({
            key: property
        });
        yupObject[property] = Yup.string('');
    }

    const [checkboxValues, setCheckboxValues] = React.useState(dataList);
    const validationSchema = Yup.object(yupObject);

    const handleCheckboxChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name][target.name] = target.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const handleFormSubmit = (values) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues['keyCustomers'] = values;
        props.onSubmit(newCheckboxValues);
    }

    if(tagsList.length === 0 || Object.keys(checkboxValues).length === 0) { 
        return null;
    }
   
    return(
        <div className='mainMarket-container'>
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                        keyCustomers={keyCustomers}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={keyCustomersInit}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
        </div>
    )
}

export default MainMarkets;