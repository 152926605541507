import React from 'react';
import { 
  ButtonOutlined, Dropzone
} from '../index';
import './UploadFile.scss';


function UploadFile(props) {
    const dropzoneId = props.id || 'dropzone-file';
    const accept = props.accept || 'image/jpeg, image/png, .pdf, .xls, .xlsx, .doc, .docx';
    const multiple = props.multiple || false;
    const maxSize = props.maxSize || 5000000;
    const buttonText = props.buttonText || 'Upload';

    const handleImageDropped = (file, base64) => {
      const uploadedFile = {
        name: file['name'],
        type: file['type'],
        size: file['size'],
        base64: base64
      };

      props.onUploadImage(uploadedFile);
      props.onFileError(false);
    };

    const onDropRejected = () => {
      props.onFileError(true);
    };

    const handleUploadImage = () => {
      document.getElementById(dropzoneId).click();
    };

    return (
      <div className="app-upload-file">
        <Dropzone 
          id={dropzoneId}
          onChange={handleImageDropped}
          contents={null}
          accept={accept}
          multiple={multiple}
          maxSize={maxSize}
          onDropRejected={onDropRejected}
        />
        <ButtonOutlined
              onClick={handleUploadImage}
          >
            {buttonText}
        </ButtonOutlined>
      </div>
    );
  }

export default UploadFile;

