const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

const commonService = {
    getTaxons: function(cancelToken) {
        const url = `${API_URL}/taxons`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getTagsName: function(cancelToken) {
        const url = `${API_URL}/tags-code`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getCurrencies: function(user_id, user_type, token, cancelToken) {
        const url = `${API_URL}/get-currency-list?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getMercure: function(user_id, user_type, token, cancelToken){
        const url = `${API_URL}/get-mercure?user_id=${user_id}&user_type=${user_type}`;
   
        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getRepresentatives: function(cancelToken) {
        const url = `${API_URL}/get-representatives`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getAccessImage: function(cancelToken) {
        const url = `${API_URL}/get-registration-image`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getSubsPlans: function(cancelToken) {
        const url = `${API_URL}/get-subscriptions`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getNotificationList: function(user_id, user_type, page, per_page, token, cancelToken){
        const url = `${API_URL}/get-notifications?user_id=${user_id}&user_type=${user_type}&page=${page}&per_page=${per_page}`;
        
        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getWDYFU: function(cancelToken) {
        const url = `${API_URL}/get-find-us`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    getSubsLimits: function(user_id, user_type, token, cancelToken) {
        const url = `${API_URL}/get-limit?user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            cancelToken: cancelToken
        });
    },
    getTips: function(cancelToken) {
        const url = `${API_URL}/get-tips`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json'
            },
            cancelToken: cancelToken
        });
    },
    verifyCoupon: function(coupon_code,user_id, user_type, cancelToken) {
        const url = `${API_URL}/verify-coupon-subscription?coupon_code=${coupon_code}&user_id=${user_id}&user_type=${user_type}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            },
            cancelToken: cancelToken
        });
    },
};

export default commonService;