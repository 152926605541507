import React from 'react';
import Grid from '@material-ui/core/Grid';
import './TitleHeadingComponent.scss';

function TitleHeadingComponent(props) {
    return (
    <Grid 
        container 
        direction="column" 
        className={"title-heading-container" + (props.className ? (" " + props.className) : "")}>
            <Grid item className="title-heading-title">
                {props.title}
                {props.icon}
            </Grid>
            {
                props.subtitle ?
                    <Grid item className="title-heading-subtitle">
                        {props.subtitle}
                    </Grid> : null
            }
        </Grid>
    );
}

export default TitleHeadingComponent;