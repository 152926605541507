import React from 'react';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';

export const subscriptionListBuyer = [
    {
        title: 'General',
        children: [
            {
                key: 1,
                feature: 'Users',
                second: '1',
                third: '5',
            }
        ]
    },
    {
        title: 'Database',
        children: [
            {
                key: 1,
                feature: 'Basic Profile',
                second: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 3,
                feature: 'Showroom',
                second: '3',
                third: '24',
            },
            {
                key: 4,
                feature: 'Search Suppliers',
                second:  <CheckCircleOutlined />,
                third:  <CheckCircleOutlined />,
            },
            {
                key: 5,
                feature: 'Advanced Search Suppliers',
                second: <HighlightOff color="disabled" />,
                third: <CheckCircleOutlined />,
            }
        ]
    },
    {
        title: 'Marketplace',
        children: [
            {
                key: 1,
                feature: 'Post RFIs (monthly)',
                second: '15',
                third: 'Unlimited',
            },
            {
                key: 2,
                feature: 'Post RFQs (monthly)',
                second: <HighlightOff color="disabled" />,
                third: 'Unlimited',
            },
            {
                key: 3,
                feature: 'Number of Contacts (Total)',
                second: '5',
                third: 'Unlimited',
            },
            {
                key: 4,
                feature: 'See Listings',
                second: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 5,
                feature: 'Be Notified',
                second: <HighlightOff color="disabled" />,
                third: <CheckCircleOutlined />,
            },
        ]
    },
    {
        title: 'Ecommerce',
        children: [
            {
                key: 1,
                feature: 'Buy Drops',
                second: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 3,
                feature: 'Fast Custom (coming soon)',
                second: <CheckCircleOutlined/>,
                third: <CheckCircleOutlined />,
            },
        ]
    },
    {
        title: 'Support Services',
        children: [
            {
                key: 1,
                feature: 'On-the-ground Services (QC, Compliance, Order Follow Up)',
                second: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 2,
                feature: 'Manual matching',
                second: <HighlightOff color="disabled" />,
                third: <CheckCircleOutlined />,
            }
        ]
    }
];

export const subscriptionListSeller = [
    {
        title: 'General',
        children: [
            {
                key: 1,
                feature: 'Users',
                zero: '1',
                first: '1',
                third: '5',
            }
        ]
    },
    {
        title: 'Database',
        children: [
            {
                key: 1,
                feature: 'Basic Profile',
                zero: <CheckCircleOutlined />,
                first: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 3,
                feature: 'Showroom',
                zero: '4',
                first: '8',
                third: '24',
            },
            {
                key: 4,
                feature: 'Search Buyers & Suppliers',
                zero: <HighlightOff color="disabled" />,
                first: <CheckCircleOutlined />,
                third:  <CheckCircleOutlined />,
            },
        ]
    },
    {
        title: 'Marketplace',
        children: [
            {
                key: 1,
                feature: 'Post Listings (Monthly)',
                zero: <HighlightOff color="disabled" />,
                first: '5',
                third: 'Unlimited',
            },
            {
                key: 2,
                feature: 'Number of Contacts (Total)',
                zero: <HighlightOff color="disabled" />,
                first: '5',
                third: 'Unlimited',
            },
            {
                key: 3,
                feature: 'See RFIs',
                zero: <CheckCircleOutlined />,
                first: <CheckCircleOutlined />,
                third:  <CheckCircleOutlined />,
            },
            {
                key: 4,
                feature: 'View & Respond to RFQs',
                zero: <HighlightOff color="disabled" />,
                first: <HighlightOff color="disabled" />,
                third:  <CheckCircleOutlined />,
            }
        ]
    },
    {
        title: 'Ecommerce',
        children: [
            {
                key: 1,
                feature: 'Access Drops',
                zero:  <CheckCircleOutlined />,
                first:  <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
            {
                key: 3,
                feature: 'Fast Custom',
                zero: <HighlightOff color="disabled" />,
                first: <CheckCircleOutlined />,
                third: <CheckCircleOutlined />,
            },
        ]
    },
    {
        title: 'Support Services',
        children: [
            {
                key: 1,
                feature: 'Manual Matching ',
                zero:  <HighlightOff color="disabled" />,
                first:  <HighlightOff color="disabled"  />,
                third: <CheckCircleOutlined />,
            },
            // {
            //     key: 2,
            //     feature: 'Online Order Follow-up - Critical path (coming soon)',
            //     zero: <HighlightOff color="disabled" />,
            //     first: <HighlightOff color="disabled" />,
            //     second: <HighlightOff color="disabled" />,
            //     third: <CheckCircleOutlined />,
            // },
            // {
            //     key: 3,
            //     feature: 'Financial Services',
            //     zero:  <HighlightOff color="disabled" />,
            //     first:  <CheckCircleOutlined />,
            //     second: <CheckCircleOutlined />,
            //     third: <CheckCircleOutlined />,
            // },
            // {
            //     key: 4,
            //     feature: 'Advisory',
            //     zero:  <HighlightOff color="disabled" />,
            //     first:  <CheckCircleOutlined />,
            //     second: <CheckCircleOutlined />,
            //     third: <CheckCircleOutlined />,
            // },
            // {
            //     key: 5,
            //     feature: 'Joint Purchasing & E-commerce Discount',
            //     zero: <HighlightOff color="disabled" />,
            //     first: <HighlightOff color="disabled" />,
            //     second: <HighlightOff color="disabled" />,
            //     third: <CheckCircleOutlined />,
            // },
            
        ]
    }
];

