import React from 'react';
import { Card, CardActionArea } from '@material-ui/core';
import './CardServices.scss';

function CardServices(props) {
    if(!props.data) {
        return null;
    }

    const serviceData = props.data;

    return (
        <Card className="card-service-container" variant="outlined">
            <CardActionArea>
                <div className="card-service-image">
                    <img src={serviceData.image.path} alt="service" />
                </div>
                <div className="card-service-text">
                    <div className="card-service-title">
                        {serviceData.title}
                    </div>
                    <div className="card-service-desc">
                    {serviceData.shortDesc}
                    </div>
                </div>
                <div className="card-service-btn">
                    {props.upgrade ? 'Upgrade to learn more' : 'Learn More'}
                </div>
            </CardActionArea>
        </Card>
    );
}

export default CardServices;