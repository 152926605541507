import React from 'react';
import Chip from '@material-ui/core/Chip';
import './ChipComponent.scss';

function ChipComponent(props) {
    return (
        <Chip
            variant="outlined"
            {...props}
        />
    );
}

export default ChipComponent;