export const localStorageName = "abana-user-auth";
export const localStorageImpersonationName = "abana-user-auth-impersonation";

export const repsDefaultList = [
  {
    code: "none",
    name: "None",
  },
];

export const wdyfuDefaultList = [
  {
    code: "none",
    name: "None",
  },
];
