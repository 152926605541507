import {
  Avatar,
  ButtonBase,
  InputAdornment,
  InputBase,
  Snackbar,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { selectors as authSelectors } from "../../redux/modules/auth";
import { selectors as profileSelectors } from "../../redux/modules/profile";
import { selectors as searchSelectors } from "../../redux/modules/search";
import { actions as searchActions } from "../../redux/modules/search";
import countriesList from "../../constants/countries-list";
import SearchBarModal from "./SearchBarModal";
import "./SearchBar.scss";
import messagesService from "../../services/messagesService";
import { Alert } from "@material-ui/lab";

const placeholder = "Search Database for Buyers, Sellers & Products";
const MIN_LENGTH = 2;
const NUMBER_OF_RESULTS = 20;
const BASE_URL = process.env.REACT_APP_API_URL;

const SearchBar = ({
  auth,
  clientData,
  search,
  searchResults,
  setSearch,
  setSearchResults,
}) => {
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isRequest, setIsRequest] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Contact Supplier");
  const [snackStatus, setSnackStatus] = useState({
    type: "success",
    message: "",
  });

  const typingTimer = useRef(null);
  const cancelToken = useRef(null);

  const history = useHistory();

  const sortFunction = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const fetchResult = async (val) => {
    if (auth?.user_id && auth?.token) {
      if (cancelToken.current) {
        cancelToken.current.cancel("Operations cancelled due to new request");
      }
      cancelToken.current = axios.CancelToken.source();
      setIsFetching(true);
      setSearchResults([]);

      const requestData = {
        user_id: auth.user_id,
        user_type: clientData?.basics?.type || "",
        per_page: NUMBER_OF_RESULTS,
        filter: val,
      };

      try {
        const res = await axios.post(`${BASE_URL}/search`, requestData, {
          cancelToken: cancelToken.token,
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        const resData = res?.data?.length > 0 ? res?.data : [];

        const data = {
          members: [],
          products: [],
        };

        resData.map((item) => {
          const { type } = item || {};

          if (type === "customer" || type === "vendor") {
            data.members.push(item);
          } else {
            data.products.push(item);
          }
          return true;
        });

        let dataSorted = [];

        if (data.members.length > 0) {
          console.log(data.members, data.members.sort(sortFunction));
          dataSorted = [
            { id: "members", type: "separator", label: "Members" },
            ...data.members.sort(sortFunction),
          ];
        }

        if (data.products.length > 0) {
          dataSorted = [
            ...dataSorted,
            { id: "products", type: "separator", label: "Products" },
            ...data.products.sort(sortFunction),
          ];
        }
        setSearchResults(dataSorted);
        setIsFetching(false);
      } catch (e) {
        console.error(e);
        setIsFetching(false);
      }
    }
  };

  const toggleSearch = () => {
    setSearchMenuOpen((val) => !val);
  };

  const handleSearch = (e) => {
    const { value } = e.target || {};
    setSearch(value);

    if (value.length > MIN_LENGTH) {
      clearTimeout(typingTimer.current);
      typingTimer.current = setTimeout(() => fetchResult(value), 500);
    }
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (searchResults.length === 0) setResultOpen(false);
    }, 50);
  };

  const getCountry = (countryCode = "") => {
    const country = countriesList.children.find(
      (ctr) => ctr.code === countryCode
    );
    return country?.name || "";
  };

  const handleClick = (item) => {
    const { type, profile_type = "", profile_id = "" } = item || {};

    if (type === "customer" || type === "vendor") {
      const url = `/auth/profile-view/${profile_id}/${profile_type}`;
      setResultOpen(false);
      history.push(url);
    } else {
      setCurrentData(item);
      setShowModal(true);
    }
  };

  const handleBtnClick = (item) => {
    const { basics } = item || {};
    const { vendor } = basics || {};
    const user = vendor?.[0] ? vendor?.[0] : null;
    const { profile_type, profile_id } = user || {};

    if (profile_type && profile_id) {
      const requestData = {
        user_id: auth.user_id,
        user_type: clientData.basics.type,
        connection_id: profile_id,
        connection_type: profile_type,
        message: "",
      };

      setIsRequest(true);

      messagesService
        .addConnection(requestData, auth.token)
        .then(() => {
          setIsRequest(false);
          setShowSnackBar(true);
          setSnackStatus({
            type: "success",
            message: "Request Sent",
          });
          setBtnText("Supplier Contacted");
          setBtnDisabled(true);
        })
        .catch((e) => {
          console.error(e);
          setIsRequest(false);
          setShowSnackBar(true);
          setSnackStatus({
            type: "error",
            message: "Request error. Please contact Admin.",
          });
        });
    }
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackBar(false);
  };

  return (
    <>
      <div
        className={`search-bar-container  ${searchMenuOpen ? "mobile" : ""}`}
      >
        <div className="search-bar">
          <InputBase
            placeholder={placeholder}
            name="search"
            classes={{
              root: "input-root",
              input: "input-input",
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
            value={search}
            onFocus={() => setResultOpen(true)}
            onBlur={handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div className="search-mobile" onClick={toggleSearch}>
        <SearchIcon />
      </div>

      <div
        className={`result-search ${searchMenuOpen ? "mobile" : ""} ${
          resultOpen && search.length > MIN_LENGTH ? "open" : ""
        }`}
      >
        {isFetching && (
          <div className="result-fetching">
            <p>Searching ...</p>
          </div>
        )}

        {search &&
          search.length > MIN_LENGTH &&
          searchResults.length === 0 &&
          !isFetching && (
            <div className="result-fetching">
              <p>No result for: {search}</p>
            </div>
          )}

        {searchResults.length > 0 && (
          <div className="result-search-container">
            {searchResults.map((item) => {
              const { id, basics, name, type, label } = item || {};

              const country =
                basics?.country || basics?.vendor?.[0]?.country || "";

              if (type === "separator") {
                return (
                  <div key={id} className="separatorSearch">
                    <p>{label}</p>
                  </div>
                );
              }

              return (
                <ButtonBase
                  key={`${id}-${type}`}
                  className="search-result-item"
                  onClick={() => handleClick(item)}
                >
                  <div className="search-item-profile">
                    {basics?.logo?.path ? (
                      <Avatar
                        alt="Profile"
                        src={basics?.logo?.path}
                        className="user-profile"
                      />
                    ) : (
                      <Avatar>{name?.charAt(0).toUpperCase()}</Avatar>
                    )}
                  </div>
                  <div className="search-item-details">
                    <h3>
                      {name}
                      {basics?.verified && <CheckCircle />}
                    </h3>
                    <p>{getCountry(country)}</p>
                  </div>
                </ButtonBase>
              );
            })}
          </div>
        )}

        {search && search.length > MIN_LENGTH && (
          <div className="clear-search">
            <button onClick={handleClearSearch}>Clear Searches</button>
          </div>
        )}
      </div>
      <SearchBarModal
        open={showModal}
        data={currentData}
        onClose={() => setShowModal(false)}
        onCloseSearch={() => setResultOpen(false)}
        onBtnClicked={handleBtnClick}
        isRequest={isRequest}
        btnText={btnText}
        btnDisabled={btnDisabled}
      />
      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          elevation={0}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackStatus.type}
          children={snackStatus.message}
        />
      </Snackbar>
    </>
  );
};

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    search: searchSelectors.getSearch(state),
    searchResults: searchSelectors.getSearchResults(state),
  }),
  {
    setSearch: searchActions.setSearch,
    setSearchResults: searchActions.setSearchResults,
  }
)(SearchBar);
