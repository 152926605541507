import React from 'react';
import { Button } from '../../../_common/index';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import './BuyerUpgrade.scss';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { actions as accountActions } from '../../../../redux/modules/account';
function BuyerUpgrade(props) {
  let history = useHistory();
  const advancedList = [
        'Revenue',
        'Monthly Capacity',
        'Main Markets',
        'Customer Examples',
        'Compliance',
        'Lead Time',
        'Employee Information',
    ];
    const handleSeePlans = () => {
      props.setTabValue(1);
      history.push(`/auth/account-settings`);
      return;
    };
    return (
        <div className="buyer-upgrade-container">
            <div className="buyer-upgrade-title">
                Upgrade your account to see advanced details about this seller such as:
            </div>
            <ul className="buyer-upgrade-content">
                {
                    advancedList.map((item, index) =>
                    <li key={index}>
                        {item}
                    </li>
                )
                }
            </ul>
            <div className="star-icon">
                <StarRoundedIcon />
            </div>
            <Button onClick={handleSeePlans}>
                See Plans
            </Button>
        </div>
    );
}
export default connect(
  state => ({
  }),
  {
    setTabValue: accountActions.setTabValue,
  }
)(BuyerUpgrade)