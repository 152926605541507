import React from 'react';
import { getCountryNameByCode } from '../../../../functions/converters';
import CountryRegionData from 'country-region-data';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';
import './ContactSection.scss';

function ContactSection(props){

    const countryName = getCountryNameByCode(CountryRegionData, props.country) || null;

    return (
        <div className="profile-contacts-container">
            <div className="profile-contacts-title">Contact:</div>
            <Grid
                container  
                direction="row"
                className="profile-contacts-contents"
            >
                {
                    (props.streetAddress || props.city || countryName) ?
                    <Grid
                        container  
                        direction="row" 
                        item
                        xs={12}
                        md={6}
                        className="pc-item"
                        wrap="nowrap"
                    >
                        <Grid className="pc-icon">
                            <LocationOnIcon />
                        </Grid>
                        <Grid className="pc-text">
                            <div>{props.streetAddress}</div>
                            <div>{props.city}</div>
                            <div>{countryName}</div>
                        </Grid>
                    </Grid> : null
                }

                <Grid
                    container  
                    direction="row" 
                    item
                    xs={12}
                    md={6}
                    className="pc-item"
                    wrap="nowrap"
                >
                    <Grid className="pc-icon">
                        <PersonOutlineIcon />
                    </Grid>
                    <Grid className="pc-text">
                        <div>{props.firstName} {props.lastName}</div>
                        <div className="pc-item-ceo">{props.position}</div>
                    </Grid>
                </Grid>

                {
                    props.cellphone ?
                    <Grid
                        container  
                        direction="row" 
                        item
                        xs={12}
                        md={6}
                        className="pc-item"
                        wrap="nowrap"
                    >
                        <Grid className="pc-icon">
                            <PhoneEnabledIcon />
                        </Grid>
                        <Grid className="pc-text">
                            <div>{props.cellphone}</div>
                        </Grid>
                    </Grid> : null
                }
                
                <Grid
                    container  
                    direction="row" 
                    item
                    xs={12}
                    md={6}
                    className="pc-item"
                    wrap="nowrap"
                >
                    <Grid className="pc-icon">
                        <MailIcon />
                    </Grid>
                    <Grid className="pc-text">
                        <div>{props.email}</div>
                    </Grid>
                </Grid>

                {
                    props.website ?
                    <Grid
                        container  
                        direction="row" 
                        item
                        xs={12}
                        md={6}
                        className="pc-item"
                        wrap="nowrap"
                    >
                        <Grid className="pc-icon">
                            <HouseOutlinedIcon />
                        </Grid>
                        <Grid className="pc-text">
                            <div className="pc-item-site">
                                <a 
                                    href={props.website} 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.website}
                                </a>
                            </div>
                        </Grid>
                    </Grid> : null
                }

                {
                    props.phoneNumber ?
                    <Grid
                        container  
                        direction="row" 
                        item
                        xs={12}
                        md={6}
                        className="pc-item"
                        wrap="nowrap"
                    >
                        <Grid className="pc-icon">
                            <PhoneEnabledIcon />
                        </Grid>
                        <Grid className="pc-text">
                            <div>{props.phoneNumber}</div>
                        </Grid>
                    </Grid>: null
                }
            </Grid>
        </div>
        
    )
}


export default ContactSection;