import React from 'react';
import './SuccessMessageComponent.scss';

function SuccessMessageComponent(props) {
    if(!props.showSuccess) {
        return null;
    }

    return (
        <div className="success-message-container">
            {props.message}
        </div>
    );
}

export default SuccessMessageComponent;