import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './ButtonNextComponent.scss';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    buttonNext: {
        background:'#FF6158;',
        color: '#ffffff;'
    },
    iconColor: {
        color: '#ffffff;'
    }
})

function ButtonNextComponent(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Button
                variant="contained"
                disableElevation
                {...props}
                endIcon = {<ArrowForwardIosIcon className={classes.iconColor} />}
                className={classes.buttonNext}
            >
                {props.children}
            </Button>

        </React.Fragment>
    );
}

export default ButtonNextComponent;
