import React from 'react';
import { Checkbox } from '../../_common/index';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import './GroupCheckboxColumnComponent.scss';

function GroupCheckboxColumnComponent(props) {

    const handleChange = (name, event) => {
        props.onChange(name, event.target);
    };  

    return (
        <div className="checkbox-column-container">
            <FormControl component="fieldset">
                {
                    props.title ?
                    <div className="checkbox-column-title">
                        {props.title}
                    </div> : null
                }  
                <FormGroup>
                    <Grid container direction='row'>
                    {
                        props.checkboxList.map((item) => (
                            <Grid 
                                className="checkbox-column-item"
                                container 
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                key={item[props.naming.name]}
                            >
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={props.values[item[props.naming.name]] || false} 
                                                onChange={(e) => handleChange(props.name, e)} 
                                                name={item[props.naming.name]}
                                            />
                                        }
                                        label={item[props.naming.label]}
                                    />
                                </Grid>
                                {/* <Grid item>
                                       {item[props.naming.description]} 
                                </Grid> */}
                            </Grid>
                            
                        ))
                    }
                    </Grid>
                    
                </FormGroup>
            </FormControl>
        </div>
    );
}

export default GroupCheckboxColumnComponent;
