import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import './MultiSelectTextfield.scss'

function MultiSelectTextfield(props){
    let value = {};

    const handleSelectChange = (event) => {
       const condition = event.target.value;
       value = {...props.values, condition: condition};
       props.onDropDownChange(value, props.name);
    };

    const handleTextfieldChange = (event) => {
        value = {...props.values, value:event.target.value};
        props.onInputChange(value, props.name);
    };

    return(
        <Grid container direction="column" className="multi-container">
             <label htmlFor={props.name} className={(props.disabled === true)? "disabled-label" : null}>{props.labelname}</label>

             <Grid container direction="row" item className="multi-content">
                <TextField 
                    select
                    variant="outlined"
                    className="multi-textfield"
                    margin="dense"
                    disabled={props.disabled}
                    value={props.values.condition || ''}
                    onChange={handleSelectChange}
                >
                  {props.children}
                </TextField >

                <TextField 
                    className="textfield"
                    variant="outlined"
                    margin="dense"
                    disabled={props.disabled}
                    value={props.values.value || ''}
                    placeholder={props.placeholder}
                    onChange={handleTextfieldChange}
                />
             </Grid>
        </Grid>
    )
}


export default MultiSelectTextfield;