//Module Name
export const name = 'drops';

// Actions
export const types = {
    SET_BASIC_DATA: 'SET_BASIC_DATA',
    SET_ADD_TO_CART_DATA: 'SET_ADD_TO_CART_DATA',
    SET_DROP_HOME_DATA: 'SET_DROP_HOME_DATA',
    SET_DROP_COLLECTION_DATA: 'SET_DROP_COLLECTION_DATA',
    SET_DROP_DATA: 'SET_DROP_DATA'
};

const initialState = {
    basicData: null,
    addToCartData: null,
    dropHomeData: null,
    dropCollectionData: null,
    dropData: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_BASIC_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                basicData: content
            };
        }
        case types.SET_ADD_TO_CART_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                addToCartData: content
            };
        }
        case types.SET_DROP_HOME_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                dropHomeData: content
            };
        }
        case types.SET_DROP_COLLECTION_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                dropCollectionData: content
            };
        }
        case types.SET_DROP_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                dropData: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setBasicData: content => ({
        type: types.SET_BASIC_DATA,
        payload: {
            content
        }
    }),
    setAddToCartData: content => ({
        type: types.SET_ADD_TO_CART_DATA,
        payload: {
            content
        }
    }),
    setDropHomeData: content => ({
        type: types.SET_DROP_HOME_DATA,
        payload: {
            content
        }
    }),
    setDropCollectionData: content => ({
        type: types.SET_DROP_COLLECTION_DATA,
        payload: {
            content
        }
    }),
    setDropData: content => ({
        type: types.SET_DROP_DATA,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getBasicData: store => store[name].basicData,
    getaddToCartData: store => store[name].addToCartData,
    getDropHomeData: store => store[name].dropHomeData,
    getDropCollectionData: store => store[name].dropCollectionData,
    getDropData: store => store[name].dropData,
};