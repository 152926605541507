import React from 'react';
import { Grid, Card, ButtonBase } from '@material-ui/core';
import { Chip } from '../../index';
import './GridImage.scss';

function GridImage(props) {
    const templateList = props.templateList;

    if(!templateList) {
        return null;
    }

    return (
        <div className="grid-image-container">
            <Grid
                container
                justify="space-between"
                spacing={3}
            >
                {
                    templateList.map((item, index) => 
                        <Grid 
                            key={index}
                            className="grid-image-item" 
                            item
                            xs={12}
                            sm={templateList.length > 1 ? 6 : 'auto'}
                            md
                        >
                            <Card>  
                                <ButtonBase onClick={() => props.onClick(item)}>
                                    {
                                        item.image ?
                                        <img src={item.image} alt="drops" /> : null
                                    }
                                    <div className="grid-image-desc">{item.description}</div>
                                    {
                                        item.buttonText ?
                                        <Chip                      
                                            label={item.buttonText}
                                        /> : null
                                    }                                  
                                </ButtonBase>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>  
        </div>
    );
}

export default GridImage;