import React, {useState} from 'react';
import { 
    TitleHeading, TextFieldOther, GroupCheckboxGrid 
} from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './Products.scss';

function Form(props) {
    const {
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldTouched,
        checkboxNaming,
        checkboxValues,
        tagsList
    } = props;

    const inputChange = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.map((item) => 
                    <div key={item.code}>
                        <GroupCheckboxGrid 
                            naming={checkboxNaming}
                            title={item.name}
                            name={item.code}
                            checkboxList={item.children}
                            values={checkboxValues[item.code]}
                            onChange={props.onCheckboxChange}
                        />
                        <TextFieldOther
                            id={item.code}
                            className="text-field-other-override"
                            name={item.code}
                            value={props.values[item.code]}
                            helperText={touched[item.code] ? errors[item.code] : ''}
                            error={touched[item.code] && Boolean(errors[item.code])}
                            onChange={inputChange.bind(null, item.code)}
                        />
                    </div>
                )
            }
            
        </form>
    );
}

function Products(props){
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    let inputOtherInit = {};
    let yupObject = {};

    if(tagsList.length > 0) {
        tagsList.forEach((item) => { 
            // yupObject[item['code']] = Yup.string('')
            //     .max(20, 'Maximum number of characters is 20');

            inputOtherInit[item['code']] = dataList[item['code']] ? (dataList[item['code']]['other'] || '') : '';
        });
    }

    const [checkboxValues, setCheckboxValues] = useState(dataList);
    const validationSchema = Yup.object(yupObject);
    
    const handleCheckboxChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name][target.name] = target.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const handleFormSubmit = (values, actions) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);

        for(let props in checkboxValues) {
            newCheckboxValues[props]['other'] = values[props];
        }

        props.onSubmit(newCheckboxValues);
    };

    if(tagsList.length === 0 || Object.keys(checkboxValues).length === 0) { 
        return null;
    }

    return(
        <div className="products-container">
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={inputOtherInit}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
        </div>   
    )
}

export default Products;