const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

const authService = {
    login: function(data) {
        const url = `${API_URL}/login`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
    signUp: function(data) {
        const url = `${API_URL}/register`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
    passwordForgotten: function(data){
        const url = `${API_URL}/request-password-reset`;

        return axios({
            method:'PUT',
            url:url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    passwordReset: function(data, token){
        const url = `${API_URL}/password-reset-user?token=${token}`;

        return axios({
            method:'PUT',
            url:url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    verifyAccountBuyer: function(token){
        const url = `${API_URL}/verify-account?token=${token}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
    verifyAccountSeller: function(token){
        const url = `${API_URL}/verify-vendor-account?token=${token}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
    registerSeller: function(data) {
        const url = `${API_URL}/register-vendor`;

        return axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
    completeProfileBuyer: function(data, token){
        const url = `${API_URL}/complete-buyer-profile`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    completeProfileSeller: function(data, token){
        const url = `${API_URL}/complete-vendor-profile`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    verifyEmailExist: function(email) {
        const url = `${API_URL}/verify-email?email=${email}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
    verifyCompanyName: function(company) {
        const url = `${API_URL}/verify-company-name?companyName=${company}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
    verifyAccountMember: function(token){
        const url = `${API_URL}/verify-new-member?token=${token}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
    registerMember: function(data, token){
        const url = `${API_URL}/register-new-member`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    updateNotification: function(data, token){
        const url = `${API_URL}/update-notification`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    },
    updateAllNotification: function (data, token) {
        const url = `${API_URL}/update-all-notification`;

        return axios({
            method: 'PUT',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    },
    getTransactionType: function(orderId){
        const url = `${API_URL}/get-transaction-type?orderId=${orderId}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
            }
        });
    },
};

export default authService;