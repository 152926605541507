import React from 'react';
import { Link } from "react-router-dom";
import './AlreadyAMemberComponent.scss';

function AlreadyAMemberComponent(props){
    return (
        <div className="already-a-member-container">
             <span>Already a member? <Link to="/login">Log In</Link></span>
        </div>
    );
}

export default AlreadyAMemberComponent;

