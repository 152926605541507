import React from 'react';
import { Grid, ButtonBase, Avatar } from '@material-ui/core';
import Moment from 'react-moment';
import "./MenuElement.scss";
import CheckCircle from '@material-ui/icons/CheckCircle';

function MenuElement(props) {
    if(!props.dataList || !props.dataList.basics) {
        return null;
    }

    const dataList = props.dataList;
    const basics = dataList.basics;
    const lastMessage = dataList.last_message;
    const attachedFile = (lastMessage && lastMessage.attached_file && lastMessage.attached_file[0]) ? (lastMessage.attached_file[0].name || null) : null;
    const contents = lastMessage ? (lastMessage.contents || attachedFile) : null;
    const createdAt = lastMessage ? lastMessage.created_at : null;
    
    return(
        <ButtonBase 
            className={
                "menu-element-container" + 
                (props.isList ? ' is-list' : '') +
                (props.isActive ? ' active' : '') +
                (dataList.notify ? ' notify' : '')
            }
            onClick={props.onClick}
        >
            <Grid
                container
                wrap="nowrap"
            >
                <Grid className="conversation-avatar">
                    {
                        (basics.logo &&
                        basics.logo.path) ?
                        <Avatar 
                            alt="Profile" 
                            src={basics.logo.path}
                        /> 
                        :
                        <Avatar>
                            {
                                basics.firstName ? 
                                basics.firstName.charAt(0).toUpperCase() : 
                                null
                            }
                        </Avatar>
                    }
                </Grid>
                <Grid className="section-text">
                    <div className="title-text">{basics.firstName} {basics.lastName} {basics.verified ? <CheckCircle/> : null}</div>
                    <div className="msg-text">
                        {contents || 'No messages'}
                    </div>
                </Grid>
                <Grid className="section-date">
                    <div className="created-date">
                        {
                            createdAt ?
                            <Moment 
                                date={createdAt}
                                fromNow
                            /> : null
                        }            
                    </div>
                </Grid>
            </Grid>
            <span className="notification-dot"></span>
        </ButtonBase>
    );
}

export default MenuElement;