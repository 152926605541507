import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { selectors as commonSelectors } from "../../../redux/modules/common";
import { actions as commonActions } from "../../../redux/modules/common";
import commonService from "../../../services/commonService";
import "./AccessImage.scss";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function AccessImage(props) {
  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.accessImage) {
      commonService
        .getAccessImage(cancelToken)
        .then(function (response) {
          if (response.status === 200 && response.data) {
            props.setAccessImage(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, []);

  const imageUrl = props.accessImage ? props.accessImage.path : "";

  return (
    <div
      className="access-image-container"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    ></div>
  );
}

export default connect(
  (state) => ({
    accessImage: commonSelectors.getAccessImage(state),
  }),
  {
    setAccessImage: commonActions.setAccessImage,
  }
)(AccessImage);
