import React, { useEffect } from "react";
import {
  TitleHeading,
  GroupCheckboxGrid,
  TextFieldOther,
  GroupCheckboxColumn,
  ButtonNext,
  ButtonBack,
} from "../../_common/index";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import "./MainMaterials.scss";
import { filterArray } from "../../../functions/converters";
import { Grid } from "@material-ui/core";

function MainMaterials(props) {
  const title = "What are your main Materials?";
  const subtitle = "Select at least one";
  let requestData = [];
  let displayGauges = true;
  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  switch (props.registerSellerData["supplier_type"]) {
    case "SG":
      requestData = {
        mainMaterials: filterArray(
          props.taxonsSelected[0].children,
          "code",
          "sg_materials"
        ),
        gauges: filterArray(
          props.taxonsSelected[0].children,
          "code",
          "sg_gauges"
        )[0],
      };
      break;
    case "SI":
      requestData = {
        mainMaterials: filterArray(
          props.taxonsSelected[0].children,
          "code",
          "si_materials"
        ),
        gauges: {
          children: [],
        },
      };
      break;
    default:
      break;
  }

  if (props.registerSellerData["supplier_type"] === "SI") {
    displayGauges = false;
  }

  if (props.registerSellerData["main_materials"]) {
    checkboxInit = Object.assign(
      {},
      props.registerSellerData["main_materials"]
    );
  } else {
    requestData.mainMaterials.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
        children["other"] = "";
      });

      checkboxInit[item.code] = children;
    });
    requestData.gauges.children.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
      });

      checkboxInit[item.code] = children;
    });
  }

  const [checkboxValues, setCheckboxValues] = React.useState(checkboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][target.name] = target.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleTextFieldChange = (name, target) => {
    let textFieldValue = Object.assign({}, checkboxValues);
    textFieldValue[name]["other"] = target.value;
    setCheckboxValues(textFieldValue);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      main_materials: checkboxValues,
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  return (
    <div className="mainMaterials-container-reg">
      <TitleHeading title={title} subtitle={subtitle} />
      {requestData.mainMaterials.map((item) => (
        <div key={item.code}>
          <GroupCheckboxGrid
            naming={checkboxNaming}
            title={item.name}
            name={item.code}
            checkboxList={item.children}
            values={checkboxValues[item.code]}
            onChange={handleCheckboxChange}
            md={4}
          />
          <TextFieldOther
            id={item.name}
            name={item.name}
            value={checkboxValues[item.code].other}
            onChange={(e) => handleTextFieldChange(item.code, e.target)}
          />
        </div>
      ))}
      {displayGauges ? (
        <div className="gauge-container">
          <TitleHeading title={requestData.gauges.name} />
          {requestData.gauges.children.map((item) => (
            <GroupCheckboxColumn
              naming={checkboxNaming}
              title={item.name}
              name={item.code}
              checkboxList={item.children}
              key={item.code}
              values={checkboxValues[item.code]}
              onChange={handleCheckboxChange}
            />
          ))}
        </div>
      ) : (
        <div className="mainMaterials-spacing"></div>
      )}

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(MainMaterials);
