//Module Name
export const name = 'listingsQuotes';

//Actions
export const types = {
    SET_LISTINGS_DATA: 'SET_LISTINGS_DATA',
    SET_QUOTES_DATA: 'SET_QUOTES_DATA',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    SET_NEW_QUOTATIONS: 'SET_NEW_QUOTATIONS',
    SET_SHOULD_UPDATE_RFI: 'SET_SHOULD_UPDATE_RFI',
    SET_SHOULD_UPDATE_RFQ: 'SET_SHOULD_UPDATE_RFQ',
    SET_RELOAD_RFQ: 'SET_RELOAD_RFQ',
};

const initialState = {
    listingsData: null,
    quotesData: null,
    activeStep: 1,
    newQuotations: null,
    shouldUpdateRFI: false,
    shouldUpdateRFQ: false,
    reloadRFQ: false
};

//Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_LISTINGS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                listingsData: content
            };
        }
        case types.SET_QUOTES_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                quotesData: content
            };
        }
        case types.SET_ACTIVE_STEP: {
            const { content } = action.payload;

            return {
                ...state,
                activeStep: content
            };
        }
        case types.SET_NEW_QUOTATIONS: {
            const { content } = action.payload;

            return{
                ...state,
                newQuotations: content
            }
        }
        case types.SET_SHOULD_UPDATE_RFI: {
            const { content } = action.payload;

            return {
                ...state,
                shouldUpdateRFI: content
            };
        }
        case types.SET_SHOULD_UPDATE_RFQ: {
            const { content } = action.payload;

            return {
                ...state,
                shouldUpdateRFQ: content
            };
        }
        case types.SET_RELOAD_RFQ: {
            const { content } = action.payload;

            return {
                ...state,
                reloadRFQ: content
            };
        }
        default:
            return state;
    }
};

//Action Creators
export const actions = {
    setListingsData: content => ({
        type: types.SET_LISTINGS_DATA,
        payload: {
            content
        }
    }),
    setQuotesData: content => ({
        type: types.SET_QUOTES_DATA,
        payload: {
            content
        }
    }),
    setActiveStep: content => ({
        type: types.SET_ACTIVE_STEP,
        payload: {
            content
        }
    }),
    setNewQuotations: content => ({
        type: types.SET_NEW_QUOTATIONS,
        payload: {
            content
        }
    }),
    setShouldUpdateRFI: content => ({
        type: types.SET_SHOULD_UPDATE_RFI,
        payload: {
            content
        }
    }),
    setShouldUpdateRFQ: content => ({
        type: types.SET_SHOULD_UPDATE_RFQ,
        payload: {
            content
        }
    }),
    setReloadRFQ: content => ({
        type: types.SET_RELOAD_RFQ,
        payload: {
            content
        }
    })
}

//Selectors
export const selectors = {
    getListingsData: store => store[name].listingsData,
    getQuotesData: store => store[name].quotesData,
    getActiveStep: store => store[name].activeStep,
    getnewQuotations: store => store[name].newQuotations,
    getShouldUpdateRFI: store => store[name].shouldUpdateRFI,
    getShouldUpdateRFQ: store => store[name].shouldUpdateRFQ,
    getReloadRFQ: store => store[name].reloadRFQ
}