import React from 'react';
import { TitleHeading, GroupCheckboxGrid, Checkbox } from '../../../_common/index';
import * as Yup from 'yup';
import { Formik } from "formik";
import './Markets.scss';

function Form(props) {
    const {
        handleSubmit,
        checkboxNaming,
        checkboxValues,
        tagsList,
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                tagsList.map((item) => 
                    <GroupCheckboxGrid
                        key={item.code}
                        naming={checkboxNaming}
                        name={item.code}
                        checkboxList={item.children}
                        values={checkboxValues[item.code]}
                        onChange={props.onCheckboxChange}
                        md={4}
                    />
                )
            }
        </form>
    );
}

function Markets(props){
    const tagsList = props.tagsList || [];
    const dataList = props.dataList || {};
    const itemsList = tagsList[0];
    const checkboxNaming = {
        name: 'code',
        label: 'name'
    };
    let initValues = {};
    let yupObject = {};

    const [checkboxValues, setCheckboxValues] = React.useState(dataList);
    const [checkboxChecked, setCheckedBoxChecked] = React.useState(false);
    const validationSchema = Yup.object(yupObject);

    const handleCheckboxChange = (name, target) => {
        let newCheckboxValues = Object.assign({}, checkboxValues);
        newCheckboxValues[name][target.name] = target.checked;
        setCheckboxValues(newCheckboxValues);
        setCheckedBoxChecked(false);
    };

    const handleAfricaCheckboxChange = (name, target) => {
        setCheckedBoxChecked(target.checked);
        
        tagsList.forEach((item, index) => {
            let newCheckboxValues = Object.assign({}, checkboxValues);
    
            item.children.forEach((value) => {      
                newCheckboxValues[name][value.code] = target.checked;    
            });
            setCheckboxValues(newCheckboxValues);
        });
    }

    const handleFormSubmit = (values) => { 
        let newCheckboxValues = Object.assign({}, checkboxValues);
        props.onSubmit(newCheckboxValues);
    };

    if(tagsList.length === 0 || Object.keys(checkboxValues).length === 0) { 
        return null;
    }
    
    return(
        <div className='market-container'>
            {
                props.title ?
                <TitleHeading 
                    title={props.title} 
                    subtitle={props.subtitle}
                /> : null
            }
            {
                props.showWhole ?
                <div>
                    <div className='africa-checbox'>
                        <Checkbox
                            id='africa'
                            name={itemsList.code}
                            checked={checkboxChecked}
                            onChange={(e) => handleAfricaCheckboxChange(itemsList.code, e.target)}
                        />
                        <label>WHOLE OF AFRICA</label>
                    </div>
                    <div className="market-separator">
                        <hr/>
                    </div>
                </div> : null
            }
            <Formik
                children={props => 
                    <Form 
                        {...props} 
                        checkboxNaming={checkboxNaming}
                        tagsList={tagsList}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                    />
                } 
                innerRef={props.innerRef}
                enableReinitialize
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            /> 
        </div>
    )

}

export default Markets;