import { themeNames } from '../../constants/theme';

//Module Name
export const name = 'theme';

// Actions
export const types = {
    SET_THEME: 'SET_THEME',
};

const initialState = {
    themeName: themeNames.light,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_THEME: {
            const { content } = action.payload;

            return {
                ...state,
                themeName: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setTheme: content => ({
        type: types.SET_THEME,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getThemeName: store => store[name].themeName
};