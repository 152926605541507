//Module Name
export const name = 'common';

// Actions
export const types = {
    SET_TAGS_NAME: 'SET_TAGS_NAME',
    SET_TAXONS_DATA: 'SET_TAXONS_DATA',
    SET_CURRENCIES_DATA: 'SET_CURRENCIES_DATA',
    SET_TIMEZONES_DATA: 'SET_TIMEZONES_DATA',
    SET_PREVIOUS_LOCATION_DATA: 'SET_PREVIOUS_LOCATION_DATA',
    SET_MERCURE_DATA: 'SET_MERCURE_DATA',
    SET_REPS_DATA: 'SET_REPS_DATA',
    SET_BUYER_TAXONS_DATA: 'SET_BUYER_TAXONS_DATA',
    SET_SUBS_PLANS: 'SET_SUBS_PLANS',
    SET_ACCESS_IMAGE: 'SET_ACCESS_IMAGE',
    SET_NOTIFICATION_LIST: 'SET_NOTIFICATION_LIST',
    SET_WDYFU_DATA: 'SET_WDYFU_DATA',
    SET_SUBS_LIMITS: 'SET_SUBS_LIMITS',
    SET_SHOULD_UPDATE_LIMITS: 'SET_SHOULD_UPDATE_LIMITS',
    SET_TIPS_DATA: 'SET_TIPS_DATA',
};

const initialState = {
    tagsNameData: null,
    taxonsData: null,
    currenciesData: null,
    timeZonesData: null,
    previousLocationData: null,
    mercureData: null,
    repsData: null,
    buyerTaxonsData: null,
    subsPlans: null,
    accessImage: null,
    notificationList: null,
    wdyfuData: null,
    subsLimits: null,
    shouldUpdateLimits: false,
    tipsData: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_TAGS_NAME: {
            const { content } = action.payload;

            return {
                ...state,
                tagsNameData: content
            };
        }
        case types.SET_TAXONS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                taxonsData: content
            };
        }
        case types.SET_CURRENCIES_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                currenciesData: content
            };
        }
        case types.SET_TIMEZONES_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                timeZonesData: content
            };
        }
        case types.SET_PREVIOUS_LOCATION_DATA: {
            const { content } = action.payload;

            return{
                ...state,
                previousLocationData: content
            }
        }
        case types.SET_MERCURE_DATA: {
            const { content } = action.payload;

            return{
                ...state,
                mercureData: content
            }
        }
        case types.SET_REPS_DATA: {
            const { content } = action.payload;

            return{
                ...state,
                repsData: content
            }
        }
        case types.SET_BUYER_TAXONS_DATA: {
            const { content } = action.payload;

            return{
                ...state,
                buyerTaxonsData: content
            }
        }
        case types.SET_SUBS_PLANS: {
            const { content } = action.payload;

            return {
                ...state,
                subsPlans: content
            }
        }
        case types.SET_ACCESS_IMAGE: {
            const { content } = action.payload;

            return {
                ...state,
                accessImage: content
            }
        }
        case types.SET_NOTIFICATION_LIST: {
            const { content } = action.payload;

            return {
                ...state,
                notificationList: content
            }
        }
        case types.SET_WDYFU_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                wdyfuData: content
            }
        }
        case types.SET_SUBS_LIMITS: {
            const { content } = action.payload;

            return {
                ...state,
                subsLimits: content
            }
        }
        case types.SET_SHOULD_UPDATE_LIMITS: {
            const { content } = action.payload;

            return {
                ...state,
                shouldUpdateLimits: content
            }
        }
        case types.SET_TIPS_DATA: {
            const { content } = action.payload;

            return {
                ...state,
                tipsData: content
            }
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setTagsNameData: content => ({
        type: types.SET_TAGS_NAME,
        payload: {
            content
        }
    }),
    setTaxonsData: content => ({
        type: types.SET_TAXONS_DATA,
        payload: {
            content
        }
    }),
    setCurrenciesData: content => ({
        type: types.SET_CURRENCIES_DATA,
        payload: {
            content
        }
    }),
    setTimeZonesData: content => ({
        type: types.SET_TIMEZONES_DATA,
        payload: {
            content
        }
    }),
    setPreviousLocationData: content => ({
        type: types.SET_PREVIOUS_LOCATION_DATA,
        payload: {
            content
        }
    }),
    setMercureData: content => ({
        type: types.SET_MERCURE_DATA,
        payload: {
            content
        }
    }),
    setRepsData: content => ({
        type: types.SET_REPS_DATA,
        payload: {
            content
        }
    }),
    setBuyerTaxonsData: content => ({
        type: types.SET_BUYER_TAXONS_DATA,
        payload: {
            content
        }
    }),
    setSubsPlans: content => ({
        type: types.SET_SUBS_PLANS,
        payload: {
            content
        }
    }),
    setAccessImage: content => ({
        type: types.SET_ACCESS_IMAGE,
        payload: {
            content
        }
    }),
    setNotificationList: content => ({
        type: types.SET_NOTIFICATION_LIST,
        payload: {
            content
        }
    }),
    setWdyfuData: content => ({
        type: types.SET_WDYFU_DATA,
        payload: {
            content
        }
    }),
    setSubsLimits: content => ({
        type: types.SET_SUBS_LIMITS,
        payload: {
            content
        }
    }),
    setShouldUpdateLimits: content => ({
        type: types.SET_SHOULD_UPDATE_LIMITS,
        payload: {
            content
        }
    }),
    setTipsData: content => ({
        type: types.SET_TIPS_DATA,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getTagsNameData: store => store[name].tagsNameData,
    getTaxonsData: store => store[name].taxonsData,
    getCurrenciesData: store => store[name].currenciesData,
    getTimeZonesData: store => store[name].timeZonesData,
    getPreviousLocationData: store => store[name].previousLocationData,
    getMercureData: store => store[name].mercureData,
    getRepsData: store => store[name].repsData,
    getBuyerTaxonsData: store => store[name].buyerTaxonsData,
    getSubsPlans: store => store[name].subsPlans,
    getAccessImage: store => store[name].accessImage,
    getNotificationList: store => store[name].notificationList,
    getWdyfuData: store => store[name].wdyfuData,
    getSubsLimits: store => store[name].subsLimits,
    getShouldUpdateLimits: store => store[name].shouldUpdateLimits,
    getTipsData: store => store[name].tipsData,
};