import React from 'react';
import { connect } from 'react-redux';
import { selectors as authSelectors } from '../../../redux/modules/auth';
import './FooterFixed.scss';

function FooterFixed(props) {
    const sidenavOpen = props.sidenavOpen;

    return (
        <div 
            className={
                "footer-fixed-container" + 
                (sidenavOpen ? " open" : " close") +
                (props.className ? (" " + props.className) : "")
            }
        >
            {props.children}
        </div>
    );
}

export default connect(
    state => ({
        sidenavOpen: authSelectors.getSidenavOpen(state)
    }),
    null
)(FooterFixed)