import React from 'react';
import { Grid } from '@material-ui/core';
import './AdvancedDetailsBuyer.scss';

function AdvancedDetailsBuyer(props){

    const dataList = props.dataList.basics;

    return(
        <div className="advanced-details-buyer-container">
            <Grid
                container
                direction="row"
            >
                {
                    dataList.style ?
                    <Grid 
                        container
                        direction="column"
                        className="details-content"
                        item
                        xs={12}
                        sm={'auto'}
                    >
                        <span className="title-content">{'Buying MOQ/Style:'}</span>
                        <span>{dataList.style}</span>
                    </Grid>
                    :null
                }
                {
                    dataList.profileType ?
                    <Grid 
                        container
                        direction="column"
                        className="details-content"
                        item
                        xs={12}
                        sm={'auto'}
                    >
                        <span className="title-content">{'Profile Type:'}</span>
                        <span>{dataList.profileType}</span>
                    </Grid>
                    :null
                }
            </Grid>

            {
                dataList.brands ?
                <Grid
                    container
                    direction="column"
                    className="details-content-brands"
                >
                    <span className="title-content">{'Brands:'}</span>
                    <span>{dataList.brands}</span>
                </Grid>
                :null
            }
           
        </div>
    )
}

export default AdvancedDetailsBuyer;