import React, { useState, useEffect } from "react";
import {
  TitleHeading,
  TextFieldOther,
  ButtonNext,
  ButtonBack,
  GroupCheckboxColumn,
  ErrorMessage,
  TextField,
} from "../../_common/index";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { selectors as authSelectors } from "../../../redux/modules/auth";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import { errorMessages } from "../../../constants/messages";
import { filterArray } from "../../../functions/converters";
import "./Certifications.scss";
import profileService from "../../../services/profileService";

function Certifications(props) {
  const title = "Certifications";
  let requestData = [];
  let checkboxInit = {};
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  switch (props.registerSellerData["supplier_type"]) {
    case "SG":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "sg_certifications"
      )[0].children;
      break;
    case "SI":
      requestData = filterArray(
        props.taxonsSelected[0].children,
        "code",
        "si_certifications"
      )[0].children;
      break;
  }

  if (props.registerSellerData["certifications"]) {
    checkboxInit = Object.assign(
      {},
      props.registerSellerData["certifications"]
    );
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
        children["other"] = "";
        children["reference"] = "";
      });

      checkboxInit[item.code] = children;
    });
  }

  const [checkboxValues, setCheckboxValues] = React.useState(checkboxInit);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRequest, setIsRequest] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][target.name] = target.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleFieldTextChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name]["other"] = target.value;
    setCheckboxValues(newCheckboxValues);
  };

  const handleCertificationNumberChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name]["reference"] = target.value;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      certifications: checkboxValues,
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
      basics: props.profileData.basics ?? [],
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);

    // // Temporary Fix - To remove!!!
    // const requestData = {
    //     ...newRegisterSellerData,
    //     ...newRegisterSellerData['basics'],
    //     type: newRegisterSellerData['supplier_type'],
    // };
    // ////////////////////////////////////////////////

    // setShowError(false);
    // setIsRequest(true);

    // profileService.editProfile(requestData, props.auth.token)
    //     .then(function (response) {

    //         if (response && response.status === 204) {
    //             props.setActiveStep(props.activeStep + 1);
    //         } else {
    //             setIsRequest(false);
    //             setErrorMsg(errorMessages.errorApi);
    //             setShowError(true);
    //         }
    //     })
    //     .catch(function (error) {
    //         setIsRequest(false);

    //         const errorData = error.response && error.response.data ? error.response.data : null;
    //         if (errorData && errorData.code === 400) {
    //             if (errorData.errors && errorData.errors['basics[email]'] && (errorData.errors['basics[email]'][0] === 'This email is already used.')) {
    //                 setErrorMsg(errorMessages.emailExists);
    //                 setShowError(true);
    //                 return;
    //             }
    //         }
    //         setErrorMsg(errorMessages.errorApi);
    //         setShowError(true);
    //     })
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="certifications-container-reg">
      <TitleHeading title={title} />
      <ErrorMessage showError={showError} message={errorMsg} />
      {requestData.map((item) => (
        <div key={item.code} className="certifications-div">
          <GroupCheckboxColumn
            naming={checkboxNaming}
            title={item.name}
            name={item.code}
            checkboxList={item.children}
            key={item.code}
            values={checkboxValues[item.code]}
            onChange={handleCheckboxChange}
          />
          <TextFieldOther
            id={item.name}
            name={item.name}
            value={checkboxValues[item.code].other}
            onChange={(e) => handleFieldTextChange(item.code, e.target)}
          />
          <div className="certifications-container">
            <TextField
              id={item.name}
              name={item.name}
              value={checkboxValues[item.code].reference}
              labelname={"Certification Numbers"}
              placeholder={
                "If you have any certification, please provide a reference number"
              }
              onChange={(e) =>
                handleCertificationNumberChange(item.code, e.target)
              }
            />
          </div>
        </div>
      ))}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext
          children="Create Account"
          onClick={handleNext}
          disabled={isRequest}
        />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    profileData: profileSelectors.getProfileData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(Certifications);
