import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  TitleHeading,
  ButtonNext,
  ButtonBack,
  Checkbox,
} from "../../_common/index";
import * as Yup from "yup";
import { Formik } from "formik";
import { errorMessages } from "../../../constants/messages";
import { filterArray } from "../../../functions/converters";
import "./Segments.scss";

function Form(props) {
  const { touched, errors, handleChange, handleSubmit, setFieldTouched } =
    props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleCheckboxChange = (name, event) => {
    props.onCheckboxChange(name, event.target);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" className="checkbox-grid">
        <Grid item xs={6} className="checkbox-heading">
          Category
        </Grid>
        <Grid item xs={6} className="checkbox-heading">
          Percentage
        </Grid>

        {props.checkboxList.map((item, index) => (
          <Grid container item xs={12} key={item[props.naming.name]}>
            <Grid
              className="checkbox-grid-item"
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              xs={6}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.checkboxValues[item[props.naming.name]]["checked"]
                    }
                    onChange={(e) =>
                      handleCheckboxChange(item[props.naming.name], e)
                    }
                    name={"checkbox-" + item[props.naming.name]}
                  />
                }
                label={item[props.naming.label]}
              />
            </Grid>
            <Grid item xs={6} className="checkbox-grid-percentage">
              <TextField
                variant="outlined"
                margin="dense"
                placeholder="%"
                id={item[props.naming.name]}
                name={item[props.naming.name]}
                value={props.values[item[props.naming.name]]}
                helperText={
                  touched[item[props.naming.name]]
                    ? errors[item[props.naming.name]]
                    : ""
                }
                error={
                  touched[item[props.naming.name]] &&
                  Boolean(errors[item[props.naming.name]])
                }
                onChange={change.bind(null, item[props.naming.name])}
                disabled={
                  !props.checkboxValues[item[props.naming.name]]["checked"]
                }
              />
            </Grid>
          </Grid>
        ))}

        {props.errorPercentage ? (
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} className="error-percentage">
              {props.errorPercentage}
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {/*<ButtonBack*/}
        {/*    children="Back"*/}
        {/*    hidden*/}
        {/*    onClick={props.onBackClicked}*/}
        {/*/>*/}
        <ButtonNext children="Next" type="submit" />
      </Grid>
    </form>
  );
}

function Segments(props) {
  const requestData = filterArray(
    props.taxonsSelected[0].children,
    "code",
    "sg_segment"
  );
  const heading = "What Segments do you cover?";
  const subHeading =
    "Add percentages of your capacity allocated to each segment";
  let checkboxInit = {};
  let percentageValues = {};
  let yupObject = {};
  const percentageError = "Percentage must be an integer between 1 and 100";
  const [errorPercentage, setErrorPercentage] = useState(null);
  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  if (props.registerSellerData["segments"]) {
    const segments = props.registerSellerData["segments"];
    checkboxInit = Object.assign({}, segments);

    requestData[0].children.forEach((item, index) => {
      percentageValues[item["code"]] =
        segments[item["code"]]["percentage"] || "";
      yupObject[item["code"]] = Yup.number("")
        .integer(percentageError)
        .min(1, percentageError)
        .max(100, percentageError)
        .typeError(percentageError);
    });
  } else {
    requestData[0].children.forEach((item, index) => {
      let children = {
        checked: false,
        percentage: 0,
      };
      checkboxInit[item["code"]] = children;

      percentageValues[item["code"]] = "";
      yupObject[item["code"]] = Yup.number("")
        .integer(percentageError)
        .min(1, percentageError)
        .max(100, percentageError)
        .typeError(percentageError);
    });
  }

  const [checkboxValues, setCheckboxValues] = useState(checkboxInit);
  const validationSchema = Yup.object(yupObject);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name]["checked"] = target.checked;
    setCheckboxValues(newCheckboxValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleFormSubmit = (values, actions) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    let totalPercentage = 0;

    for (let props in checkboxValues) {
      if (checkboxValues[props]["checked"]) {
        totalPercentage += parseInt(values[props]);
        newCheckboxValues[props]["percentage"] = parseInt(values[props]);
      } else {
        newCheckboxValues[props]["percentage"] = 0;
      }
    }

    if (totalPercentage !== 100) {
      setErrorPercentage(errorMessages.percentageError);
    } else {
      setErrorPercentage(null);
      const registerSellerData = props.registerSellerData;
      const newRegisterSellerData = {
        ...registerSellerData,
        segments: newCheckboxValues,
      };

      props.setRegisterSellerData(newRegisterSellerData);
      props.setActiveStep(props.activeStep + 1);
    }
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="segments-container-reg">
      <TitleHeading title={heading} subtitle={subHeading} />
      <Formik
        children={(props) => (
          <Form
            {...props}
            naming={checkboxNaming}
            checkboxList={requestData[0].children}
            checkboxValues={checkboxValues}
            onCheckboxChange={handleCheckboxChange}
            onBackClicked={handleBack}
            errorPercentage={errorPercentage}
          />
        )}
        enableReinitialize
        initialValues={percentageValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(Segments);
