import { combineReducers } from 'redux';
import accountReducer, { name as accountName } from './modules/account';
import authReducer, { name as authName } from './modules/auth';
import commonReducer, { name as commonName } from './modules/common';
import databaseReducer, { name as databaseName } from './modules/database';
import dropsReducer, { name as dropsName } from './modules/drops';
import feedReducer, { name as feedName } from './modules/feed';
import homeReducer, { name as homeName } from './modules/home';
import listingsQuotesReducer, { name as listingsQuotesName } from './modules/listingsQuotes';
import messagesReducer, { name as messagesName } from './modules/messages';
import orderReducer, { name as ordersName } from './modules/orders';
import profileReducer, { name as profileName } from './modules/profile';
import registerReducer, { name as registerName } from './modules/register';
import reportsReducer, { name as reportsName } from './modules/reports';
import resultsReducer, { name as resultsName } from './modules/results';
import savedItemsReducer, { name as savedItemsName } from './modules/savedItems';
import searchReducer, { name as searchName } from './modules/search';
import servicesReducer, {name as servicesName} from './modules/services';
import themeReducer, { name as themeName } from './modules/theme';

const rootReducer = combineReducers({
  [accountName]: accountReducer,
  [authName]: authReducer,
  [commonName]: commonReducer,
  [databaseName]: databaseReducer,
  [dropsName]: dropsReducer,
  [feedName]: feedReducer,
  [homeName]: homeReducer,
  [listingsQuotesName]: listingsQuotesReducer,
  [messagesName]: messagesReducer,
  [ordersName]: orderReducer,
  [profileName]: profileReducer,
  [registerName]: registerReducer,
  [reportsName]: reportsReducer,
  [resultsName]: resultsReducer,
  [savedItemsName]: savedItemsReducer,
  [searchName]: searchReducer,
  [servicesName]: servicesReducer,
  [themeName]: themeReducer,
});

export default rootReducer;