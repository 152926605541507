import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import './TextFieldOther.scss';

function TextFieldOther(props) {
    return (
        <div className={"textfield-other" + (props.className ? (" " + props.className) : "")}>
            <TextField 
                variant="outlined"
                margin="dense"
                placeholder="Other"
                {...props}
            />   
        </div>
    
    );
}

export default TextFieldOther;