import React, { useState, useEffect } from "react";
import {
  TitleHeading,
  ButtonNext,
  ErrorMessage,
  ButtonBack,
  Dropzone,
  Button,
  UploadValidator,
} from "../../_common/index";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import { Grid } from "@material-ui/core";
import { errorMessages } from "../../../constants/messages";
import "./AdditionalInformation.scss";
import profileService from "../../../services/profileService";
import { selectors as authSelectors } from "../../../redux/modules/auth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  buttonChooseFile: {
    background: "#FF6158;",
    color: "#ffffff;",
  },
});

function AdditionalInformation(props) {
  const title = "Additional Information";
  const subtitle =
    "If you wish, upload any additional company profile (prices etc)";
  let uploadedFileInit = null;
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [showFileError, setShowFileError] = useState(false);

  if (
    props.registerSellerData["add_info"] &&
    props.registerSellerData["add_info"]["attached_file"]
  ) {
    uploadedFileInit = Object.assign(
      {},
      props.registerSellerData["add_info"]["attached_file"]
    );
  }
  const [uploadedFile, setUploadedFile] = useState(uploadedFileInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  let dropzoneContent = (
    <div className="acount-user-imgwrapper">
      <div className="acount-user-img">
        <img
          src="/assets/images/icon-download.png"
          srcSet="/assets/images/icon-download.png 1x, /assets/images/icon-download@2x.png 2x"
          alt="abana"
        />
        <p>
          Drag and drop a file here (.pdf, .xls, .doc, .png) <br />
          or
        </p>
        <Button className={classes.buttonChooseFile}>Choose File</Button>
        <UploadValidator
          size={"5MB"}
          types={"PNG, JPEG, PDF, XLS, XLSX, DOC, DOCX"}
          showError={showFileError}
        />
      </div>
    </div>
  );

  const handleFileDropped = (file, base64) => {
    const result = {
      file: file,
      base64: base64,
    };

    setUploadedFile(result);
    setShowFileError(false);
  };

  const onDropRejected = () => {
    setShowFileError(true);
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const attached_file = uploadedFile || 0;

    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      add_info: {
        attached_file,
      },
      user_id: props.auth.user_id,
      user_type: props.clientData.basics.type,
      basics: props.profileData.basics,
    };

    props.setRegisterSellerData(newRegisterSellerData);

    // Temporary Fix - To remove!!!
    const requestData = {
      ...newRegisterSellerData,
      ...newRegisterSellerData["basics"],
      type: newRegisterSellerData["supplier_type"],
    };
    ////////////////////////////////////////////////

    setShowError(false);
    setIsRequest(true);

    profileService
      .editProfile(requestData, props.auth.token)
      .then(function (response) {
        if (response && response.status === 204) {
          props.setActiveStep(props.activeStep + 1);
        } else {
          setIsRequest(false);
          setErrorMsg(errorMessages.errorApi);
          setShowError(true);
        }
      })
      .catch(function (error) {
        setIsRequest(false);

        const errorData =
          error.response && error.response.data ? error.response.data : null;
        if (errorData && errorData.code === 400) {
          if (
            errorData.errors &&
            errorData.errors["basics[email]"] &&
            errorData.errors["basics[email]"][0] ===
              "This email is already used."
          ) {
            setErrorMsg(errorMessages.emailExists);
            setShowError(true);
            return;
          }
        }
        setErrorMsg(errorMessages.errorApi);
        setShowError(true);
      });

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  let fileName = "";
  let fileSize = "";

  if (uploadedFile) {
    fileName = uploadedFile["file"]["name"];
    fileSize = (parseInt(uploadedFile["file"]["size"]) / 1000).toFixed(2);
    fileSize = "(" + fileSize + " kB)";
  }

  return (
    <div className="additional-info-container-reg">
      <TitleHeading title={title} />
      <ErrorMessage showError={showError} message={errorMsg} />

      <div className="factory-upload-text">
        If you wish, upload any additional company profile (machinery etc.)
      </div>
      <div className="dropzone-outer dropzone-outer-wrapper">
        <div className="dropzone-outer dropzone-outer-wrapper">
          {uploadedFile ? (
            <div className="dropzone-uploaded-file">
              <div className="acount-user-imgwrapper">
                <div className="acount-user-img">
                  <img
                    src="/assets/images/icon-download.png"
                    srcSet="/assets/images/icon-download.png 1x, /assets/images/icon-download@2x.png 2x"
                    alt="abana"
                  />
                  <p>{fileName}</p>
                  <p>{fileSize}</p>
                  <Button onClick={removeUploadedFile}>Remove File</Button>
                </div>
              </div>
            </div>
          ) : (
            <Dropzone
              onChange={handleFileDropped}
              contents={dropzoneContent}
              accept={"image/jpeg, image/png, .pdf, .xls, .xlsx, .doc, .docx"}
              multiple={false}
              maxSize={5000000}
              onDropRejected={onDropRejected}
            />
          )}
        </div>
      </div>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} disabled={isRequest} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    profileData: profileSelectors.getProfileData(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(AdditionalInformation);
