//Module Name
export const name = 'messages';

// Actions
export const types = {
    SET_CONVERSATION_LIST: 'SET_CONVERSATION_LIST',
    SET_CONNECTION_REQUESTS: 'SET_CONNECTION_REQUESTS',
    SET_SHOULD_UPDATE_MSG: 'SET_SHOULD_UPDATE_MSG',
    SET_SELECTED_CON: 'SET_SELECTED_CON',
};

const initialState = {
    conversationList: null,
    connectionRequests: null,
    shouldUpdateMsg: false,
    selectedCon: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_CONVERSATION_LIST: {
            const { content } = action.payload;

            return {
                ...state,
                conversationList: content
            };
        }
        case types.SET_CONNECTION_REQUESTS: {
            const { content } = action.payload;

            return {
                ...state,
                connectionRequests: content
            };
        }
        case types.SET_SHOULD_UPDATE_MSG: {
            const { content } = action.payload;

            return {
                ...state,
                shouldUpdateMsg: content
            };
        }
        case types.SET_SELECTED_CON: {
            const { content } = action.payload;

            return {
                ...state,
                selectedCon: content
            };
        }
        default:
            return state;
    }
}

// Action Creators
export const actions = {
    setConversationList: content => ({
        type: types.SET_CONVERSATION_LIST,
        payload: {
            content
        }
    }),
    setConnectionRequests: content => ({
        type: types.SET_CONNECTION_REQUESTS,
        payload: {
            content
        }
    }),
    setShouldUpdateMsg: content => ({
        type: types.SET_SHOULD_UPDATE_MSG,
        payload: {
            content
        }
    }),
    setSelectedCon: content => ({
        type: types.SET_SELECTED_CON,
        payload: {
            content
        }
    })
};

// Selectors
export const selectors = {
    getConversationList: store => store[name].conversationList,
    getConnectionRequests: store => store[name].connectionRequests,
    getShouldUpdateMsg: store => store[name].shouldUpdateMsg,
    getSelectedCon: store => store[name].selectedCon
};