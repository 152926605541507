import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

function CroppedImage(props) {
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);

    const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        props.onCropComplete(croppedArea, croppedAreaPixels);
    };

    return (
        <Cropper
            image={props.image}
            aspect={props.aspect || 1}
            minZoom={0.4}
            restrictPosition={false}
            crop={crop}
            zoom={zoom}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={handleCropComplete}
        />
    );
}

export default CroppedImage;