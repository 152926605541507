import React, { useEffect } from "react";
import {
  TitleHeading,
  ButtonNext,
  ButtonBack,
  GroupCheckboxGrid,
  Checkbox,
} from "../../_common/index";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {
  selectors as registerSelectors,
  actions as registerActions,
} from "../../../redux/modules/register";
import { filterArray } from "../../../functions/converters";
import "./Market.scss";

function Market(props) {
  const title = "What are your markets?";
  const requestData = filterArray(
    props.taxonsSelected[0].children,
    "code",
    "ss_markets"
  )[0].children;

  let checkboxInit = {};
  let maincheckboxInit = {};

  const checkboxNaming = {
    name: "code",
    label: "name",
  };

  if (props.registerSellerData["market"]) {
    checkboxInit = Object.assign({}, props.registerSellerData["market"]);
  } else {
    requestData.forEach((item, index) => {
      let children = {};

      item.children.forEach((value) => {
        children[value.code] = false;
      });

      checkboxInit[item.code] = children;
      maincheckboxInit[item.code] = false;
    });
  }

  const [checkboxValues, setCheckboxValues] = React.useState(checkboxInit);
  const [checkboxChecked, setCheckedBoxChecked] =
    React.useState(maincheckboxInit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (name, target) => {
    let newCheckboxValues = Object.assign({}, checkboxValues);
    newCheckboxValues[name][target.name] = target.checked;
    setCheckboxValues(newCheckboxValues);

    let mainCheckboxValues = checkboxChecked;
    let isChecked = true;

    for (const [key] of Object.entries(newCheckboxValues[name])) {
      isChecked *= newCheckboxValues[name][key];
    }
    mainCheckboxValues[name] = Boolean(isChecked);
    setCheckedBoxChecked(mainCheckboxValues);
  };

  const handleAfricaCheckboxChange = (name, target) => {
    let newCheckboxValues = checkboxChecked;
    newCheckboxValues[name] = target.checked;
    setCheckedBoxChecked(newCheckboxValues);

    let newValues = Object.assign({}, checkboxValues);
    for (const [key] of Object.entries(newValues[name])) {
      newValues[name][key] = target.checked;
    }
    setCheckboxValues(newValues);
  };

  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleNext = () => {
    const registerSellerData = props.registerSellerData;
    const newRegisterSellerData = {
      ...registerSellerData,
      market: checkboxValues,
    };

    props.setRegisterSellerData(newRegisterSellerData);
    props.setActiveStep(props.activeStep + 1);
  };

  if (requestData.length === 0 || Object.keys(checkboxValues).length === 0) {
    return null;
  }

  return (
    <div className="market-container-reg">
      <TitleHeading title={title} />
      {requestData.map((item) => (
        <div key={item.code}>
          <div className="africa-checbox">
            <Checkbox
              id="africa"
              name={item.code}
              checked={checkboxChecked[item.code]}
              onChange={(e) => handleAfricaCheckboxChange(item.code, e.target)}
            />
            <label>{item.name}</label>
          </div>

          <div className="market-separator">
            <hr />
          </div>

          <GroupCheckboxGrid
            naming={checkboxNaming}
            title={item.name}
            name={item.code}
            checkboxList={item.children}
            values={checkboxValues[item.code]}
            onChange={handleCheckboxChange}
            md={4}
          />
        </div>
      ))}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <ButtonBack children="Back" onClick={handleBack} />
        <ButtonNext children="Next" onClick={handleNext} />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    activeStep: registerSelectors.getActiveStep(state),
    registerSellerData: registerSelectors.getRegisterSellerData(state),
    taxonsSelected: registerSelectors.getTaxonsSelected(state),
  }),
  {
    setActiveStep: registerActions.setActiveStep,
    setRegisterSellerData: registerActions.setRegisterSellerData,
  }
)(Market);
