import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ButtonOutlined, ButtonInverted } from '../index';
import './DialogComponent.scss';

function DialogComponent(props) {
    const open = props.open;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isRequest = false;

    return (
        <Dialog
            fullScreen={props.dialogAction ? null : fullScreen}
            open={open}
            onClose={props.onClose}
            aria-labelledby="responsive-dialog-title"
            classes={{ container: props.dialogAction ? 'dialog-drop-content':'dialog-container ' + props.registerClassName  }}
            {...props.dialogProps}
        >
            {props.title ?
                <DialogTitle>
                    {props.title}
                </DialogTitle> : null
            }
            <DialogContent>
                {props.content || ''}
            </DialogContent>

            {props.disableAction ? null :
                <DialogActions>
                    <ButtonInverted
                        disabled={props.isRequest || false}
                        onClick={props.onClose}
                    >
                        {props.buttonCancel || ''}
                    </ButtonInverted>

                    {props.dialogAction ? null :
                        <ButtonOutlined
                            disabled={props.isRequest || false}
                            onClick={() => props.onAgree(props.section)}
                        >
                            {props.buttonAgree || ''}
                        </ButtonOutlined>
                    }
                </DialogActions>
            }
          
        </Dialog>
    );
}

export default DialogComponent;