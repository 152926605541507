import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

function ComboBoxComponent(props) {

  return (
    <Grid container direction="column" className="combo-box-container">
        <label htmlFor={props.name}>{props.labelname}</label>
        <Autocomplete
            options={props.options}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => props.onChange(event,value)}
            groupBy={props.groupBy ? props.groupBy : undefined}
            openOnFocus={true}
            value={props.value}
            inputValue={props.inputValue}
            onInputChange={(event, value) => props.onInputChange(value)}
            blurOnSelect={true}
            renderInput={(params) => 
              <TextField 
                {...params} 
                placeholder={props.placeholder} 
                variant="outlined" 
            />}
        />
    </Grid>
  );
}

export default ComboBoxComponent;