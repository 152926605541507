import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  TitleHeading,
  ButtonInverted,
  Button,
  ErrorMessage,
  Loading,
} from "../index";
import { useHistory } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import { connect } from "react-redux";
import { selectors as authSelectors } from "../../../redux/modules/auth";
import { selectors as profileSelectors } from "../../../redux/modules/profile";
import { selectors as databaseSelectors } from "../../../redux/modules/database";
import { actions as databaseActions } from "../../../redux/modules/database";
import { actions as accountActions } from "../../../redux/modules/account";
import databaseService from "../../../services/databaseService";
import { errorMessages } from "../../../constants/messages";
import "./UpgradeNow.scss";

const axios = require("axios");
const CancelToken = axios.CancelToken;

function UpgradeNow(props) {
  let history = useHistory();

  const premium = "This is a premium feature";
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    if (!props.upgradeData) {
      databaseService
        .getFreeUser(
          props.auth.user_id,
          props.clientData.basics.type,
          props.auth.token,
          cancelToken
        )
        .then(function (response) {
          if (response && response.status === 200) {
            props.setUpgradeData(response.data.data);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    }

    return () => {
      //Cancel Request to prevent memory leak!!!
      cancel();
    };
  }, []);

  if (props.upgradeData) {
    if (loading) {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (!loading && !props.upgradeData) {
    return (
      <div className="request-failed">
        <ErrorMessage message={errorMessages.requestFailed} showError={true} />
      </div>
    );
  }

  const hanldeUpgradeButtonClick = () => {
    props.setTabValue(1);
    history.push(`/auth/account-settings`);
    return;
  };

  return (
    <div className="upgrade-container">
      <Grid className="title-container" container direction="row">
        <Grid item className="title-search">
          <TitleHeading title={props.title} />
        </Grid>

        <Grid className="upgrade-section" item>
          <TitleHeading subtitle={premium} />

          <div className="button-section">
            <FiberManualRecordIcon />
            <ButtonInverted
              className="upgrade-button"
              onClick={hanldeUpgradeButtonClick}
            >
              Upgrade
            </ButtonInverted>
          </div>
        </Grid>
      </Grid>

      <TitleHeading subtitle={props.subtitle} />

      <Grid className="upgrade-content" container direction="row">
        <StarRoundedIcon />

        <TitleHeading title={props.upgradeDescription} />

        <Grid item md={3} xs={12}>
          <Button onClick={hanldeUpgradeButtonClick}>See Plans</Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
        className="offer-container"
      >
        {props.upgradeData.map((item, index) => (
          <div key={index} className="offer-content">
            <div className={"offer-title " + item.class}>
              <div className="offer-count">{item.total}</div>
              <div className="offer-text">{item.name.toUpperCase()}</div>
            </div>
            <div className="offer-tags">
              {item.tags.map((product, index) => (
                <Grid
                  className="section-item"
                  container
                  key={index}
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  item
                >
                  <CheckCircleIcon />
                  <div className="section-item-value">{product}</div>
                </Grid>
              ))}
            </div>
          </div>
        ))}
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    auth: authSelectors.getAuth(state),
    clientData: profileSelectors.getClientData(state),
    upgradeData: databaseSelectors.getUpgradeData(state),
  }),
  {
    setUpgradeData: databaseActions.setUpgradeData,
    setTabValue: accountActions.setTabValue,
  }
)(UpgradeNow);
